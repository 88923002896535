import { FormProvider, useForm } from 'react-hook-form';

import FiltersBox from 'components/FiltersBox';
import { useDispatch } from 'hooks/filters';
import { hasDateValue } from 'functions/hasDateValue';
import { buildGraphqlFilter } from 'functions/buildGraphqlFilter';
import Fields from './Fields';

const Filters = () => {
	const filtersDispatch = useDispatch();
	const methods = useForm();

	const { getValues, reset } = methods;

	const handleClick = () => {
		const values = getValues();
		delete values.regional_id;
		let builtValues = [];
		Object.keys(values).map((val) =>
			builtValues.push({
				label: val,
				value: values[val]
			})
		);
		builtValues = builtValues.filter((val) => val.value);
		builtValues = hasDateValue(builtValues);
		const filters = buildGraphqlFilter(builtValues);
		filtersDispatch({ type: 'SET', data: filters });
	};

	const handleClear = () => {
		reset();
	};

	return (
		<FiltersBox handleClick={handleClick} handleClear={handleClear}>
			<FormProvider {...methods}>
				<Fields />
			</FormProvider>
		</FiltersBox>
	);
};

export default Filters;
