const statesUnsorted = [
	{ id: 11, initials: 'RO', name: 'Rondônia', region_id: 1 },
	{ id: 12, initials: 'AC', name: 'Acre', region_id: 1 },
	{ id: 13, initials: 'AM', name: 'Amazonas', region_id: 1 },
	{ id: 14, initials: 'RR', name: 'Roraima', region_id: 1 },
	{ id: 15, initials: 'PA', name: 'Pará', region_id: 1 },
	{ id: 16, initials: 'AP', name: 'Amapá', region_id: 1 },
	{ id: 17, initials: 'TO', name: 'Tocantins', region_id: 1 },
	{ id: 21, initials: 'MA', name: 'Maranhão', region_id: 2 },
	{ id: 22, initials: 'PI', name: 'Piauí', region_id: 2 },
	{ id: 23, initials: 'CE', name: 'Ceará', region_id: 2 },
	{ id: 24, initials: 'RN', name: 'Rio Grande do Norte', region_id: 2 },
	{ id: 25, initials: 'PB', name: 'Paraíba', region_id: 2 },
	{ id: 26, initials: 'PE', name: 'Pernambuco', region_id: 2 },
	{ id: 27, initials: 'AL', name: 'Alagoas', region_id: 2 },
	{ id: 28, initials: 'SE', name: 'Sergipe', region_id: 2 },
	{ id: 29, initials: 'BA', name: 'Bahia', region_id: 2 },
	{ id: 31, initials: 'MG', name: 'Minas Gerais', region_id: 3 },
	{ id: 32, initials: 'ES', name: 'Espírito Santo', region_id: 3 },
	{ id: 33, initials: 'RJ', name: 'Rio de Janeiro', region_id: 3 },
	{ id: 35, initials: 'SP', name: 'São Paulo', region_id: 3 },
	{ id: 41, initials: 'PR', name: 'Paraná', region_id: 4 },
	{ id: 42, initials: 'SC', name: 'Santa Catarina', region_id: 4 },
	{ id: 43, initials: 'RS', name: 'Rio Grande do Sul', region_id: 4 },
	{ id: 50, initials: 'MS', name: 'Mato Grosso do Sul', region_id: 5 },
	{ id: 51, initials: 'MT', name: 'Mato Grosso', region_id: 5 },
	{ id: 52, initials: 'GO', name: 'Goiás', region_id: 5 },
	{ id: 53, initials: 'DF', name: 'Distrito Federal', region_id: 5 }
];

const states = statesUnsorted.sort((a, b) => (a.name > b.name ? 1 : -1));

export default states;
