import { ApolloClient, HttpLink, from, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import {
	JWT_TOKEN_COGNITO,
	JWT_TOKEN_COGNITO_ID
} from '../../variables/enumLabels';

const createClient = () => {
	const ERROR_LINK = onError(({ networkError }) => {
		if (networkError && networkError?.response?.status == 401) {
			window.localStorage.removeItem(JWT_TOKEN_COGNITO_ID);
			window.localStorage.removeItem(JWT_TOKEN_COGNITO);
			// window.localStorage.clear()
			location.reload();
		}
	});

	const HTTP_LINK = new HttpLink({
		uri: `${process.env.REACT_APP_API_URL}/api/configuration/graphql`,
		headers: {
			authorization: `Bearer ${window.localStorage.getItem(
				JWT_TOKEN_COGNITO_ID
			)}`
		}
	});

	const APP_LINK = from([ERROR_LINK, HTTP_LINK]);

	return new ApolloClient({
		link: APP_LINK,
		cache: new InMemoryCache()
	});
};
export default createClient;
