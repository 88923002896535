import { Link, useHistory, useParams } from 'react-router-dom';

import { useForm } from 'hooks/forms';

import {
	Box,
	Button,
	Divider,
	Flex,
	FormControl,
	FormLabel,
	Grid,
	HStack,
	Skeleton,
	Spinner,
	Text,
	useColorModeValue
} from '@chakra-ui/react';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';

const ViewForm = () => {
	const { id } = useParams();
	const { data, loading } = useForm(id);

	const history = useHistory();

	const textColor = useColorModeValue('gray.700', 'white');

	return (
		<Flex
			direction="column"
			minH="100vh"
			align="center"
			pt={{ sm: '120px', lg: '160px' }}
		>
			<Card w={{ md: '100%', lg: '50%' }}>
				<CardHeader mb="40px">
					<Flex direction="row" w="100%" justify="space-between">
						<Text
							color={textColor}
							fontSize="lg"
							fontWeight="bold"
							mb="3px"
						>
							Formulário
						</Text>
						<Flex>
							<HStack spacing={4} alignItems="flex-start">
								<Link to={`${id}/editar`}>
									<Button colorScheme="green" size="md">
										Editar
									</Button>
								</Link>
								<Button
									variant="ghost"
									colorScheme="gray"
									size="md"
									onClick={() => history.goBack()}
								>
									Voltar
								</Button>
							</HStack>
						</Flex>
					</Flex>
				</CardHeader>
				<CardBody>
					<Flex direction="column" w="100%">
						<Grid
							templateColumns={{
								sm: '1fr',
								md: 'repeat(2, 1fr)'
							}}
							gap="24px"
							mb="24px"
						>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Nome do Formulário
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text fontSize="lg" fontWeight="bold">
										{data?.getFormConfig?.name}
									</Text>
								</Skeleton>
							</FormControl>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Tag do Builder
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text fontSize="lg" fontWeight="bold">
										{data?.getFormConfig?.tag_builder}
									</Text>
								</Skeleton>
							</FormControl>
						</Grid>
						<Flex
							w="full"
							hidden={
								data?.getFormConfig?.data_track?.length === 0
							}
							mb="8"
						>
							<Text fontSize="lg" fontWeight="bold">
								Métricas
							</Text>
						</Flex>
						{loading ? (
							<Flex
								w="full"
								justifyContent="center"
								alignItems="center"
								my="6"
							>
								<Spinner size="md" />
							</Flex>
						) : (
							<Grid
								templateColumns={{
									sm: '1fr',
									md: 'repeat(1, 1fr)'
								}}
								gap="15px"
								mb="24px"
							>
								{data?.getFormConfig?.data_track?.map(
									(dataTrack, x) => (
										<>
											<HStack key={dataTrack?.id}>
												<FormControl>
													<Text
														fontSize="md"
														fontWeight="bold"
													>
														{dataTrack?.label}
													</Text>
												</FormControl>
												<FormControl>
													<Text
														fontSize="md"
														fontWeight="bold"
													>
														{dataTrack?.tag_builder}
													</Text>
												</FormControl>
												<FormControl>
													<Text
														fontSize="md"
														fontWeight="bold"
													>
														{dataTrack?.type}
													</Text>
												</FormControl>
												<FormControl>
													<HStack>
														<Box
															w="20px"
															h="20px"
															backgroundColor={
																dataTrack?.color
															}
														/>
														<Text
															fontSize="md"
															fontWeight="bold"
														>
															{dataTrack?.color}
														</Text>
													</HStack>
												</FormControl>
											</HStack>
											{x + 1 !==
												data?.getFormConfig?.data_track
													?.length && <Divider />}
										</>
									)
								)}
							</Grid>
						)}
						<Grid
							templateColumns={{
								sm: '1fr',
								md: 'repeat(2, 1fr)'
							}}
							gap="24px"
							mb="24px"
						>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Tag da Devolutiva
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text fontSize="lg" fontWeight="bold">
										{
											data?.getFormConfig?.devolutive
												?.tag_builder
										}
									</Text>
								</Skeleton>
							</FormControl>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Opções de retorno
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text fontSize="lg" fontWeight="bold">
										{data?.getFormConfig?.devolutive
											?.type === '1' &&
											'Whatsapp com link da tela'}
										{data?.getFormConfig?.devolutive
											?.type === '2' && 'E-mail com PDF'}
									</Text>
								</Skeleton>
							</FormControl>
						</Grid>
					</Flex>
				</CardBody>
			</Card>
		</Flex>
	);
};

export default ViewForm;
