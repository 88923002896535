import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { CompactPicker } from 'react-color';
import { useForm as useFormConfig, useUpdateForm } from 'hooks/forms';

import {
	Box,
	Button,
	Divider,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Grid,
	HStack,
	Input,
	InputGroup,
	InputLeftElement,
	Select,
	Skeleton,
	Spinner,
	Text,
	useColorModeValue,
	useToast
} from '@chakra-ui/react';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import Icon from 'components/FontAwesomeIcons/Icon';

const schema = yup.object().shape({
	name: yup.string().required('O campo Nome é obrigatório'),
	tag_builder: yup.string().required('O campo Tag do Builder é obrigatório'),
	devolutive_type: yup.string().when('devolutive_tag', {
		is: (devolutiveTag) => devolutiveTag != null && devolutiveTag !== '',
		then: yup
			.string()
			.required('O campo é obrigatório quando é informado uma devolutiva')
	})
});

const EditForm = () => {
	const { id } = useParams();
	const { data, loading } = useFormConfig(id);

	const methods = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		resolver: yupResolver(schema)
	});

	const toast = useToast();
	const history = useHistory();

	const {
		register,
		handleSubmit,
		reset,
		getValues,
		setValue,
		formState: { errors }
	} = methods;

	const [colorPicker, setColorPicker] = useState(null);
	const [currentColor, setCurrentColor] = useState(null);

	useEffect(() => {
		const newArray = [];
		if (data?.getFormConfig?.data_track?.length > 0) {
			data?.getFormConfig?.data_track?.map((dataTrack) =>
				newArray.push({
					id: dataTrack?.id,
					color: dataTrack?.color,
					type: dataTrack?.type,
					label: dataTrack?.label,
					tag_builder: dataTrack?.tag_builder
				})
			);
		}
		setValue('data_track', newArray);
	}, [data]);

	const handleAddRisk = () => {
		const newArray = getValues('data_track');
		newArray.push({
			id: null,
			color: null,
			type: null,
			label: null,
			tag_builder: null
		});
		setValue('data_track', newArray);
	};

	const handleRiskRemove = (index) => {
		const newArray = getValues('data_track');
		newArray.splice(index, 1);
		setValue('data_track', newArray);
		if (colorPicker === index) {
			setColorPicker(null);
		}
		if (colorPicker < index) {
			setColorPicker(colorPicker - 1);
		}
	};

	const handleColorChange = (index, value) => {
		if (value) {
			const { hex } = value;
			const newArray = getValues('data_track');
			newArray[index].color = hex;
			setValue('data_track', newArray);
			setColorPicker(null);
		}
	};

	const [updateForm, { loading: loadingUpdate }] = useUpdateForm({
		onSuccess: (data) => {
			reset();
			toast({
				title: 'Formulário atualizado com sucesso.',
				status: 'success',
				duration: 5000,
				isClosable: true
			});
			history.push(
				`/sistema/administracao/formularios/${data?.updateFormConfig?.id}`
			);
		},
		onError: () => {
			toast({
				title: 'Ocorreu um erro',
				status: 'error',
				duration: 5000,
				isClosable: true
			});
		}
	});

	const onSubmit = (values) => {
		const variables = {
			input: {
				id: data?.getFormConfig?.id,
				name: values.name,
				tag_builder: values.tag_builder,
				data_track: values.data_track,
				devolutive: {
					id: data?.getFormConfig?.devolutive?.id,
					tag_builder: values.devolutive.tag_builder,
					type: values.devolutive.type
				}
			}
		};

		updateForm({ variables });
	};

	const inputHover = useColorModeValue('health.800', 'health.800');
	const textColor = useColorModeValue('gray.700', 'white');

	return (
		<Flex
			direction="column"
			minH="100vh"
			align="center"
			pt={{ sm: '120px', lg: '160px' }}
		>
			<Card w={{ md: '100%', lg: '50%' }}>
				<CardHeader mb="40px">
					<Flex direction="column">
						<Text
							color={textColor}
							fontSize="lg"
							fontWeight="bold"
							mb="3px"
						>
							Editar Formulário
						</Text>
					</Flex>
				</CardHeader>
				<CardBody>
					<FormProvider {...methods}>
						<form
							style={{ width: '100%' }}
							onSubmit={handleSubmit(onSubmit)}
						>
							<Flex direction="column" w="100%">
								<Grid
									templateColumns={{
										sm: '1fr',
										md: 'repeat(2, 1fr)'
									}}
									gap="24px"
									mb="24px"
								>
									<FormControl isInvalid={errors.name}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Nome do Formulário
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Input
												{...register('name')}
												defaultValue={
													data?.getFormConfig?.name
												}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
											/>
										)}
										{errors.name && (
											<FormErrorMessage>
												{errors.name.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.tag_builder}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Tag do Builder
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Input
												{...register('tag_builder')}
												defaultValue={
													data?.getFormConfig
														?.tag_builder
												}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
											/>
										)}
										{errors.tag_builder && (
											<FormErrorMessage>
												{errors.tag_builder.message}
											</FormErrorMessage>
										)}
									</FormControl>
								</Grid>
								{loading ? (
									<Flex
										w="full"
										justifyContent="center"
										alignItems="center"
										my="6"
									>
										<Spinner size="md" />
									</Flex>
								) : (
									<>
										<Grid
											templateColumns={{
												sm: '1fr',
												md: 'repeat(1, 1fr)'
											}}
											gap="15px"
											mb="24px"
										>
											{data?.getFormConfig?.data_track?.map(
												(dataTrack, x) => (
													<>
														<HStack
															key={dataTrack?.id}
														>
															<FormControl>
																<FormLabel
																	color={
																		textColor
																	}
																	fontWeight="bold"
																	fontSize="xs"
																>
																	Nome
																</FormLabel>
																<Input
																	{...register(
																		`data_track.${x}.label`
																	)}
																	defaultValue={
																		dataTrack?.label
																	}
																	focusBorderColor={
																		inputHover
																	}
																	borderRadius="8px"
																	fontSize="md"
																/>
															</FormControl>
															<FormControl>
																<FormLabel
																	color={
																		textColor
																	}
																	fontWeight="bold"
																	fontSize="xs"
																>
																	Tag
																</FormLabel>
																<Input
																	{...register(
																		`data_track.${x}.tag_builder`
																	)}
																	defaultValue={
																		dataTrack?.tag_builder
																	}
																	focusBorderColor={
																		inputHover
																	}
																	borderRadius="8px"
																	fontSize="md"
																/>
															</FormControl>
															<FormControl>
																<FormLabel
																	color={
																		textColor
																	}
																	fontWeight="bold"
																	fontSize="xs"
																>
																	Valor
																</FormLabel>
																<Input
																	{...register(
																		`data_track.${x}.type`
																	)}
																	defaultValue={
																		dataTrack?.type
																	}
																	focusBorderColor={
																		inputHover
																	}
																	borderRadius="8px"
																	fontSize="md"
																/>
															</FormControl>
															<FormControl>
																<FormLabel
																	color={
																		textColor
																	}
																	fontWeight="bold"
																	fontSize="xs"
																>
																	Cor
																</FormLabel>
																<Box position="relative">
																	<InputGroup>
																		<InputLeftElement pointerEvents="none">
																			<Box
																				w="20px"
																				h="20px"
																				backgroundColor={
																					getValues(
																						`data_track.${x}.color`
																					) ||
																					dataTrack?.color ||
																					'#ffffff'
																				}
																			/>
																		</InputLeftElement>
																		<Input
																			{...register(
																				`data_track.${x}.color`
																			)}
																			defaultValue={
																				dataTrack?.color
																			}
																			focusBorderColor={
																				inputHover
																			}
																			borderRadius="8px"
																			fontSize="md"
																			onFocus={() => {
																				setCurrentColor(
																					getValues(
																						`data_track.${x}.color`
																					) ||
																						'#ffffff'
																				);
																				setColorPicker(
																					x
																				);
																			}}
																		/>
																		<Box
																			position="absolute"
																			right="0"
																			top="40px"
																			zIndex="99"
																			hidden={
																				colorPicker !==
																				x
																			}
																		>
																			<CompactPicker
																				color={
																					currentColor ||
																					dataTrack?.color ||
																					'#ffffff'
																				}
																				onChange={(
																					value
																				) =>
																					handleColorChange(
																						x,
																						value
																					)
																				}
																			/>
																		</Box>
																	</InputGroup>
																</Box>
															</FormControl>
															<Button
																colorScheme="orange"
																onClick={() =>
																	handleRiskRemove(
																		x
																	)
																}
																marginTop="auto"
															>
																<Text fontSize="md">
																	<Icon icon="delete" />
																</Text>
															</Button>
														</HStack>
														{x + 1 !==
															dataTrack.length && (
															<Divider />
														)}
													</>
												)
											)}
										</Grid>
										<Flex mb="24px">
											<Button
												colorScheme="green"
												leftIcon={<Icon icon="plus" />}
												onClick={() => handleAddRisk()}
											>
												Adicionar métrica
											</Button>
										</Flex>
									</>
								)}
								<Grid
									templateColumns={{
										sm: '1fr',
										md: 'repeat(2, 1fr)'
									}}
									gap="24px"
									mb="24px"
								>
									<FormControl
										isInvalid={
											errors.devolutive?.tag_builder
										}
									>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Tag da Devolutiva
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Input
												{...register(
													'devolutive.tag_builder'
												)}
												defaultValue={
													data?.getFormConfig
														?.devolutive
														?.tag_builder
												}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
											/>
										)}
										{errors.devolutive?.tag_builder && (
											<FormErrorMessage>
												{
													errors.devolutive
														?.tag_builder?.message
												}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl
										isInvalid={errors.devolutive?.type}
									>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Opções de retorno
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Select
												placeholder="Selecione uma opção..."
												{...register('devolutive.type')}
												defaultValue={
													data?.getFormConfig
														?.devolutive?.type
												}
											>
												<option value="1">
													Whatsapp com link da tela
												</option>
												<option value="2">
													E-mail com PDF
												</option>
											</Select>
										)}
										{errors.devolutive?.tag_builder && (
											<FormErrorMessage>
												{
													errors.devolutive
														?.tag_builder?.message
												}
											</FormErrorMessage>
										)}
									</FormControl>
								</Grid>
								<Flex
									w="full"
									justifyContent="flex-end"
									mt="24px"
								>
									<HStack spacing={4}>
										<Button
											disabled={loadingUpdate}
											variant="ghost"
											colorScheme="gray"
											alignSelf="flex-end"
											size="md"
											onClick={() => history.goBack()}
										>
											Cancelar
										</Button>
										<Button
											isLoading={loadingUpdate}
											disabled={loadingUpdate}
											colorScheme="green"
											type="submit"
											alignSelf="flex-end"
											size="md"
										>
											Atualizar
										</Button>
									</HStack>
								</Flex>
							</Flex>
						</form>
					</FormProvider>
				</CardBody>
			</Card>
		</Flex>
	);
};

export default EditForm;
