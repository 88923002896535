import { useHistory, useParams } from 'react-router-dom';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ReactSelect from 'react-select';

import { useGetRegionals } from 'hooks/regionals';
import { useUnit, useUpdateUnit } from 'hooks/units';

import {
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Grid,
	HStack,
	Input,
	Skeleton,
	Switch,
	Text,
	Select,
	useColorModeValue,
	useToast
} from '@chakra-ui/react';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';

import { msLightTheme, msDarkTheme } from 'variables/multiselectStyle';
import states from 'variables/states';

const schema = yup.object().shape({
	name: yup.string().required('O campo Nome é obrigatório'),
	regional_id: yup.string().required('O campo Regional é obrigatório')
});

const EditUnit = () => {
	const { id } = useParams();
	const { data, loading } = useUnit(id);
	const unit = data?.getUnit || {};

	const { data: dataRegional, loading: loadingRegionals } = useGetRegionals();
	const regionals = dataRegional?.listRegionals || [];

	const history = useHistory();

	const methods = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		resolver: yupResolver(schema)
	});
	const toast = useToast();

	const {
		control,
		register,
		reset,
		handleSubmit,
		formState: { errors }
	} = methods;

	const [updateUnit, { loading: loadingUpdate }] = useUpdateUnit({
		onSuccess: (data) => {
			reset();
			toast({
				title: 'Unidade atualizada com sucesso.',
				status: 'success',
				duration: 5000,
				isClosable: true
			});
			history.push(
				`/sistema/administracao/unidades/${data.updateUnit.id}`
			);
		},
		onError: () => {
			toast({
				title: 'Ocorreu um erro',
				status: 'error',
				duration: 5000,
				isClosable: true
			});
		}
	});

	const onSubmit = (data) => {
		const variables = {
			input: {
				id,
				...data
			}
		};
		updateUnit({ variables });
	};

	const inputHover = useColorModeValue('health.800', 'health.800');
	const textColor = useColorModeValue('gray.700', 'white');

	return (
		<Flex
			direction="column"
			minH="100vh"
			align="center"
			pt={{ sm: '120px', lg: '160px' }}
		>
			<Card w={{ md: '100%', lg: '50%' }}>
				<CardHeader mb="40px">
					<Flex direction="column">
						<Text
							color={textColor}
							fontSize="lg"
							fontWeight="bold"
							mb="3px"
						>
							Editar Unidade
						</Text>
					</Flex>
				</CardHeader>
				<CardBody>
					<FormProvider {...methods}>
						<form
							style={{ width: '100%' }}
							onSubmit={handleSubmit(onSubmit)}
						>
							<Flex direction="column" w="100%">
								<Grid
									templateColumns={{
										sm: '1fr',
										md: 'repeat(2, 1fr)'
									}}
									gap="24px"
								>
									<FormControl>
										<HStack alignItems="center" mb="24px">
											<FormLabel
												color={textColor}
												fontWeight="bold"
												fontSize="xs"
												my="auto"
											>
												Ativo
											</FormLabel>
											{loading ? null : (
												<Switch
													{...register('is_active')}
													defaultChecked={
														unit.is_active
													}
													colorScheme="green"
												/>
											)}
										</HStack>
									</FormControl>
								</Grid>
								<Grid
									templateColumns={{
										sm: '1fr',
										md: 'repeat(2, 1fr)'
									}}
									gap="24px"
								>
									<FormControl isInvalid={errors.regional_id}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Regional
										</FormLabel>
										{loading || loadingRegionals ? (
											<Skeleton height="40px" />
										) : (
											<Select
												defaultValue={
													unit?.regional?.id
												}
												placeholder="Selecione uma opção..."
												{...register('regional_id')}
											>
												{regionals.map((reg) => (
													<option value={reg.id}>
														{reg.name}
													</option>
												))}
											</Select>
										)}
										{errors.regional_id && (
											<FormErrorMessage>
												{errors.regional_id.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.name}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Nome
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Input
												{...register('name')}
												defaultValue={unit.name}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
											/>
										)}
										{errors.name && (
											<FormErrorMessage>
												{errors.name.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.state_id}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Estados
										</FormLabel>
										<Controller
											name="state_id"
											{...register('state_id')}
											control={control}
											defaultValue={unit.state}
											render={({
												field: { onBlur, value }
											}) => (
												<ReactSelect
													name="plans"
													options={states.map(
														(st) => ({
															value: st.id,
															label: st.name
														})
													)}
													isMulti
													isClearable
													backspaceRemovesValue
													placeholder="Selecione"
													onBlur={onBlur}
													selected={value}
													styles={useColorModeValue(
														msLightTheme,
														msDarkTheme
													)}
												/>
											)}
										/>
										{errors.state_id && (
											<FormErrorMessage>
												{errors.state_id.message}
											</FormErrorMessage>
										)}
									</FormControl>
								</Grid>
								<Flex
									w="full"
									justifyContent="flex-end"
									mt="24px"
								>
									<HStack spacing={4}>
										<Button
											disabled={loadingUpdate}
											variant="ghost"
											colorScheme="gray"
											alignSelf="flex-end"
											size="md"
											onClick={() => history.goBack()}
										>
											Cancelar
										</Button>
										<Button
											isLoading={loadingUpdate}
											disabled={loadingUpdate}
											colorScheme="green"
											type="submit"
											alignSelf="flex-end"
											size="md"
										>
											Atualizar
										</Button>
									</HStack>
								</Flex>
							</Flex>
						</form>
					</FormProvider>
				</CardBody>
			</Card>
		</Flex>
	);
};

export default EditUnit;
