import { useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useGetRegionals } from 'hooks/regionals';
import { useCreateCampaign } from 'hooks/campaigns';

import {
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Grid,
	HStack,
	Input,
	Text,
	Select,
	useColorModeValue,
	useToast,
	Skeleton
} from '@chakra-ui/react';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import { useGetForms } from 'hooks/forms';
import { useEffect, useState } from 'react';

const schema = yup.object().shape({
	name: yup.string().required('O campo Nome é obrigatório'),
	regional: yup.string().required('O campo Regional é obrigatório'),
	unit_id: yup.string().required('O campo Unidade é obrigatório'),
	form_config_id: yup.string().required('O campo Formulário é obrigatório')
});

const CreateCampaign = () => {
	const {
		data: { listRegionals: regionals = [] } = [],
		loading: loadingRegionals
	} = useGetRegionals();
	const {
		data: { listFormConfigs: forms = [] } = [],
		loading: loadingForms
	} = useGetForms();

	const methods = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		resolver: yupResolver(schema)
	});
	const toast = useToast();
	const history = useHistory();

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		watch
	} = methods;

	const [units, setUnits] = useState([]);
	const selectedRegional = watch('regional');

	useEffect(() => {
		setUnits(
			() => regionals.find((r) => r?.id === selectedRegional)?.units || []
		);
	}, [selectedRegional]);

	const [createCampaign, { loading }] = useCreateCampaign({
		onSuccess: (data) => {
			reset();
			toast({
				title: 'Campanha cadastrada com sucesso.',
				status: 'success',
				duration: 5000,
				isClosable: true
			});
			history.push(
				`/sistema/administracao/campanhas/${data.createCampaign.id}`
			);
		},
		onError: () => {
			toast({
				title: 'Ocorreu um erro',
				status: 'error',
				duration: 5000,
				isClosable: true
			});
		}
	});

	const onSubmit = (data) => {
		const unitId = data.unit_id;
		const formConfigId = data.form_config_id;

		delete data.regional;
		delete data.unit_id;
		delete data.form_config_id;

		const variables = {
			input: {
				...data,
				unit: {
					connect: unitId
				},
				form_config: {
					connect: formConfigId
				}
			}
		};
		createCampaign({ variables });
	};

	const inputHover = useColorModeValue('health.800', 'health.800');
	const textColor = useColorModeValue('gray.700', 'white');

	return (
		<Flex
			direction="column"
			minH="100vh"
			align="center"
			pt={{ sm: '120px', lg: '160px' }}
		>
			<Card w={{ md: '100%', lg: '50%' }}>
				<CardHeader mb="40px">
					<Flex direction="column">
						<Text
							color={textColor}
							fontSize="lg"
							fontWeight="bold"
							mb="3px"
						>
							Cadastrar Campanha
						</Text>
						<Text
							color="gray.400"
							fontWeight="normal"
							fontSize="sm"
						>
							Preencha os campos para realizar o cadastro
						</Text>
					</Flex>
				</CardHeader>
				<CardBody>
					<FormProvider {...methods}>
						<form
							style={{ width: '100%' }}
							onSubmit={handleSubmit(onSubmit)}
						>
							<Flex direction="column" w="100%">
								<Grid
									templateColumns={{
										sm: '1fr',
										md: 'repeat(2, 1fr)'
									}}
									gap="24px"
								>
									<FormControl isInvalid={errors.name}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Nome
										</FormLabel>
										<Input
											{...register('name')}
											focusBorderColor={inputHover}
											borderRadius="8px"
											fontSize="md"
										/>
										{errors.name && (
											<FormErrorMessage>
												{errors.name.message}
											</FormErrorMessage>
										)}
									</FormControl>

									<FormControl isInvalid={errors.regional}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Regional
										</FormLabel>
										<Skeleton
											height="40px"
											isLoaded={!loadingRegionals}
										>
											<Select
												placeholder="Selecione uma opção..."
												{...register('regional')}
											>
												{regionals &&
													regionals.map((reg) => (
														<option value={reg.id}>
															{reg.name}
														</option>
													))}
											</Select>
										</Skeleton>
										{errors.regional && (
											<FormErrorMessage>
												{errors.regional.message}
											</FormErrorMessage>
										)}
									</FormControl>
								</Grid>
								<Grid
									templateColumns={{
										sm: '1fr',
										md: 'repeat(2, 1fr)'
									}}
									gap="24px"
								>
									<FormControl isInvalid={errors.unit_id}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Unidade
										</FormLabel>
										<Skeleton
											height="40px"
											isLoaded={!loadingRegionals}
										>
											<Select
												placeholder="Selecione uma opção..."
												{...register('unit_id')}
												disabled={
													!units || !selectedRegional
												}
											>
												{units &&
													units.map((unit) => (
														<option value={unit.id}>
															{unit.name}
														</option>
													))}
											</Select>
										</Skeleton>
										{errors.unit_id && (
											<FormErrorMessage>
												{errors.unit_id.message}
											</FormErrorMessage>
										)}
									</FormControl>

									<FormControl
										isInvalid={errors.form_config_id}
									>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Formulário
										</FormLabel>
										<Skeleton
											height="40px"
											isLoaded={!loadingForms}
										>
											<Select
												placeholder="Selecione uma opção..."
												{...register('form_config_id')}
											>
												{forms &&
													forms.map((form) => (
														<option value={form.id}>
															{form.name}
														</option>
													))}
											</Select>
										</Skeleton>
										{errors.form_config_id && (
											<FormErrorMessage>
												{errors.form_config_id.message}
											</FormErrorMessage>
										)}
									</FormControl>
								</Grid>
								<Flex
									w="full"
									justifyContent="flex-end"
									mt="24px"
								>
									<HStack spacing={4}>
										<Button
											disabled={loading}
											variant="ghost"
											colorScheme="gray"
											alignSelf="flex-end"
											size="md"
											onClick={() => history.goBack()}
										>
											Cancelar
										</Button>
										<Button
											isLoading={loading}
											disabled={loading}
											colorScheme="green"
											type="submit"
											alignSelf="flex-end"
											size="md"
										>
											Cadastrar
										</Button>
									</HStack>
								</Flex>
							</Flex>
						</form>
					</FormProvider>
				</CardBody>
			</Card>
		</Flex>
	);
};

export default CreateCampaign;
