import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import InputMask from 'react-input-mask';

import { useCreateUser } from 'hooks/users';

import {
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Grid,
	HStack,
	Input,
	Select,
	Text,
	useColorModeValue,
	useToast
} from '@chakra-ui/react';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';

import { cpfRegex, phoneRegex } from 'variables/regex';
import { useState } from 'react';

const schema = yup.object().shape({
	name: yup.string().required('O campo Nome é obrigatório'),
	cpf: yup
		.string()
		.required('O campo CPF é obrigatório')
		.matches(cpfRegex, 'Insira um CPF válido'),
	email: yup
		.string()
		.email('Insira um e-mail válido')
		.required('O campo E-mail é obrigatório'),
	phone: yup
		.string()
		.required('O campo Telefone Principal é obrigatório')
		.matches(phoneRegex, 'Insira um telefone válido'),
	type: yup.string().required('O campo Tipo de Perfil é obrigatório'),
	client_id: yup.string().when('type', {
		is: (type) => type === 4,
		then: yup.string().required('O campo Empresa é obrigatório')
	})
});

const CreateUser = () => {
	const methods = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		resolver: yupResolver(schema)
	});
	const toast = useToast();
	const history = useHistory();

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset
	} = methods;

	const [roleType, setRoleType] = useState(null);

	const [createUser, { loading }] = useCreateUser({
		onSuccess: (data) => {
			reset();
			toast({
				title: 'Usuário cadastrado com sucesso.',
				status: 'success',
				duration: 5000,
				isClosable: true
			});
			history.push(
				`/sistema/administracao/usuarios/${data.createUser.id}`
			);
		},
		onError: () => {
			toast({
				title: 'Ocorreu um erro',
				status: 'error',
				duration: 5000,
				isClosable: true
			});
		}
	});

	const onSubmit = (data) => {
		const variables = {
			input: {
				name: data.name,
				email: data.email,
				role: data.role,
				phone: data.phone,
				cpf: data.cpf,
				status: true
			}
		};

		createUser({ variables });
	};

	const inputHover = useColorModeValue('health.800', 'health.800');
	const textColor = useColorModeValue('gray.700', 'white');

	return (
		<Flex
			direction="column"
			minH="100vh"
			align="center"
			pt={{ sm: '120px', lg: '160px' }}
		>
			<Card w={{ md: '100%', lg: '50%' }}>
				<CardHeader mb="40px">
					<Flex direction="column">
						<Text
							color={textColor}
							fontSize="lg"
							fontWeight="bold"
							mb="3px"
						>
							Cadastrar Usuário
						</Text>
						<Text
							color="gray.400"
							fontWeight="normal"
							fontSize="sm"
						>
							Preencha os campos para realizar o cadastro
						</Text>
					</Flex>
				</CardHeader>
				<CardBody>
					<FormProvider {...methods}>
						<form
							style={{ width: '100%' }}
							onSubmit={handleSubmit(onSubmit)}
						>
							<Flex direction="column" w="100%">
								<Grid
									templateColumns={{
										sm: '1fr',
										md: 'repeat(2, 1fr)'
									}}
									gap="24px"
								>
									<FormControl isInvalid={errors.name}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Nome
										</FormLabel>
										<Input
											{...register('name')}
											focusBorderColor={inputHover}
											borderRadius="8px"
											fontSize="md"
										/>
										{errors.name && (
											<FormErrorMessage>
												{errors.name.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.email}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											E-mail
										</FormLabel>
										<Input
											{...register('email')}
											focusBorderColor={inputHover}
											borderRadius="8px"
											fontSize="md"
										/>
										{errors.email && (
											<FormErrorMessage>
												{errors.email.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.phone}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Celular
										</FormLabel>
										<Input
											as={InputMask}
											mask="(99) 99999-9999"
											{...register('phone')}
											focusBorderColor={inputHover}
											borderRadius="8px"
											fontSize="md"
										/>
										{errors.phone && (
											<FormErrorMessage>
												{errors.phone.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.type}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Tipo de Perfil
										</FormLabel>
										<Select
											{...register('type')}
											focusBorderColor={inputHover}
											borderRadius="8px"
											fontSize="md"
											placeholder="Selecione uma opção..."
											onChange={({ target }) =>
												setRoleType(target.value)
											}
										>
											<option value={1}>
												Super Admin
											</option>
											<option value={2}>
												Admin Regional
											</option>
											<option value={3}>
												Operador Regional
											</option>
											<option value={4}>Empresa</option>
										</Select>
										{errors.type && (
											<FormErrorMessage>
												{errors.type.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.cpf}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											CPF
										</FormLabel>
										<Input
											{...register('cpf')}
											as={InputMask}
											mask="999.999.999-99"
											focusBorderColor={inputHover}
											borderRadius="8px"
											fontSize="md"
										/>
										{errors.cpf && (
											<FormErrorMessage>
												{errors.cpf.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl
										isInvalid={errors.regional_id}
										hidden={roleType !== 1}
									>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Regional
										</FormLabel>
										<Select
											placeholder="Selecione uma opção..."
											{...register('regional_id')}
										>
											<option value={1}>AA</option>
											<option value={2}>BB</option>
										</Select>
										{errors.regional_id && (
											<FormErrorMessage>
												{errors.regional_id.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl
										isInvalid={errors.client_id}
										hidden={roleType !== 4}
									>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Empresa
										</FormLabel>
										<Select
											{...register('client_id')}
											placeholder="Selecione uma opção..."
										>
											<option value={1}>AA</option>
											<option value={2}>BB</option>
										</Select>
										{errors.client_id && (
											<FormErrorMessage>
												{errors.client_id.message}
											</FormErrorMessage>
										)}
									</FormControl>
								</Grid>
								<Flex
									w="full"
									justifyContent="flex-end"
									mt="24px"
								>
									<HStack spacing={4}>
										<Button
											disabled={loading}
											variant="ghost"
											colorScheme="gray"
											alignSelf="flex-end"
											size="md"
											onClick={() => history.goBack()}
										>
											Cancelar
										</Button>
										<Button
											isLoading={loading}
											disabled={loading}
											colorScheme="green"
											type="submit"
											alignSelf="flex-end"
											size="md"
										>
											Cadastrar
										</Button>
									</HStack>
								</Flex>
							</Flex>
						</form>
					</FormProvider>
				</CardBody>
			</Card>
		</Flex>
	);
};

export default CreateUser;
