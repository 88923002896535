import {
	Box,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	useColorModeValue
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { DotsThreeVertical } from '@phosphor-icons/react';

const ActionsMenu = ({ id }) => {
	const iconColor = useColorModeValue('gray.200', 'gray.800');
	const iconHoverColor = useColorModeValue('gray.300', 'gray.900');

	return (
		<Box position="absolute" top={0} right={0}>
			<Menu>
				<MenuButton
					px={1}
					py={1}
					transition="all 0.2s"
					borderRadius="md"
					backgroundColor={iconColor}
					_hover={{ bg: iconHoverColor }}
				>
					<DotsThreeVertical size="30" weight="bold" />
				</MenuButton>
				<MenuList>
					<MenuItem>Enviar Campanha</MenuItem>
					<MenuItem>
						<Link to={`${id}/editar`}>Editar Dados</Link>
					</MenuItem>
					<MenuItem>Desativar</MenuItem>
				</MenuList>
			</Menu>
		</Box>
	);
};

export default ActionsMenu;
