import { UsersProvider as Provider } from 'hooks/users/index';
import { ConfigurationQueryProvider } from '../services/configurationQueryProvider';

const UsersProvider = ({ children }) => (
	<ConfigurationQueryProvider>
		<Provider>{children}</Provider>
	</ConfigurationQueryProvider>
);

export default UsersProvider;
