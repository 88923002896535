import {
	Text,
	useColorModeValue,
	Select,
	VStack,
	Input,
	Skeleton
} from '@chakra-ui/react';
import { useGetRegionals } from 'hooks/regionals';
import { useGetUnits } from 'hooks/units';
import { useFormContext } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { status } from 'variables/beneficiariesStatus';

const Fields = () => {
	const methods = useFormContext();
	const { data: dataR, loading: loadingRegionals } = useGetRegionals();
	const { data: dataU, loading: loadingUnits } = useGetUnits();

	const regionals = dataR?.listRegionals || [];
	const units = dataU?.listUnits || [];

	const { register, watch } = methods;

	const wRegional = watch('regional_id', null);

	const inputHover = useColorModeValue('notrelife.800', 'notrelife.800');

	return (
		<>
			<VStack spacing={2} alignItems="flex-start">
				<Text size="sm">Regional</Text>
				<Skeleton height="40px" isLoaded={!loadingRegionals}>
					<Select
						placeholder="Selecione uma opção..."
						{...register('regional_id')}
					>
						{regionals.map((reg) => (
							<option value={reg.id}>{reg.name}</option>
						))}
					</Select>
				</Skeleton>
			</VStack>
			<VStack spacing={2} alignItems="flex-start">
				<Text size="sm">Unidade</Text>
				<Skeleton height="40px" isLoaded={!loadingUnits}>
					<Select
						placeholder={
							!wRegional
								? 'Seleione uma regional'
								: 'Selecione uma opção...'
						}
						disabled={!wRegional}
						{...register('unit_id')}
						defaultValue={null}
					>
						{units
							?.filter((unit) => unit.regional.id == wRegional)
							.map((unit) => (
								<option value={unit.id}>{unit.name}</option>
							))}
					</Select>
				</Skeleton>
			</VStack>
			<VStack spacing={2} alignItems="flex-start">
				<Text size="sm">Status</Text>
				<Select
					placeholder="Selecione uma opção..."
					{...register('status')}
				>
					{status.map((st) => (
						<option id={st.value} value={st.value}>
							{st.label}
						</option>
					))}
				</Select>
			</VStack>
			<VStack spacing={2} alignItems="flex-start">
				<Text size="sm">Data Inicial</Text>
				<Input
					as={InputMask}
					mask="99/99/9999"
					focusBorderColor={inputHover}
					borderRadius="8px"
					fontSize="md"
					{...register('start_date')}
				/>
			</VStack>
			<VStack spacing={2} alignItems="flex-start">
				<Text size="sm">Data Final</Text>
				<Input
					as={InputMask}
					mask="99/99/9999"
					focusBorderColor={inputHover}
					borderRadius="8px"
					fontSize="md"
					{...register('end_date')}
				/>
			</VStack>
		</>
	);
};

export default Fields;
