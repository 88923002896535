import { gql } from '@apollo/client';

export const GET_FORMS = gql`
	query GetForms {
		listFormConfigs {
			id
			client_id
			name
			tag_builder
			created_at
			updated_at
			deleted_at
		}
	}
`;

export const GET_FORMS_PAGINATE = gql`
	query GET_FORMS($first: Int! = 30, $page: Int! = 1, $search: String) {
		paginateFormConfigs(first: $first, page: $page, search: $search) {
			paginatorInfo {
				count
				currentPage
				firstItem
				perPage
				lastItem
				total
			}
			data {
				id
				client_id
				name
				tag_builder
				created_at
				updated_at
				deleted_at
			}
		}
	}
`;

export const GET_FORM = gql`
	query GetForm($id: ID!) {
		getFormConfig(id: $id) {
			id
			client_id
			name
			tag_builder
			data_track {
				id
				client_id
				form_config_id
				label
				tag_builder
				type
				color
				created_at
				updated_at
				deleted_at
			}
			devolutive {
				id
				client_id
				form_config_id
				tag_builder
				type
				created_at
				updated_at
				deleted_at
			}
			created_at
			updated_at
			deleted_at
		}
	}
`;

export const CREATE_FORM = gql`
	mutation CreateForm($input: CreateFormConfig) {
		createFormConfig(input: $input) {
			id
			client_id
			name
			tag_builder
			data_track {
				id
				client_id
				form_config_id
				label
				tag_builder
				type
			}
			devolutive {
				id
				client_id
				form_config_id
				tag_builder
				type
			}
		}
	}
`;

export const UPDATE_FORM = gql`
	mutation UpdateForm($input: UpdateFormConfig) {
		updateFormConfig(input: $input) {
			id
			client_id
			name
			tag_builder
			data_track {
				id
				client_id
				form_config_id
				label
				tag_builder
				type
			}
			devolutive {
				id
				client_id
				form_config_id
				tag_builder
				type
			}
		}
	}
`;
