const ACTION_TYPES = {
	LIST: 'LIST',
	SELECT: 'SELECT',
	ADD: 'ADD',
	UPDATE: 'UPDATE',
	REMOVE: 'REMOVE',
	VIEW: 'VIEW'
};

const reducers = (state, action) => {
	switch (action.type) {
		case ACTION_TYPES.LIST: {
			return {
				...state,
				rows: action.data.data,
				page: action.data.paginatorInfo.currentPage,
				perPage: action.data.paginatorInfo.perPage,
				total: action.data.paginatorInfo.total,
				currentPage: action.data.paginatorInfo.currentPage
			};
		}
		case ACTION_TYPES.ADD: {
			return {
				...state,
				rows: [...state.rows, action.data]
			};
		}
		case ACTION_TYPES.VIEW: {
			return {
				...state,
				user: action.data
			};
		}
		case ACTION_TYPES.UPDATE: {
			return {
				...state,
				users:
					state?.users?.length > 0
						? state.users.map((item) =>
							item.id === action.data.user.id
								? action.data.user
								: item
						  )
						: [],
				rows:
					state?.rows?.length > 0
						? state.rows.map((item) =>
							item.id === action.data.user.id
								? action.data.user
								: item
						  )
						: []
			};
		}
		case ACTION_TYPES.REMOVE: {
			return {
				...state,
				users: state?.users?.filter(({ id }) => id !== action.id)
			};
		}
		default: {
			return state;
		}
	}
};

export { reducers, ACTION_TYPES };
