import { createContext, useContext, useReducer } from 'react';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import {
	GET_CLIENTS_PAGINATE,
	GET_CLIENT,
	CREATE_CLIENT,
	UPDATE_CLIENT,
	GET_CLIENTS
} from 'graphql/clients';
import { ACTION_TYPES, reducers } from './reducers';

const ClientsStateContext = createContext();
const ClientsDispatchContext = createContext();

const initialState = {
	rows: [],
	page: 1,
	perPage: 15,
	client: {}
};

const ClientsProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducers, initialState);

	return (
		<ClientsStateContext.Provider value={{ ...state }}>
			<ClientsDispatchContext.Provider value={dispatch}>
				{children}
			</ClientsDispatchContext.Provider>
		</ClientsStateContext.Provider>
	);
};

const useClients = () => {
	const context = useContext(ClientsStateContext);

	if (!context)
		throw new Error('useClients must be used within an ClientsProvider');

	return context;
};

const useDispatch = () => {
	const dispatch = useContext(ClientsDispatchContext);

	if (dispatch === undefined)
		throw new Error('useDispatch must be used within a ClientsProvider');

	return dispatch;
};

const useGetClients = () => useQuery(GET_CLIENTS);

const usePaginateClients = () => {
	const dispatch = useDispatch();
	return useLazyQuery(GET_CLIENTS_PAGINATE, {
		onCompleted: (data) => {
			// window.history.replaceState(
			// 	null,
			// 	'',
			// 	`./clientes?page=${data.clientsPaginate.paginatorInfo.currentPage}&size=${data.clientsPaginate.paginatorInfo.perPage}`
			// );
			dispatch({ type: ACTION_TYPES.LIST, data: data.clients });
		}
	});
};

const useCreateClient = (options = {}) => {
	const dispatch = useDispatch();
	const { onSuccess, onError } = options;

	return useMutation(CREATE_CLIENT, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data);
			dispatch({ type: ACTION_TYPES.ADD, data: data.createClient });
		},
		onError: (error) => {
			if (onError) onError(error);
		}
	});
};

const useClient = (id) => {
	const dispatch = useContext(ClientsDispatchContext);

	return useQuery(GET_CLIENT, {
		fetchPolicy: 'no-cache',
		variables: {
			id
		},
		onCompleted: (data) =>
			dispatch({ type: ACTION_TYPES.VIEW, data: data.client })
	});
};

const useUpdateClient = (options = {}) => {
	const dispatch = useContext(ClientsDispatchContext);
	const { onSuccess, onError } = options;

	return useMutation(UPDATE_CLIENT, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data);
			dispatch({ type: ACTION_TYPES.UPDATE, data: data.updateClient });
		},
		onError: (error) => {
			if (onError) onError(error);
		},
		refetchQueries: [
			{
				query: GET_CLIENTS_PAGINATE
			}
		]
	});
};

export {
	ClientsProvider,
	useClients,
	useGetClients,
	useCreateClient,
	usePaginateClients,
	useClient,
	useUpdateClient
	// useDeleteClient
};
