const types = [
	{
		label: 'Contador',
		value: 'counter'
	},
	{
		label: 'Barras',
		value: 'bars'
	},
	{
		label: 'Colunas',
		value: 'coluns'
	},
	{
		label: 'Donut',
		value: 'donut'
	},
	{
		label: 'Velocímetro',
		value: 'velocimeter'
	}
];

const widths = [
	{
		label: '1',
		value: 'full',
		colSpan: 4
	},
	{
		label: '3/4',
		value: 'three_quarter',
		colSpan: 3
	},
	{
		label: '1/2',
		value: 'half',
		colSpan: 2
	},
	{
		label: '1/4',
		value: 'one_quarter',
		colSpan: 1
	}
];

const colors = [
	{
		label: 'Azul',
		value: 'blue',
		hex: '#4255C2',
		scheme: 'blue.500'
	},
	{
		label: 'Verde',
		value: 'green',
		hex: '#22b357',
		scheme: 'green.500'
	},
	{
		label: 'Laranja',
		value: 'orange',
		hex: '#D97325',
		scheme: 'orange.500'
	},
	{
		label: 'Vermelho',
		value: 'red',
		hex: '#CB4333',
		scheme: 'red.500'
	}
];

export { types, widths, colors };
