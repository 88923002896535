import { Link, useHistory, useParams } from 'react-router-dom';
import {
	Box,
	Button,
	Divider,
	Flex,
	FormControl,
	FormLabel,
	Grid,
	HStack,
	Image,
	Skeleton,
	Spinner,
	Text,
	useColorModeValue,
	VStack
} from '@chakra-ui/react';

import { useClient, useClients } from 'hooks/clients';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';

import StatusIndicator from 'components/StatusIndicator';

import Logo from 'assets/img/logo_healthid.png';

const ViewClient = () => {
	const { id } = useParams();
	const { client } = useClients();
	const { loading } = useClient(id);
	const history = useHistory();
	const textColor = useColorModeValue('gray.700', 'white');

	return loading || !client ? (
		'Carregando'
	) : (
		<Flex
			direction="column"
			minH="100vh"
			align="center"
			pt={{ sm: '120px', lg: '160px' }}
		>
			<Card w={{ md: '100%', lg: '50%' }}>
				<CardHeader>
					<Flex direction="row" w="100%" justify="space-between">
						<HStack spacing={4}>
							<Box h="80px" w="80px">
								{loading ? (
									<Flex
										w="full"
										h="full"
										justifyContent="center"
										alignItems="center"
									>
										<Spinner size="md" />
									</Flex>
								) : (
									<Image
										width="full"
										height="auto"
										src={Logo}
										alt="Logo"
										me="10px"
									/>
								)}
							</Box>
							<VStack spacing={2} alignItems="flex-start">
								<Skeleton height="25px" isLoaded={!loading}>
									<Text fontSize="lg" fontWeight="bold">
										Nome do cliente
									</Text>
								</Skeleton>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text fontSize="md">{client.cnpj}</Text>
								</Skeleton>
								<StatusIndicator isActive size="sm" />
							</VStack>
						</HStack>
						<Flex>
							<HStack spacing={4} alignItems="flex-start">
								<Link to={`${id}/editar`}>
									<Button colorScheme="green" size="md">
										Editar
									</Button>
								</Link>
								<Button
									variant="ghost"
									colorScheme="gray"
									size="md"
									onClick={() => history.goBack()}
								>
									Voltar
								</Button>
							</HStack>
						</Flex>
					</Flex>
				</CardHeader>
				<Divider my={8} />
				<CardBody>
					<Flex direction="column" w="100%">
						<Text fontSize="lg" fontWeight="bold" mb="12px">
							Dados Gerais
						</Text>
						<Grid
							templateColumns={{
								sm: '1fr',
								md: 'repeat(2, 1fr)'
							}}
							gap="24px"
						>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Contato Principal
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text
										color={textColor}
										fontSize="lg"
										fontWeight="bold"
										mb="3px"
									>
										{client.name}
									</Text>
								</Skeleton>
							</FormControl>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									E-mail
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text
										color={textColor}
										fontSize="lg"
										fontWeight="bold"
										mb="3px"
									>
										{client.email}
									</Text>
								</Skeleton>
							</FormControl>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Telefone Principal
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text
										color={textColor}
										fontSize="lg"
										fontWeight="bold"
										mb="3px"
									>
										{client.phone}
									</Text>
								</Skeleton>
							</FormControl>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Telefone Secundário
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text
										color={textColor}
										fontSize="lg"
										fontWeight="bold"
										mb="3px"
									>
										{client.phone_secondary}
									</Text>
								</Skeleton>
							</FormControl>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Data do Contrato
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text
										color={textColor}
										fontSize="lg"
										fontWeight="bold"
										mb="3px"
									>
										{client.assigned_at}
									</Text>
								</Skeleton>
							</FormControl>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Módulos
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text
										color={textColor}
										fontSize="lg"
										fontWeight="bold"
										mb="3px"
									/>
								</Skeleton>
							</FormControl>
						</Grid>
					</Flex>
				</CardBody>
			</Card>
			<Card w={{ md: '100%', lg: '50%' }} mt="24px">
				<CardHeader>
					<Flex direction="row" w="100%" justify="space-between">
						<Text fontSize="lg" fontWeight="bold" mb="12px">
							Take Blip
						</Text>
					</Flex>
				</CardHeader>
				<CardBody>
					<Flex direction="column" w="100%">
						<Grid
							templateColumns={{
								sm: '1fr',
								md: 'repeat(1, 1fr)'
							}}
							gap="24px"
						>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Cabeçalho de autenticação (Authorization)
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text
										color={textColor}
										fontSize="lg"
										fontWeight="bold"
										mb="3px"
									>
										Key {client.whats_authorization}
									</Text>
								</Skeleton>
							</FormControl>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Url para enviar mensagens
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text
										color={textColor}
										fontSize="lg"
										fontWeight="bold"
										mb="3px"
									>
										{client.whats_messages}
									</Text>
								</Skeleton>
							</FormControl>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Url para enviar notificações
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text
										color={textColor}
										fontSize="lg"
										fontWeight="bold"
										mb="3px"
									>
										{client.whats_notifications}
									</Text>
								</Skeleton>
							</FormControl>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Url para enviar comandos
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text
										color={textColor}
										fontSize="lg"
										fontWeight="bold"
										mb="3px"
									>
										{client.whats_commands}
									</Text>
								</Skeleton>
							</FormControl>
						</Grid>
					</Flex>
				</CardBody>
			</Card>
		</Flex>
	);
};

export default ViewClient;
