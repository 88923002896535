import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';
import ReactSelect from 'react-select';
import InputMask from 'react-input-mask';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import Dropzone, { useDropzone } from 'react-dropzone';
import { isValid } from 'date-fns';

import { useClient, useClients, useUpdateClient } from 'hooks/clients';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';

import {
	Box,
	Button,
	Center,
	Divider,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Grid,
	HStack,
	Image,
	Input,
	Skeleton,
	Spinner,
	Switch,
	Text,
	useColorModeValue,
	useToast
} from '@chakra-ui/react';

import { msLightTheme, msDarkTheme } from 'variables/multiselectStyle';
import { cnpjRegex, phoneRegex } from 'variables/regex';

import Logo from 'assets/img/logo_healthid.png';

const validDate = (value) => {
	const date = value.split('/');
	const valid = isValid(new Date(`${date[2]}-${date[1]}-${date[0]}`));
	return valid;
};

const schema = yup.object().shape({
	name: yup.string().required('O campo Nome é obrigatório'),
	cnpj: yup
		.string()
		.required('O campo CNPJ é obrigatório')
		.matches(cnpjRegex, 'Insira um CNPJ válido'),
	contact: yup.string().required('O campo Contato Principal é obrigatório'),
	email: yup
		.string()
		.email('Insira um e-mail válido')
		.required('O campo E-mail é obrigatório'),
	phone: yup
		.string()
		.required('O campo Telefone Principal é obrigatório')
		.matches(phoneRegex, 'Insira um telefone válido'),
	assigned_at: yup
		.string()
		.required('O campo Data do Contrato é obrigatório')
		.test('dateValidate', 'Insira uma data válida', (field) =>
			validDate(field)
		),
	modules: yup.array().required('O campo Módulos é obrigatório'),
	whats_authorization: yup.string().when('whats', {
		is: (whats) => whats === true,
		then: yup.string().required('Informe o cabeçalho de autenticação')
	}),
	whats_messages: yup.string().when('whats', {
		is: (whats) => whats === true,
		then: yup.string().required('Informe a url de envio de mensagens')
	}),
	whats_notifications: yup.string().when('whats', {
		is: (whats) => whats === true,
		then: yup.string().required('Informe a url de envio de notificações')
	}),
	whats_commands: yup.string().when('whats', {
		is: (whats) => whats === true,
		then: yup.string().required('Informe a url de envio de comandos')
	})
});

const EditClient = () => {
	const { id } = useParams();
	const { loading } = useClient(id);
	const { client } = useClients();
	const inputHover = useColorModeValue('health.800', 'health.800');
	const textColor = useColorModeValue('gray.700', 'white');
	const history = useHistory();
	const methods = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		resolver: yupResolver(schema),
		defaultValues: client
	});
	const {
		control,
		register,
		reset,
		handleSubmit,
		formState: { errors },
		setValue
	} = methods;
	const toast = useToast();
	const { getRootProps } = useDropzone({
		accept: ['png/jpg/jpeg']
	});
	const [activeWhats, setActiveWhats] = useState(false);
	const [files, setFiles] = useState([]);
	const handleDrop = (acceptedFiles) => {
		setFiles(acceptedFiles);
	};

	const [updateClient, { loading: loadingUpdate }] = useUpdateClient({
		onSuccess: (data) => {
			reset();
			toast({
				title: 'Cliente atualizado com sucesso.',
				status: 'success',
				duration: 5000,
				isClosable: true
			});
			history.push(
				`/sistema/administracao/clientes/${data.updateUser.id}`
			);
		},
		onError: () => {
			toast({
				title: 'Ocorreu um erro',
				status: 'error',
				duration: 5000,
				isClosable: true
			});
		}
	});

	const onSubmit = (data) => {
		updateClient(data);
	};

	useEffect(() => {
		setValue('whats', activeWhats);
	}, [activeWhats]);

	return (
		<Flex
			direction="column"
			minH="100vh"
			align="center"
			pt={{ sm: '120px', lg: '160px' }}
		>
			<Card w={{ md: '100%', lg: '50%' }}>
				<CardHeader mb="40px">
					<Flex direction="column">
						<Text
							color={textColor}
							fontSize="lg"
							fontWeight="bold"
							mb="3px"
						>
							Editar Cliente
						</Text>
					</Flex>
				</CardHeader>
				<CardBody>
					<FormProvider {...methods}>
						<form
							style={{ width: '100%' }}
							onSubmit={handleSubmit(onSubmit)}
						>
							<Flex direction="column" w="100%">
								<Grid
									templateColumns={{
										sm: '1fr',
										md: 'repeat(1, 1fr)'
									}}
									gap="24px"
								>
									<FormControl>
										<HStack alignItems="center" mb="24px">
											<FormLabel
												color={textColor}
												fontWeight="bold"
												fontSize="xs"
												my="auto"
											>
												Ativo
											</FormLabel>
											<Switch colorScheme="green" />
										</HStack>
									</FormControl>
								</Grid>
								<Grid
									templateColumns={{
										sm: '1fr',
										md: 'repeat(2, 1fr)'
									}}
									gap="24px"
								>
									<FormControl isInvalid={errors.name}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Nome
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Input
												{...register('name')}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
											/>
										)}
										{errors.name && (
											<FormErrorMessage>
												{errors.name.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.cnpj}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											CNPJ
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Input
												{...register('cnpj')}
												as={InputMask}
												mask="99.999.999/9999-99"
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
											/>
										)}
										{errors.cnpj && (
											<FormErrorMessage>
												{errors.cnpj.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.contact}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Contato Principal
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Input
												{...register('contact')}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
											/>
										)}
										{errors.contact && (
											<FormErrorMessage>
												{errors.contact.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.email}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											E-mail
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Input
												{...register('email')}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
											/>
										)}
										{errors.email && (
											<FormErrorMessage>
												{errors.email.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.phone}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Telefone Principal
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Input
												{...register('phone')}
												as={InputMask}
												mask="(99) 99999-9999"
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
											/>
										)}
										{errors.phone && (
											<FormErrorMessage>
												{errors.phone.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl
										isInvalid={errors.phone_secondary}
									>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Telefone Secundário
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Input
												{...register('phone_secondary')}
												as={InputMask}
												mask="(99) 99999-9999"
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
											/>
										)}
										{errors.phone_secondary && (
											<FormErrorMessage>
												{errors.phone_secondary.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.assigned_at}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Data do Contrato
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Input
												{...register('assigned_at')}
												as={InputMask}
												mask="99/99/9999"
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
											/>
										)}
										{errors.assigned_at && (
											<FormErrorMessage>
												{errors.assigned_at.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.modules}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Módulos
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Controller
												name="modules"
												{...register('modules[]')}
												control={control}
												render={({
													field: { onBlur, value }
												}) => (
													<ReactSelect
														name="plans"
														options={[
															{
																value: '1',
																label: 'Perfil Saúde'
															},
															{
																value: '2',
																label: 'Cuidado Coordenado'
															}
														]}
														isMulti
														isClearable
														backspaceRemovesValue
														placeholder="Selecione"
														onBlur={onBlur}
														selected={value}
														styles={useColorModeValue(
															msLightTheme,
															msDarkTheme
														)}
													/>
												)}
											/>
										)}
										{errors.modules && (
											<FormErrorMessage>
												{errors.modules.message}
											</FormErrorMessage>
										)}
									</FormControl>
								</Grid>
								<Flex w="full" mt="24px">
									<FormControl>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Logo do cliente
										</FormLabel>
										<HStack w="full" spacing={4}>
											<Box h="80px" w="80px">
												{loading ? (
													<Flex
														w="full"
														h="full"
														justifyContent="center"
														alignItems="center"
													>
														<Spinner size="md" />
													</Flex>
												) : (
													<Image
														width="full"
														height="auto"
														src={Logo}
														alt="Logo"
														me="10px"
													/>
												)}
											</Box>
											<Center
												{...getRootProps({
													className: 'dropzone'
												})}
												w="full"
											>
												<Flex
													align="center"
													justify="center"
													border="1px dashed #C7CCD4"
													borderRadius="15px"
													w="100%"
													padding="1em"
													minH="224px"
													cursor="pointer"
												>
													<Dropzone
														onDrop={handleDrop}
														maxFiles={1}
													>
														{({
															getRootProps,
															getInputProps
														}) => (
															<div
																{...getRootProps(
																	{
																		className:
																			'dropzone'
																	}
																)}
															>
																<input
																	{...getInputProps()}
																/>
																{files.length ===
																0 ? (
																		<p>
																		Arraste
																		ou
																		selecione
																		um
																		arquivo
																		</p>
																	) : (
																		<p>{`Arquivo selecionado: ${files[0].name}`}</p>
																	)}
															</div>
														)}
													</Dropzone>
												</Flex>
											</Center>
										</HStack>
									</FormControl>
								</Flex>
								<Divider my="5" />
								<Text fontSize="lg" fontWeight="bold" mb="12px">
									Take Blip
								</Text>
								<FormControl>
									<HStack alignItems="center" mb="24px">
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
											my="auto"
										>
											Whatsapp Personalizado
										</FormLabel>
										<Switch
											onChange={({ target }) =>
												setActiveWhats(target.checked)
											}
											colorScheme="green"
										/>
									</HStack>
								</FormControl>
								<Grid
									hidden={!activeWhats}
									templateColumns={{
										sm: '1fr',
										md: 'repeat(1, 1fr)'
									}}
									gap="24px"
								>
									<FormControl
										isInvalid={errors.whats_authorization}
									>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Cabeçalho de autenticação
											(Authorization)
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Input
												{...register(
													'whats_authorization'
												)}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
											/>
										)}
										{errors.whats_authorization && (
											<FormErrorMessage>
												{
													errors.whats_authorization
														.message
												}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl
										isInvalid={errors.whats_messages}
									>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Url para enviar mensagens
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Input
												{...register('whats_messages')}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
											/>
										)}
										{errors.whats_messages && (
											<FormErrorMessage>
												{errors.whats_messages.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl
										isInvalid={errors.whats_notifications}
									>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Url para enviar notificações
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Input
												{...register(
													'whats_notifications'
												)}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
											/>
										)}
										{errors.whats_notifications && (
											<FormErrorMessage>
												{
													errors.whats_notifications
														.message
												}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl
										isInvalid={errors.whats_commands}
									>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Url para enviar comandos
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Input
												{...register('whats_commands')}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
											/>
										)}
										{errors.whats_commands && (
											<FormErrorMessage>
												{errors.whats_commands.message}
											</FormErrorMessage>
										)}
									</FormControl>
								</Grid>
								<Flex
									w="full"
									justifyContent="flex-end"
									mt="24px"
								>
									<HStack spacing={4}>
										<Button
											isLoading={loadingUpdate}
											disabled={loadingUpdate}
											variant="ghost"
											colorScheme="gray"
											alignSelf="flex-end"
											size="md"
											onClick={() => history.goBack()}
										>
											Cancelar
										</Button>
										<Button
											isLoading={loadingUpdate}
											disabled={loadingUpdate}
											colorScheme="green"
											type="submit"
											alignSelf="flex-end"
											size="md"
										>
											Atualizar
										</Button>
									</HStack>
								</Flex>
							</Flex>
						</form>
					</FormProvider>
				</CardBody>
			</Card>
		</Flex>
	);
};

export default EditClient;
