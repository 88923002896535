const arc = {
	enableArcLinkLabels: false,
	arcLinkLabelsSkipAngle: 10,
	arcLinkLabelsTextColor: '#FFF',
	arcLinkLabelsThickness: 4,
	arcLabelsSkipAngle: 10
};

const arcLinkLabelsColor = { from: 'color' };

const arcLabelsTextColor = {
	from: 'color',
	modifiers: [['brighter', 8]]
};

const margin = {
	top: 10,
	right: 50,
	bottom: 20,
	left: 210
};

const legendsDark = [
	{
		anchor: 'left',
		direction: 'column',
		justify: false,
		translateX: -190,
		translateY: 0,
		itemsSpacing: 15,
		itemWidth: 50,
		itemHeight: 18,
		itemTextColor: '#FFF',
		itemDirection: 'left-to-right',
		itemOpacity: 1,
		symbolSize: 18,
		symbolShape: 'circle'
	}
];

const legendsLight = [
	{
		anchor: 'left',
		direction: 'column',
		justify: false,
		translateX: -190,
		translateY: 0,
		itemsSpacing: 15,
		itemWidth: 50,
		itemHeight: 18,
		itemTextColor: '#000',
		itemDirection: 'left-to-right',
		itemOpacity: 1,
		symbolSize: 18,
		symbolShape: 'circle'
	}
];

const pieProps = {
	sortByValue: false,
	innerRadius: 0.7,
	padAngle: 2,
	activeOuterRadiusOffset: 6,
	startAngle: -90,
	endAngle: 90
};

export {
	arc,
	arcLinkLabelsColor,
	arcLabelsTextColor,
	margin,
	legendsDark,
	legendsLight,
	pieProps
};
