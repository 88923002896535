import { CampaignsProvider as Provider } from 'hooks/campaigns';
import { RegionalsProvider } from 'hooks/regionals';
import { ManagerQueryProvider } from 'services/manager';
import { FiltersProvider } from 'hooks/filters';
import { UnitsProvider } from 'hooks/units';
import { FormsProvider } from 'hooks/forms';

const CampaignsProvider = ({ children }) => (
	<ManagerQueryProvider>
		<FiltersProvider>
			<RegionalsProvider>
				<UnitsProvider>
					<FormsProvider>
						<Provider>{children}</Provider>
					</FormsProvider>
				</UnitsProvider>
			</RegionalsProvider>
		</FiltersProvider>
	</ManagerQueryProvider>
);

export default CampaignsProvider;
