import {
	Button,
	Divider,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Grid,
	HStack,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	Skeleton,
	Text,
	useColorModeValue
} from '@chakra-ui/react';

import { useFormContext } from 'react-hook-form';
import { types, widths } from 'variables/customDashboards';
import { Plus, Trash } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';

const ChartModal = ({
	id,
	currentOpen,
	setOpen,
	defaultValues = [],
	forms,
	loadingForms,
	errors
}) => {
	const methods = useFormContext();

	const { register, unregister, watch, clearErrors } = methods;

	const wChart = watch(`charts.${id}`);
	const valueType = wChart?.value_type;

	const [counter, setCounter] = useState(0);
	const [values, setValues] = useState(defaultValues || []);

	const inputHover = useColorModeValue('health.800', 'health.800');

	const handleAddValue = () => {
		const id = counter;
		setValues([...values, { id }]);
		setCounter(counter + 1);
	};

	const handleValueRemove = (valueId) => {
		setValues(values.filter((value) => value.id !== valueId));
		unregister(`charts.${id}.values.${valueId}`);
	};

	useEffect(() => {
		if (valueType == 'common') {
			clearErrors(`charts.${id}.values`);
			unregister(`charts.${id}.values`);
		}
	}, [valueType]);

	return (
		<Modal
			isCentered
			onClose={() => setOpen(null)}
			isOpen={currentOpen == id}
			motionPreset="slideInBottom"
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Painel</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Grid
						templateColumns={{
							sm: '1fr',
							md: 'repeat(2, 1fr)'
						}}
						gap="15px"
						mb="24px"
					>
						<FormControl isInvalid={errors?.title}>
							<FormLabel fontWeight="bold" fontSize="xs">
								Nome do menu
							</FormLabel>
							<Input
								{...register(`charts.${id}.title`)}
								defaultValue={wChart?.title || null}
								focusBorderColor={inputHover}
								borderRadius="8px"
								fontSize="md"
							/>
							{errors?.title && (
								<FormErrorMessage>
									{errors?.title.message}
								</FormErrorMessage>
							)}
						</FormControl>
						<FormControl>
							<FormLabel fontWeight="bold" fontSize="xs">
								Subtítulo
							</FormLabel>
							<Input
								{...register(`charts.${id}.subtitle`)}
								focusBorderColor={inputHover}
								borderRadius="8px"
								fontSize="md"
							/>
						</FormControl>
						<FormControl>
							<FormLabel fontWeight="bold" fontSize="xs">
								Ajuda
							</FormLabel>
							<Input
								{...register(`charts.${id}.helper`)}
								focusBorderColor={inputHover}
								borderRadius="8px"
								fontSize="md"
							/>
						</FormControl>
						<FormControl isInvalid={errors?.chart_type}>
							<FormLabel fontWeight="bold" fontSize="xs">
								Tipo de Gráfico
							</FormLabel>
							<Select
								{...register(`charts.${id}.chart_type`)}
								borderRadius="8px"
								fontSize="md"
							>
								{types.map((tp) => (
									<option key={tp.value} value={tp.value}>
										{tp.label}
									</option>
								))}
							</Select>
							{errors?.chart_type && (
								<FormErrorMessage>
									{errors?.chart_type.message}
								</FormErrorMessage>
							)}
						</FormControl>
						{wChart?.chart_type != 'counter' && (
							<FormControl isInvalid={errors?.value_type}>
								<FormLabel fontWeight="bold" fontSize="xs">
									Seleção
								</FormLabel>
								<Select
									{...register(`charts.${id}.value_type`)}
									borderRadius="8px"
									fontSize="md"
								>
									<option value="manual">Manual</option>
									<option value="common">
										Perguntas Frequentes
									</option>
								</Select>
								{errors?.value_type && (
									<FormErrorMessage>
										{errors?.value_type.message}
									</FormErrorMessage>
								)}
							</FormControl>
						)}
						<FormControl isInvalid={errors?.width}>
							<FormLabel fontWeight="bold" fontSize="xs">
								Largura
							</FormLabel>
							<Select
								{...register(`charts.${id}.width`)}
								borderRadius="8px"
								fontSize="md"
							>
								{widths.map((wd) => (
									<option key={wd.value} value={wd.value}>
										{wd.label}
									</option>
								))}
							</Select>
							{errors?.width && (
								<FormErrorMessage>
									{errors?.width.message}
								</FormErrorMessage>
							)}
						</FormControl>
						{wChart?.chart_type == 'counter' && (
							<>
								<FormControl isInvalid={errors?.form_id}>
									<FormLabel fontWeight="bold" fontSize="xs">
										Formulário
									</FormLabel>
									{loadingForms ? (
										<Skeleton height="40px" />
									) : (
										<Select
											{...register(
												`charts.${id}.form_id`
											)}
											borderRadius="8px"
											fontSize="md"
										>
											{forms?.map((form) => (
												<option
													key={form.id}
													value={form.id}
												>
													{form.name}
												</option>
											))}
										</Select>
									)}
									{errors?.form_id && (
										<FormErrorMessage>
											{errors?.form_id.message}
										</FormErrorMessage>
									)}
								</FormControl>
								<FormControl isInvalid={errors?.form_label}>
									<FormLabel fontWeight="bold" fontSize="xs">
										Dado
									</FormLabel>
									<Input
										{...register(`charts.${id}.form_label`)}
										focusBorderColor={inputHover}
										borderRadius="8px"
										fontSize="md"
									/>
									{errors?.form_label && (
										<FormErrorMessage>
											{errors?.form_label.message}
										</FormErrorMessage>
									)}
								</FormControl>
								<FormControl isInvalid={errors?.value}>
									<FormLabel fontWeight="bold" fontSize="xs">
										Valor
									</FormLabel>
									<Input
										{...register(`charts.${id}.value`)}
										focusBorderColor={inputHover}
										borderRadius="8px"
										fontSize="md"
									/>
									{errors?.value && (
										<FormErrorMessage>
											{errors?.value.message}
										</FormErrorMessage>
									)}
								</FormControl>
							</>
						)}
						<FormControl isInvalid={errors?.order}>
							<FormLabel fontWeight="bold" fontSize="xs">
								Ordem (Posição)
							</FormLabel>
							<Input
								{...register(`charts.${id}.order`)}
								focusBorderColor={inputHover}
								type="number"
								borderRadius="8px"
								fontSize="md"
							/>
							{errors?.order && (
								<FormErrorMessage>
									{errors?.order.message}
								</FormErrorMessage>
							)}
						</FormControl>
					</Grid>
					{wChart?.value_type == 'manual' && (
						<Grid
							templateColumns={{
								sm: '1fr',
								md: 'repeat(1, 1fr)'
							}}
							gap="15px"
							mb="24px"
						>
							{values?.map((value, x) => (
								<>
									<HStack
										key={`${id}-${value.id}`}
										justifyContent="center"
									>
										<Grid
											templateColumns={{
												sm: '1fr',
												md: 'repeat(2, 1fr)'
											}}
											gap="15px"
										>
											<FormControl
												isInvalid={
													errors?.values &&
													errors?.values[x]?.form_id
												}
											>
												<FormLabel
													fontWeight="bold"
													fontSize="xs"
												>
													Formulário
												</FormLabel>
												{loadingForms ? (
													<Skeleton height="40px" />
												) : (
													<Select
														{...register(
															`charts.${id}.values.${value.id}.form_id`
														)}
														borderRadius="8px"
														fontSize="md"
													>
														{forms?.map((form) => (
															<option
																key={form.id}
																value={form.id}
															>
																{form.name}
															</option>
														))}
													</Select>
												)}
												{errors?.values &&
													errors?.values[x]
														?.form_id && (
													<FormErrorMessage>
														{
															errors?.values[
																x
															]?.form_id
																.message
														}
													</FormErrorMessage>
												)}
											</FormControl>
											<FormControl
												isInvalid={
													errors?.values &&
													errors?.values[x]
														?.form_label
												}
											>
												<FormLabel
													fontWeight="bold"
													fontSize="xs"
												>
													Dado
												</FormLabel>
												<Input
													{...register(
														`charts.${id}.values.${value.id}.form_label`
													)}
													focusBorderColor={
														inputHover
													}
													borderRadius="8px"
													fontSize="md"
												/>
												{errors?.values &&
													errors?.values[x]
														?.form_label && (
													<FormErrorMessage>
														{
															errors?.values[
																x
															]?.form_label
																.message
														}
													</FormErrorMessage>
												)}
											</FormControl>
											<FormControl
												isInvalid={
													errors?.values &&
													errors?.values[x]?.value
												}
											>
												<FormLabel
													fontWeight="bold"
													fontSize="xs"
												>
													Valor
												</FormLabel>
												<Input
													{...register(
														`charts.${id}.values.${value.id}.value`
													)}
													focusBorderColor={
														inputHover
													}
													borderRadius="8px"
													fontSize="md"
												/>
												{errors?.values &&
													errors?.values[x]
														?.value && (
													<FormErrorMessage>
														{
															errors?.values[
																x
															]?.value.message
														}
													</FormErrorMessage>
												)}
											</FormControl>
											<FormControl
												isInvalid={
													errors?.values &&
													errors?.values[x]?.color
												}
											>
												<FormLabel
													fontWeight="bold"
													fontSize="xs"
												>
													Cor
												</FormLabel>
												<Select
													{...register(
														`charts.${id}.values.${value.id}.color`
													)}
													borderRadius="8px"
													fontSize="md"
												>
													<option value="blue">
														Azul
													</option>
													<option value="green">
														Verde
													</option>
													<option value="orange">
														Laranja
													</option>
													<option value="red">
														Vermelho
													</option>
												</Select>
												{errors?.values &&
													errors?.values[x]
														?.color && (
													<FormErrorMessage>
														{
															errors?.values[
																x
															]?.color.message
														}
													</FormErrorMessage>
												)}
											</FormControl>
										</Grid>
										<Button
											colorScheme="orange"
											onClick={() =>
												handleValueRemove(value.id)
											}
											marginTop="auto"
										>
											<Text fontSize="md">
												<Trash size="22" />
											</Text>
										</Button>
									</HStack>
									{x + 1 !== values.length && <Divider />}
								</>
							))}
							{errors?.values_missing && (
								<Flex mb="24px">
									<Text fontSize="sm" color="red.500">
										{errors?.values_missing}
									</Text>
								</Flex>
							)}
						</Grid>
					)}
					{wChart?.value_type == 'manual' && (
						<Flex mb="24px">
							<Button
								colorScheme="green"
								leftIcon={<Plus size="22" />}
								onClick={() => handleAddValue()}
							>
									Adicionar valor
							</Button>
						</Flex>
					)}
					{wChart?.value_type == 'common' && (
						<Grid
							templateColumns={{
								sm: '1fr',
								md: 'repeat(2, 1fr)'
							}}
							gap="15px"
							mb="24px"
						>
							<FormControl isInvalid={errors?.form_id}>
								<FormLabel fontWeight="bold" fontSize="xs">
									Formulário
								</FormLabel>
								{loadingForms ? (
									<Skeleton height="40px" />
								) : (
									<Select
										{...register(`charts.${id}.form_id`)}
										borderRadius="8px"
										fontSize="md"
									>
										{forms?.map((form) => (
											<option
												key={form.id}
												value={form.id}
											>
												{form.name}
											</option>
										))}
									</Select>
								)}
								{errors?.form_id && (
									<FormErrorMessage>
										{errors?.form_id.message}
									</FormErrorMessage>
								)}
							</FormControl>
							<FormControl isInvalid={errors?.form_label}>
								<FormLabel fontWeight="bold" fontSize="xs">
									Dado
								</FormLabel>
								<Input
									{...register(`charts.${id}.form_label`)}
									focusBorderColor={inputHover}
									borderRadius="8px"
									fontSize="md"
								/>
								{errors?.form_label && (
									<FormErrorMessage>
										{errors?.form_label.message}
									</FormErrorMessage>
								)}
							</FormControl>
							<FormControl isInvalid={errors?.color}>
								<FormLabel fontWeight="bold" fontSize="xs">
									Cor
								</FormLabel>
								<Select
									{...register(`charts.${id}.color`)}
									borderRadius="8px"
									fontSize="md"
								>
									<option value="blue">Azul</option>
									<option value="green">Verde</option>
									<option value="orange">Laranja</option>
									<option value="red">Vermelho</option>
								</Select>
								{errors?.color && (
									<FormErrorMessage>
										{errors?.color.message}
									</FormErrorMessage>
								)}
							</FormControl>
							<FormControl isInvalid={errors?.max_lines}>
								<FormLabel fontWeight="bold" fontSize="xs">
									Quantidade de linha
								</FormLabel>
								<Input
									{...register(`charts.${id}.max_lines`)}
									type="number"
									focusBorderColor={inputHover}
									borderRadius="8px"
									fontSize="md"
								/>
								{errors?.max_lines && (
									<FormErrorMessage>
										{errors?.max_lines.message}
									</FormErrorMessage>
								)}
							</FormControl>
						</Grid>
					)}
				</ModalBody>
				<ModalFooter>
					<Button
						variant="ghost"
						size="md"
						onClick={() => setOpen(null)}
					>
						Fechar
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default ChartModal;
