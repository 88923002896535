import { createContext, useContext, useReducer, useState } from 'react';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import {
	GET_REPORTS_PAGINATE,
	GET_REPORTS,
	CREATE_REPORT,
	GET_REPORT,
	UPDATE_REPORT,
	DELETE_REPORT
} from 'graphql/customReports';

import { ACTION_TYPES, reducers } from './reducers';

const ReportsStateContext = createContext();
const ReportsDispatchContext = createContext();

const initialState = {
	rows: [],
	report: {},
	page: 1,
	perPage: 15
};

const CustomReportsProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducers, initialState);
	const [toDelete, setToDelete] = useState(null);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);

	return (
		<ReportsStateContext.Provider
			value={{
				...state,
				toDelete,
				setToDelete,
				deleteModalOpen,
				setDeleteModalOpen
			}}
		>
			<ReportsDispatchContext.Provider value={dispatch}>
				{children}
			</ReportsDispatchContext.Provider>
		</ReportsStateContext.Provider>
	);
};

const useReports = () => {
	const context = useContext(ReportsStateContext);

	if (!context)
		throw new Error(
			'useReports must be used within an CustomReportsProvider'
		);

	return context;
};

const useDispatch = () => {
	const dispatch = useContext(ReportsDispatchContext);

	if (dispatch === undefined)
		throw new Error(
			'useDispatch must be used within a CustomReportsProvider'
		);

	return dispatch;
};

const usePaginateReports = () => {
	const dispatch = useDispatch();

	return useLazyQuery(GET_REPORTS_PAGINATE, {
		onCompleted: (data) => {
			window.history.replaceState(
				null,
				'',
				`./relatorios-custom?page=${data.paginateReports.paginatorInfo.currentPage}&size=${data.paginateReports.paginatorInfo.perPage}`
			);
			dispatch({
				type: ACTION_TYPES.LIST,
				data: data.paginateReports
			});
		}
	});
};

const useGetReports = () => useQuery(GET_REPORTS);

const useCreateReport = (options = {}) => {
	const dispatch = useDispatch();
	const { onSuccess, onError } = options;

	return useMutation(CREATE_REPORT, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data);
			dispatch({ type: ACTION_TYPES.ADD, data: data.createReport });
		},
		onError: (error) => {
			if (onError) onError(error);
		}
	});
};

const useReport = (id) => {
	const dispatch = useContext(ReportsDispatchContext);

	return useQuery(
		GET_REPORT,
		{
			fetchPolicy: 'no-cache',
			variables: {
				id
			}
		},
		{
			onCompleted: (data) => {
				dispatch({ type: ACTION_TYPES.VIEW, data: data.getReport });
			}
		}
	);
};

const useUpdateReport = (options = {}) => {
	const dispatch = useContext(ReportsDispatchContext);
	const { onSuccess, onError } = options;

	return useMutation(UPDATE_REPORT, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data);
			dispatch({ type: ACTION_TYPES.UPDATE, data: data.updateReport });
		},
		onError: (error) => {
			if (onError) onError(error);
		}
	});
};

const useDeleteReport = (options = {}) => {
	const dispatch = useDispatch();
	const { onSuccess } = options;

	return useMutation(DELETE_REPORT, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess();
			dispatch({ type: ACTION_TYPES.REMOVE, data: data.deleteReport });
		}
	});
};

export {
	CustomReportsProvider,
	useReports,
	useCreateReport,
	usePaginateReports,
	useGetReports,
	useReport,
	useUpdateReport,
	useDeleteReport
};
