import { Grid, HStack, Skeleton, Text, VStack } from '@chakra-ui/react';
import { phoneFormat, cpfFormat } from 'functions/maskFormats';

import { status } from 'variables/beneficiariesStatus';

const BeneficiaryDetails = ({ beneficiary, isLoading = true }) => (
	<VStack spacing={5} w="full" alignItems="flex-start">
		<VStack spacing={1} alignItems="flex-start">
			<Text fontSize="md">Nome completo</Text>
			<Skeleton height="27px" minW="140" isLoaded={!isLoading}>
				<Text fontSize="lg">{beneficiary.name}</Text>
			</Skeleton>
		</VStack>
		<VStack spacing={1} alignItems="flex-start">
			<Text fontSize="md">Status</Text>
			<Skeleton height="27px" minW="140" isLoaded={!isLoading}>
				<Text fontSize="lg">
					{beneficiary.status &&
						status.find((st) => st.value == beneficiary.status)
							?.label}
				</Text>
			</Skeleton>
		</VStack>
		<VStack spacing={1} alignItems="flex-start">
			<Text fontSize="md">Regional</Text>
			<Skeleton height="27px" minW="140" isLoaded={!isLoading}>
				<Text fontSize="lg">{beneficiary.unit?.regional?.name}</Text>
			</Skeleton>
		</VStack>
		<VStack spacing={1} alignItems="flex-start">
			<Text fontSize="md">Unidade</Text>
			<Skeleton height="27px" minW="140" isLoaded={!isLoading}>
				<Text fontSize="lg">{beneficiary.unit?.name}</Text>
			</Skeleton>
		</VStack>
		<Grid
			w="full"
			templateColumns={{ sm: '1fr', md: 'repeat(2, 1fr)' }}
			gap="24px"
			mb="24px"
		>
			<VStack spacing={1} alignItems="flex-start">
				<Text fontSize="md">CPF</Text>
				<Skeleton height="27px" minW="140" isLoaded={!isLoading}>
					<Text fontSize="lg">{cpfFormat(beneficiary.cpf)}</Text>
				</Skeleton>
			</VStack>
			<VStack spacing={1} alignItems="flex-start">
				<Text fontSize="md">E-mail</Text>
				<Skeleton height="27px" minW="140" isLoaded={!isLoading}>
					<Text fontSize="lg">{beneficiary.email}</Text>
				</Skeleton>
			</VStack>
			<VStack spacing={1} alignItems="flex-start">
				<Text fontSize="md">Telefone</Text>
				<Skeleton height="27px" minW="140" isLoaded={!isLoading}>
					<Text fontSize="lg">{phoneFormat(beneficiary.phone)}</Text>
				</Skeleton>
			</VStack>
		</Grid>
		<VStack spacing={1} alignItems="flex-start">
			<HStack spacing={4} justifyContent="space-between" />
		</VStack>
	</VStack>
);

export default BeneficiaryDetails;
