import { Link, useHistory, useParams } from 'react-router-dom';
import {
	Button,
	Divider,
	Flex,
	FormControl,
	FormLabel,
	Grid,
	HStack,
	Skeleton,
	Spinner,
	Text,
	useColorModeValue
} from '@chakra-ui/react';

import { useDashboard } from 'hooks/customDashboards';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import { types } from 'variables/customDashboards';

const ViewDashboard = () => {
	const { id } = useParams();
	const { data, loading } = useDashboard(id);
	const dashboard = data?.getDashboard || {};

	const history = useHistory();

	const textColor = useColorModeValue('gray.700', 'white');

	return (
		<Flex
			direction="column"
			minH="100vh"
			align="center"
			pt={{ sm: '120px', lg: '160px' }}
		>
			<Card w={{ md: '100%', lg: '50%' }}>
				<CardHeader mb="40px">
					<Flex direction="row" w="100%" justify="space-between">
						<Text
							color={textColor}
							fontSize="lg"
							fontWeight="bold"
							mb="3px"
						>
							Dashboard
						</Text>
						<Flex>
							<HStack spacing={4} alignItems="flex-start">
								<Link to={`${id}/editar`}>
									<Button colorScheme="green" size="md">
										Editar
									</Button>
								</Link>
								<Button
									variant="ghost"
									colorScheme="gray"
									size="md"
									onClick={() => history.goBack()}
								>
									Voltar
								</Button>
							</HStack>
						</Flex>
					</Flex>
				</CardHeader>
				<CardBody>
					<Flex direction="column" w="100%">
						<Grid
							w="full"
							templateColumns={{
								sm: '1fr',
								md: 'repeat(1, 1fr)'
							}}
							gap="24px"
							mt="24px"
						>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Nome
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text
										color={textColor}
										fontSize="lg"
										fontWeight="bold"
										mb="3px"
									>
										{dashboard.name}
									</Text>
								</Skeleton>
							</FormControl>
							{loading ? (
								<Flex w="full" justifyContent="center">
									<Spinner size="md" />
								</Flex>
							) : (
								dashboard.charts
									?.sort((a, b) =>
										a.order > b.order ? 1 : -1
									)
									.map((chart, x) => (
										<>
											<HStack key={chart.id}>
												<Text>
													{`#  ${chart.title} - ${
														types.find(
															(type) =>
																type.value ==
																chart.chart_type
														)?.label
													}`}
												</Text>
											</HStack>
											{x + 1 !==
												dashboard?.charts?.length && (
												<Divider />
											)}
										</>
									))
							)}
						</Grid>
					</Flex>
				</CardBody>
			</Card>
		</Flex>
	);
};

export default ViewDashboard;
