import { FiltersProvider } from 'hooks/filters';
import { FormsProvider } from 'hooks/forms';
import { CustomDashboardsProvider as Provider } from 'hooks/customDashboards';
import { ManagerQueryProvider } from 'services/manager';

const CustomDashboardsProvider = ({ children }) => (
	<ManagerQueryProvider>
		<FormsProvider>
			<FiltersProvider>
				<Provider>{children}</Provider>
			</FiltersProvider>
		</FormsProvider>
	</ManagerQueryProvider>
);

export default CustomDashboardsProvider;
