const ACTION_TYPES = {
	LIST: 'LIST',
	VIEW: 'VIEW',
	ADD: 'ADD',
	UPDATE: 'UPDATE',
	REMOVE: 'REMOVE'
};

const reducers = (state, action) => {
	switch (action.type) {
		case ACTION_TYPES.LIST: {
			return {
				...state,
				rows: action.data.data,
				page: action.data.paginatorInfo.currentPage,
				perPage: action.data.paginatorInfo.perPage,
				total: action.data.paginatorInfo.total,
				currentPage: action.data.paginatorInfo.currentPage
			};
		}
		case ACTION_TYPES.ADD: {
			return {
				...state,
				rows: [...state.rows, action.data]
			};
		}
		case ACTION_TYPES.VIEW: {
			return {
				...state,
				unit: action.data.getUnit
			};
		}
		case ACTION_TYPES.UPDATE: {
			return {
				...state,
				rows: state.rows.map((row) => {
					if (row.id == action.data.id) row = action.data;
					return row;
				})
			};
		}
		case ACTION_TYPES.REMOVE: {
			return {
				...state,
				rows: state.rows.filter(({ id }) => id !== action.data.id)
			};
		}
		default: {
			return state;
		}
	}
};

export { reducers, ACTION_TYPES };
