import { useEffect } from 'react';
import { Text } from '@chakra-ui/react';

import {
	useBeneficiaries,
	usePaginateBeneficiaries
} from 'hooks/beneficiaries';
import { useFilters } from 'hooks/filters';

import AsyncTable from 'components/Tables/AsyncTable';

import dateFormat from 'functions/dateFormat';
import { phoneFormat, cpfFormat } from 'functions/maskFormats';

import { status } from 'variables/beneficiariesStatus';

const columns = [
	{
		Header: 'Nome',
		accessor: 'name'
	},
	{
		Header: 'Telefone',
		accessor: 'phone',
		Cell: ({ value }) => <Text>{phoneFormat(value)}</Text>
	},
	{
		Header: 'CPF',
		accessor: 'cpf',
		Cell: ({ value }) => <Text>{cpfFormat(value)}</Text>
	},
	{
		Header: 'Status',
		accessor: 'status',
		Cell: ({ value }) => (
			<Text>{status.find((st) => st.value == value)?.label}</Text>
		)
	},
	{
		Header: 'Regional',
		accessor: 'unit.regional.name'
	},
	{
		Header: 'Data de criação',
		accessor: 'created_at',
		Cell: ({ value }) => <Text>{dateFormat(value)}</Text>
	}
];

const BeneficiariesTable = () => {
	const { filters } = useFilters();
	const { rows, page, perPage, total, setToDelete, setDeleteModalOpen } =
		useBeneficiaries();
	const [paginateBeneficiaries, { loading }] = usePaginateBeneficiaries();

	const deleteBeneficiary = (id) => {
		setToDelete(id);
		setDeleteModalOpen(true);
	};

	const fetchData = ({ pageSize, pageIndex, search }) => {
		if (pageSize != perPage) pageIndex = 1;
		paginateBeneficiaries({
			variables: {
				first: pageSize || perPage,
				page: pageIndex || page,
				search: search || '',
				...filters
			}
		});
	};

	useEffect(() => {
		if (filters && Object.keys(filters).length > 0) {
			paginateBeneficiaries({
				variables: {
					first: perPage,
					page,
					...filters
				}
			});
		}
	}, [filters]);

	return (
		<AsyncTable
			fetchData={fetchData}
			isLoaded={!loading}
			data={!loading ? rows : [{}, {}, {}, {}]}
			columns={columns}
			total={total}
			size={perPage}
			asyncPage={page}
			searchByName
			baseRoute="gestao-vidas"
			removeAction={deleteBeneficiary}
			linkRow
		/>
	);
};

export default BeneficiariesTable;
