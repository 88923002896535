import { useForms, usePaginateForms } from 'hooks/forms';

import { Text } from '@chakra-ui/react';

import AsyncTable from 'components/Tables/AsyncTable';
import dateFormat from 'functions/dateFormat';

const columns = [
	{
		Header: 'Nome',
		accessor: 'name',
		isLink: true
	},
	{
		Header: 'Tag Builder',
		accessor: 'builder_tag'
	},
	{
		Header: 'Cadastrado em',
		accessor: 'created_at',
		Cell: ({ value }) => <Text>{dateFormat(value)}</Text>
	}
];

const FormsTable = () => {
	const { rows, page, perPage, total } = useForms();
	const [paginateClients, { loading }] = usePaginateForms();

	const fetchData = ({ pageSize, pageIndex, search }) => {
		if (pageSize !== perPage) pageIndex = 1;
		paginateClients({
			variables: {
				first: pageSize || perPage,
				page: pageIndex || page,
				search: search || ''
			}
		});
	};

	return (
		<AsyncTable
			fetchData={fetchData}
			isLoaded={!loading}
			data={!loading ? rows : [{}, {}, {}, {}]}
			columns={columns}
			total={total}
			size={perPage}
			asyncPage={page}
			searchByName
			baseRoute="formularios"
			noDelete
			linkRow
		/>
	);
};

export default FormsTable;
