import { createContext, useContext, useReducer, useState } from 'react';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import {
	GET_DASHBOARDS_PAGINATE,
	GET_DASHBOARDS,
	CREATE_DASHBOARD,
	GET_DASHBOARD,
	UPDATE_DASHBOARD,
	DELETE_DASHBOARD
} from 'graphql/customDashboards';

import { ACTION_TYPES, reducers } from './reducers';

const DashboardsStateContext = createContext();
const DashboardsDispatchContext = createContext();

const initialState = {
	rows: [],
	dashboard: {},
	page: 1,
	perPage: 15
};

const CustomDashboardsProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducers, initialState);
	const [toDelete, setToDelete] = useState(null);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);

	return (
		<DashboardsStateContext.Provider
			value={{
				...state,
				toDelete,
				setToDelete,
				deleteModalOpen,
				setDeleteModalOpen
			}}
		>
			<DashboardsDispatchContext.Provider value={dispatch}>
				{children}
			</DashboardsDispatchContext.Provider>
		</DashboardsStateContext.Provider>
	);
};

const useDashboards = () => {
	const context = useContext(DashboardsStateContext);

	if (!context)
		throw new Error(
			'useDashboards must be used within an CustomDashboardsProvider'
		);

	return context;
};

const useDispatch = () => {
	const dispatch = useContext(DashboardsDispatchContext);

	if (dispatch === undefined)
		throw new Error(
			'useDispatch must be used within a CustomDashboardsProvider'
		);

	return dispatch;
};

const usePaginateDashboards = () => {
	const dispatch = useDispatch();

	return useLazyQuery(GET_DASHBOARDS_PAGINATE, {
		onCompleted: (data) => {
			window.history.replaceState(
				null,
				'',
				`./custom-dashboards?page=${data.paginateDashboards.paginatorInfo.currentPage}&size=${data.paginateDashboards.paginatorInfo.perPage}`
			);
			dispatch({
				type: ACTION_TYPES.LIST,
				data: data.paginateDashboards
			});
		}
	});
};

const useGetDashboards = () => useQuery(GET_DASHBOARDS);

const useCreateDashboard = (options = {}) => {
	const dispatch = useDispatch();
	const { onSuccess, onError } = options;

	return useMutation(CREATE_DASHBOARD, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data);
			dispatch({ type: ACTION_TYPES.ADD, data: data.createDashboard });
		},
		onError: (error) => {
			if (onError) onError(error);
		}
	});
};

const useDashboard = (id) => {
	const dispatch = useContext(DashboardsDispatchContext);

	return useQuery(
		GET_DASHBOARD,
		{
			fetchPolicy: 'no-cache',
			variables: {
				id
			}
		},
		{
			onCompleted: (data) => {
				dispatch({ type: ACTION_TYPES.VIEW, data: data.getDashboard });
			}
		}
	);
};

const useUpdateDashboard = (options = {}) => {
	const dispatch = useContext(DashboardsDispatchContext);
	const { onSuccess, onError } = options;

	return useMutation(UPDATE_DASHBOARD, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data);
			dispatch({ type: ACTION_TYPES.UPDATE, data: data.updateDashboard });
		},
		onError: (error) => {
			if (onError) onError(error);
		}
	});
};

const useDeleteDashboard = (options = {}) => {
	const dispatch = useDispatch();
	const { onSuccess } = options;

	return useMutation(DELETE_DASHBOARD, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess();
			dispatch({ type: ACTION_TYPES.REMOVE, data: data.deleteDashboard });
		}
	});
};

export {
	CustomDashboardsProvider,
	useDashboards,
	useCreateDashboard,
	usePaginateDashboards,
	useGetDashboards,
	useDashboard,
	useUpdateDashboard,
	useDeleteDashboard
};
