import ExampleDashboard from 'views/Dashboards/ExampleDashboard';
import CustomDashboard from 'views/Dashboards/CustomDashboard';

import BeneficiariesProvider from 'providers/beneficiariesProvider';
import Beneficiaries from 'views/Beneficiaries';
import CreateBeneficiary from 'views/Beneficiaries/CreateBeneficiary';
import ViewBeneficiary from 'views/Beneficiaries/ViewBeneficiary';
import EditBeneficiary from 'views/Beneficiaries/EditBeneficiary';

import ClientsProvider from 'providers/clientsProvider';
import Clients from 'views/Clients';
import CreateClient from 'views/Clients/CreateClient';
import ViewClient from 'views/Clients/ViewClient';
import EditClient from 'views/Clients/EditClient';

import RegionalsProvider from 'providers/regionalsProvider';
import Regionals from 'views/Regionals';
import CreateRegional from 'views/Regionals/CreateRegional';
import ViewRegional from 'views/Regionals/ViewRegional';
import EditRegional from 'views/Regionals/EditRegional';

import UnitsProvider from 'providers/unitsProvider';
import Units from 'views/Units';
import CreateUnit from 'views/Units/CreateUnit';
import ViewUnit from 'views/Units/ViewUnit';
import EditUnit from 'views/Units/EditUnit';

import UsersProvider from 'providers/usersProvider';
import Users from 'views/Users';
import CreateUser from 'views/Users/CreateUser';
import ViewUser from 'views/Users/ViewUser';
import EditUser from 'views/Users/EditUser';

import FormsProvider from 'providers/formsProvider';
import Forms from 'views/Forms';
import CreateForm from 'views/Forms/CreateForm';
import ViewForm from 'views/Forms/ViewForm';
import EditForm from 'views/Forms/EditForm';

import CustomReportsProvider from 'providers/customReportsProvider';
import CustomReports from 'views/CustomReports';
import CreateReport from 'views/CustomReports/CreateReport';
import ViewReport from 'views/CustomReports/ViewReport';
import EditReport from 'views/CustomReports/EditReport';

import CustomDashboardsProvider from 'providers/customDashboardProvider';
import CustomDashboards from 'views/CustomDashboards';
import CreateDashboard from 'views/CustomDashboards/CreateDashboard';
import ViewDashboard from 'views/CustomDashboards/ViewDashboard';
import EditDashboard from 'views/CustomDashboards/EditDashboard';

import Tickets from 'views/Tickets';
import TicketsProvider from 'providers/ticketsProvider';
import CreateTicket from 'views/Tickets/CreateTicket';
import ViewTicket from 'views/Tickets/ViewTicket';
import EditTicket from 'views/Tickets/EditTicket';

import Campaigns from 'views/Campaigns';
import CreateCampaign from 'views/Campaigns/CreateCampaign';
import ViewCampaign from 'views/Campaigns/ViewCampaign';
import EditCampaign from 'views/Campaigns/EditCampaign';
import CampaignsProvider from 'providers/campaignsProvider';

const routes = [
	// DASHBOARDS
	{
		name: 'exemplo',
		path: '/dashboards/exemplo',
		component: ExampleDashboard,
		provider: ClientsProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'exemplo',
		path: '/dashboards/:id',
		component: CustomDashboard,
		provider: ClientsProvider,
		layout: '/sistema',
		roles: []
	},
	// GESTÃO DE VIDAS
	{
		name: 'viewBeneficiaries',
		path: '/administracao/gestao-vidas',
		component: Beneficiaries,
		provider: BeneficiariesProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'createBeneficiary',
		path: '/administracao/gestao-vidas/cadastrar',
		component: CreateBeneficiary,
		provider: BeneficiariesProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'viewBeneficiary',
		path: '/administracao/gestao-vidas/:id',
		component: ViewBeneficiary,
		provider: BeneficiariesProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'editBeneficiary',
		path: '/administracao/gestao-vidas/:id/editar',
		component: EditBeneficiary,
		provider: BeneficiariesProvider,
		layout: '/sistema',
		roles: []
	},
	// CLIENTES
	{
		name: 'clientes',
		path: '/administracao/clientes',
		component: Clients,
		provider: ClientsProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'clientes',
		path: '/administracao/clientes/cadastrar',
		component: CreateClient,
		provider: ClientsProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'clientes',
		path: '/administracao/clientes/:id',
		component: ViewClient,
		provider: ClientsProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'clientes',
		path: '/administracao/clientes/:id/editar',
		component: EditClient,
		provider: ClientsProvider,
		layout: '/sistema',
		roles: []
	},
	// REGIONAIS
	{
		name: 'regionais',
		path: '/administracao/regionais',
		component: Regionals,
		provider: RegionalsProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'regionais',
		path: '/administracao/regionais/cadastrar',
		component: CreateRegional,
		provider: RegionalsProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'regionais',
		path: '/administracao/regionais/:id',
		component: ViewRegional,
		provider: RegionalsProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'regionais',
		path: '/administracao/regionais/:id/editar',
		component: EditRegional,
		provider: RegionalsProvider,
		layout: '/sistema',
		roles: []
	},
	// UNIDADES
	{
		name: 'unidades',
		path: '/administracao/unidades',
		component: Units,
		provider: UnitsProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'unidades',
		path: '/administracao/unidades/cadastrar',
		component: CreateUnit,
		provider: UnitsProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'unidades',
		path: '/administracao/unidades/:id',
		component: ViewUnit,
		provider: UnitsProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'unidades',
		path: '/administracao/unidades/:id/editar',
		component: EditUnit,
		provider: UnitsProvider,
		layout: '/sistema',
		roles: []
	},
	// FORMULÁRIOS
	{
		name: 'formularios',
		path: '/administracao/formularios',
		component: Forms,
		provider: FormsProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'formularios',
		path: '/administracao/formularios/cadastrar',
		component: CreateForm,
		provider: FormsProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'formularios',
		path: '/administracao/formularios/:id',
		component: ViewForm,
		provider: FormsProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'formularios',
		path: '/administracao/formularios/:id/editar',
		component: EditForm,
		provider: FormsProvider,
		layout: '/sistema',
		roles: []
	},
	// REPORTS CUSTOMIZADOS
	{
		name: 'reports',
		path: '/administracao/relatorios-custom',
		component: CustomReports,
		provider: CustomReportsProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'createReport',
		path: '/administracao/relatorios-custom/cadastrar',
		component: CreateReport,
		provider: CustomReportsProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'viewReport',
		path: '/administracao/relatorios-custom/:id',
		component: ViewReport,
		provider: CustomReportsProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'editReport',
		path: '/administracao/relatorios-custom/:id/editar',
		component: EditReport,
		provider: CustomReportsProvider,
		layout: '/sistema',
		roles: []
	},
	// DASHBOARDS CUSTOMIZADOS
	{
		name: 'dashboards',
		path: '/administracao/custom-dashboards',
		component: CustomDashboards,
		provider: CustomDashboardsProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'createDashboard',
		path: '/administracao/custom-dashboards/cadastrar',
		component: CreateDashboard,
		provider: CustomDashboardsProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'viewDashboard',
		path: '/administracao/custom-dashboards/:id',
		component: ViewDashboard,
		provider: CustomDashboardsProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'editDashboard',
		path: '/administracao/custom-dashboards/:id/editar',
		component: EditDashboard,
		provider: CustomDashboardsProvider,
		layout: '/sistema',
		roles: []
	},
	// SUPORTE
	{
		name: 'suporte',
		path: '/administracao/suporte',
		component: Tickets,
		provider: TicketsProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'suporte',
		path: '/administracao/suporte/cadastrar',
		component: CreateTicket,
		provider: TicketsProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'suporte',
		path: '/administracao/suporte/:id',
		component: ViewTicket,
		provider: TicketsProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'suporte',
		path: '/administracao/suporte/:id/editar',
		component: EditTicket,
		provider: TicketsProvider,
		layout: '/sistema',
		roles: []
	},
	// USUARIOS
	{
		name: 'usuarios',
		path: '/administracao/usuarios',
		component: Users,
		provider: UsersProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'usuarios',
		path: '/administracao/usuarios/cadastrar',
		component: CreateUser,
		provider: UsersProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'usuarios',
		path: '/administracao/usuarios/:id',
		component: ViewUser,
		provider: UsersProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'usuarios',
		path: '/administracao/usuarios/:id/editar',
		component: EditUser,
		provider: UsersProvider,
		layout: '/sistema',
		roles: []
	},
	// CAMPANHAS
	{
		name: 'campanhas',
		path: '/administracao/campanhas',
		component: Campaigns,
		provider: CampaignsProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'campanhas',
		path: '/administracao/campanhas/cadastrar',
		component: CreateCampaign,
		provider: CampaignsProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'campanhas',
		path: '/administracao/campanhas/:id',
		component: ViewCampaign,
		provider: CampaignsProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'campanhas',
		path: '/administracao/campanhas/:id/editar',
		component: EditCampaign,
		provider: CampaignsProvider,
		layout: '/sistema',
		roles: []
	}
];

export default routes;
