const beneficiaryValues = [
	{
		label: 'Nome',
		value: 'name'
	},
	{
		label: 'CPF',
		value: 'cpf'
	},
	{
		label: 'Telefone',
		value: 'phone'
	},
	{
		label: 'E-mail',
		value: 'email'
	},
];

const campaignValues = [
	{
		label: 'Nome',
		value: 'name'
	},
	{
		label: 'Unidade',
		value: 'unit'
	},
	{
		label: 'Regional',
		value: 'regional'
	},
	{
		label: 'Formulário',
		value: 'form'
	},
];

export { beneficiaryValues, campaignValues };
