import { ResponsiveBar } from '@nivo/bar';
import { padding } from 'variables/nivo/bar';
import { useColorModeValue } from '@chakra-ui/react';
import {
	axisLeft,
	axisTop,
	themeDark,
	themeLight,
	tooltip
} from 'variables/nivo/default';
import { colors } from 'variables/customDashboards';

const Columns = ({ color }) => {
	const mockData = [
		{
			name: 'Teste 1',
			total: 10
		},
		{
			name: 'Teste 2',
			total: 12
		},
		{
			name: 'Teste 3',
			total: 8
		}
	];

	return (
		<ResponsiveBar
			data={mockData}
			keys={['total']}
			indexBy="name"
			layout="vertical"
			margin={{ top: 20, right: 20, bottom: 10, left: 50 }}
			padding={padding}
			colors={colors.find((cl) => cl.value == color)?.hex}
			labelSkipWidth={11}
			theme={useColorModeValue(themeLight, themeDark)}
			axisBottom={null}
			axisTop={axisTop}
			axisLeft={axisLeft}
			labelTextColor="#FFF"
			tooltip={({ indexValue, value }) => tooltip(indexValue, value)}
		/>
	);
};

export default Columns;
