import { Route } from 'react-router-dom';
import { JWT_TOKEN_COGNITO_ID } from '../../variables/enumLabels';

// eslint-disable-next-line no-unused-vars
const PrivateRoute = ({ component: Component, roles = [], ...props }) => {
	if (!window.localStorage.getItem(JWT_TOKEN_COGNITO_ID)) return null;
	return <Route {...props} render={() => <Component />} />;
};

export default PrivateRoute;
