import { gql } from '@apollo/client';

export const GET_CLIENTS = gql`
	query GetClients {
		listClients {
			id
			enabled
			name
			contact
			cnpj
			email
			phone
			phone_secondary
			assigned_at
			whats
			whats_authorization
			whats_messages
			whats_notifications
			whats_commands
			created_at
		}
	}
`;

export const GET_CLIENTS_PAGINATE = gql`
	query GET_CLIENTS($first: Int! = 30, $page: Int! = 1) {
		paginateClients(first: $first, page: $page) {
			paginatorInfo {
				count
				currentPage
				firstItem
				perPage
				lastItem
				total
			}
			data {
				id
				enabled
				name
				contact
				cnpj
				email
				phone
				phone_secondary
				assigned_at
				whats
				whats_authorization
				whats_messages
				whats_notifications
				whats_commands
				created_at
			}
		}
	}
`;

export const GET_CLIENT = gql`
	query GetClient($id: ID!) {
		getClient(id: $id) {
			id
			enabled
			name
			contact
			cnpj
			email
			phone
			phone_secondary
			assigned_at
			whats
			whats_authorization
			whats_messages
			whats_notifications
			whats_commands
			created_at
		}
	}
`;

export const CREATE_CLIENT = gql`
	mutation CreateClient($input: CreateClientInput!) {
		createClient(input: $input) {
			id
			name
			email
			enabled
			created_at
		}
	}
`;

export const UPDATE_CLIENT = gql`
	mutation UpdateClient($input: UpdateClientInput!) {
		updateClient(input: $input) {
			id
		}
	}
`;
