import { gql } from '@apollo/client';

export const GET_USERS = gql`
	query GetUsers {
		listUsers {
			id
			client_id
			role_id
			cognito_user
			email
			name
			phone
			cpf
			status
			created_at
			updated_at
			deleted_at
		}
	}
`;

export const GET_USERS_PAGINATE = gql`
	query GET_USERS($first: Int! = 30, $page: Int! = 1) {
		users(first: $first, page: $page) {
			paginatorInfo {
				count
				currentPage
				firstItem
				perPage
				lastItem
				total
			}
			data {
				id
				client_id
				role_id
				cognito_user
				email
				name
				phone
				cpf
				status
				created_at
				updated_at
				deleted_at
			}
		}
	}
`;

export const GET_USER = gql`
	query GetUser($id: ID!) {
		user(id: $id) {
			id
			client_id
			role_id
			cognito_user
			email
			name
			phone
			cpf
			status
			created_at
			updated_at
			deleted_at
			roles {
				name
			}
		}
	}
`;

export const CREATE_USER = gql`
	mutation CreateUser($input: CreateUser) {
		createUser(input: $input) {
			id
			client_id
			role_id
			cognito_user
			email
			name
			phone
			cpf
			status
			created_at
			updated_at
			deleted_at
		}
	}
`;

export const UPDATE_USER = gql`
	mutation UpdateUser($input: UserUpdateInput) {
		updateUser(input: $input) {
			id
			client_id
			role_id
			cognito_user
			email
			name
			phone
			cpf
			status
			created_at
			updated_at
			deleted_at
		}
	}
`;
