import { Text } from '@chakra-ui/react';

import { usePaginateUsers, useUsers } from 'hooks/users';

import AsyncTable from 'components/Tables/AsyncTable';
import StatusIndicator from 'components/StatusIndicator';

import dateFormat from 'functions/dateFormat';

import queryParamsPage from 'functions/queryParamsPage';
import { useLocation } from 'react-router-dom';

const columns = [
	{
		Header: 'Nome',
		accessor: 'name'
	},
	{
		Header: 'E-mail',
		accessor: 'email'
	},
	{
		Header: 'Perfil',
		accessor: 'type'
	},
	{
		Header: 'Status',
		accessor: 'status',
		Cell: ({ value }) => <StatusIndicator isActive={value} size="sm" />
	},
	{
		Header: 'Data de criação',
		accessor: 'created_at',
		Cell: ({ value }) => <Text>{dateFormat(value)}</Text>
	},
	{
		Header: 'Regional',
		accessor: 'regional'
	}
];

const UsersTable = () => {
	const location = useLocation();
	const { page: queryPage, size: querySize } = queryParamsPage(location);
	const { loading, rows = [{}], total, perPage, currentPage } = useUsers();
	const [paginateUsers, { loading: reloading }] = usePaginateUsers();

	const fetchData = ({ pageSize, pageIndex, search }) => {
		if (pageSize !== perPage) {
			pageIndex = 1;
		}
		paginateUsers({
			variables: {
				first: pageSize || querySize || 30,
				page: pageIndex || queryPage || 1,
				search: search || ''
			}
		});
	};

	return (
		<AsyncTable
			fetchData={fetchData}
			isLoaded={!loading && !reloading}
			data={!loading && !reloading ? rows : [{}, {}, {}, {}]}
			columns={columns}
			total={total}
			size={perPage}
			asyncPage={currentPage}
			searchByName
			baseRoute="usuarios"
			noDelete
			linkRow
		/>
	);
};

export default UsersTable;
