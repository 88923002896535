import { Text, VStack } from '@chakra-ui/react';

const Campaigns = () => (
	<VStack
		w="full"
		spacing={4}
		justifyContent="flex-start"
		alignItems="flex-start"
	>
		<Text>Campanhas</Text>
	</VStack>
);

export default Campaigns;
