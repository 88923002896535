/* eslint-disable no-unused-vars */
const msLightTheme = {
	control: (provided, { data, isDisabled, isFocused, isSelected }) => ({
		...provided,
		backgroundColor: '#FFF',
		borderColor: '#69C49D',
		borderRadius: '8px',
		':active': {
			...provided[':active'],
			borderColor: '#69C49D',
			color: '#FFF'
		},
		':focus': {
			...provided[':focus'],
			borderColor: '#69C49D',
			boxShadow: '0 0 0 1px #69C49D',
			color: '#FFF'
		},
		':hover': {
			...provided[':hover'],
			borderColor: '#ffffff3d',
			color: '#FFF'
		},
		color: '#FFF',
		hover: {
			borderColor: '#CBD5E0'
		}
	}),
	menu: (provided, state) => ({
		...provided,
		backgroundColor: '#FFF',
		color: '#FFF'
	}),
	option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
		...provided,
		backgroundColor: isFocused || isSelected ? '#69C49D' : '#FFF',
		color: isFocused || isSelected ? '#FFF' : '#000',
		cursor: 'pointer'
	}),
	multiValue: (provided) => ({
		...provided,
		backgroundColor: '#69C49D',
		color: '#FFF'
	}),
	multiValueLabel: (provided) => ({
		...provided,
		color: '#FFF'
	})
};

const msDarkTheme = {
	control: (provided, { data, isDisabled, isFocused, isSelected }) => ({
		...provided,
		backgroundColor: '#1f2733',
		borderColor: '#ffffff29',
		borderRadius: '8px',
		':active': {
			...provided[':active'],
			borderColor: '#69C49D',
			color: '#FFF'
		},
		':focus': {
			...provided[':focus'],
			borderColor: '#69C49D',
			boxShadow: '0 0 0 1px #69c49d',
			color: '#FFF'
		},
		':hover': {
			...provided[':hover'],
			borderColor: '#ffffff3d',
			color: '#FFF'
		},
		color: '#FFF'
	}),
	container: (provided, state) => ({
		...provided,
		color: '#FFF',
		':active': {
			...provided[':active'],
			borderColor: '#69C49D'
		},
		':focus': {
			...provided[':focus'],
			borderColor: '#69C49D'
		},
		':hover': {
			...provided[':hover'],
			borderColor: '#ffffff3d'
		}
	}),
	menu: (provided, state) => ({
		...provided,
		backgroundColor: '#1A202C',
		color: '#FFF',
		fontColor: '#FFF'
	}),
	option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
		...provided,
		backgroundColor: isFocused || isSelected ? '#69C49D' : '#1A202C',
		color: '#FFF',
		cursor: 'pointer',
		fontColor: '#FFF'
	}),
	input: (provided) => ({
		...provided,
		color: '#FFF'
	}),
	singleValue: (provided) => ({
		...provided,
		color: '#FFF'
	}),
	multiValue: (provided) => ({
		...provided,
		backgroundColor: '#69C49D',
		color: '#FFF',
		fontColor: '#FFF'
	}),
	multiValueLabel: (provided) => ({
		...provided,
		color: '#FFF',
		fontColor: '#FFF'
	})
};

export { msLightTheme, msDarkTheme };
