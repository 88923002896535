import { UnitsProvider as Provider } from 'hooks/units';
import { RegionalsProvider } from 'hooks/regionals';
import { ManagerQueryProvider } from 'services/manager';
import { FiltersProvider } from 'hooks/filters';

const UnitsProvider = ({ children }) => (
	<ManagerQueryProvider>
		<FiltersProvider>
			<RegionalsProvider>
				<Provider>{children}</Provider>
			</RegionalsProvider>
		</FiltersProvider>
	</ManagerQueryProvider>
);

export default UnitsProvider;
