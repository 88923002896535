import { useHistory, useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useReport, useUpdateReport } from 'hooks/customReports';

import {
	Button,
	Divider,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Grid,
	HStack,
	Input,
	Select,
	Skeleton,
	Spinner,
	Text,
	useColorModeValue,
	useToast
} from '@chakra-ui/react';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import { useState, useEffect } from 'react';
import { Plus, Trash } from '@phosphor-icons/react';
import { beneficiaryValues , campaignValues } from 'variables/customReports';


const schema = yup.object().shape({
	name: yup.string().required('O campo Nome é obrigatório'),
	columns: yup.array().of(
		yup.object().shape({
			label: yup.string().required('O campo Nome é obrigatório'),
			type: yup.string().required('Escolha o Campo da coluna'),
			value: yup.string().required('O campo Valor é obrigatório'),
		})
	)
});

const EditReport = () => {
	const { id } = useParams();
	const { data, loading } = useReport(id);
	const report = data?.getReport || {};

	const methods = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		resolver: (data, context, options) => {
			const columns = [];
			const keys = Object.keys(data?.columns) || []
			keys.map(col => columns[data.columns[col].key] = data.columns[col])
			const newData = {
				...data,
				columns
			}
			return yupResolver(schema)(newData, context, options)
		}
	});

	const toast = useToast();
	const history = useHistory();

	const [counter, setCounter] = useState(0);
	const [columns, setColumns] = useState([]);

	const {
		register,
		unregister,
		handleSubmit,
		formState: { errors },
		setError,
		setValue,
		clearErrors,
		reset,
		watch
	} = methods;

	const wColumns = watch('columns');

	const isDisabled = (key) => {
		if (!wColumns) return true;
		if (wColumns[key]?.type) return false;
		return true;
	};

	const renderValueField = (key) => {
		if (!wColumns || !wColumns[key].type) return null;
		const { type } = wColumns[key];

		return type;
	};

	const renderOptions = (key) => {
		if (!wColumns || !wColumns[key].type) return [];
		const { type } = wColumns[key];
		switch (type) {
			case 'form':
				return [];
			case 'metric':
				return [];
			case 'beneficiary':
				return beneficiaryValues;
			case 'campaign':
				return campaignValues;
			default:
				break;
		}
		return [];
	};

	const handleAddColumn = () => {
		clearErrors(`columns_req`);
		const id = counter;
		setColumns([...columns, { id }]);
		setCounter(counter + 1);
	};

	const handleRemoveColumn = (id) => {
		clearErrors();
		setColumns(columns.filter((chart) => chart.id !== id));
		unregister(`columns.${id}`);
	};

	useEffect(() => {
		if (report && report.columns) {
			report.columns.forEach((column) => {
				setValue(`columns.${column.id}`, { ...column });
			});
			setColumns(report.columns);
		}
	}, [report]);

	const [updateReport, { loading: loadingUpdate }] = useUpdateReport({
		onSuccess: (data) => {
			reset();
			toast({
				title: 'Relatório atualizado com sucesso.',
				status: 'success',
				duration: 5000,
				isClosable: true
			});
			history.push(
				`/sistema/administracao/relatorios-custom/${data.updateReport.id}`
			);
		},
		onError: () => {
			toast({
				title: 'Ocorreu um erro',
				status: 'error',
				duration: 5000,
				isClosable: true
			});
		}
	});

	const onSubmit = (data) => {
		if (!data.columns || data.columns.length == 0) {
			setError('columns_req', {
				type: 'custom',
				message: 'Insira pelo menos uma coluna para o relatório'
			});
			return;
		}

		// console.log('data', data)

		const columnKeys = Object.keys(data.columns);
		const columns = columnKeys.map((key) => data.columns[key]);

		const createColumns = columns
			.filter((column) => !column.id)
			.map((column) => {
				delete column.key
				return {
					...column
				}
			});
		const updateColumns = columns
			.filter((column) => column.id)
			.map((column) => {
				delete column.key
				return {
					...column
				}
			});
		const currentColumns = report.columns.map((column) => column.id);
		const deleteColumns = currentColumns
			.filter((column) => !columns.find((c) => c.id == column))
			.map((column) => ({
				id: column
			}));

		delete data.columns;

		const variables = {
			input: {
				...data,
				id,
				columns: {
					create: createColumns,
					update: updateColumns,
					delete: deleteColumns
				}
			}
		};
		// console.log(variables)
		updateReport({ variables });
	};

	const inputHover = useColorModeValue('health.800', 'health.800');
	const textColor = useColorModeValue('gray.700', 'white');

	return (
		<Flex
			direction="column"
			minH="100vh"
			align="center"
			pt={{ sm: '120px', lg: '160px' }}
		>
			<Card w={{ md: '100%', lg: '50%' }}>
				<CardHeader mb="40px">
					<Flex direction="column">
						<Text
							color={textColor}
							fontSize="lg"
							fontWeight="bold"
							mb="3px"
						>
							Editar Relatório Customizado
						</Text>
						<Text
							color="gray.400"
							fontWeight="normal"
							fontSize="sm"
						>
							Preencha os campos para realizar o cadastro
						</Text>
					</Flex>
				</CardHeader>
				<CardBody>
					<FormProvider {...methods}>
						<form
							style={{ width: '100%' }}
							onSubmit={handleSubmit(onSubmit)}
						>
							<Flex direction="column" w="100%">
								<Grid
									templateColumns={{
										sm: '1fr',
										md: 'repeat(2, 1fr)'
									}}
									gap="24px"
									mb="24px"
								>
									<FormControl isInvalid={errors.name}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Nome do relatório
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Input
												{...register('name')}
												defaultValue={report.name}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
											/>
										)}
										{errors.name && (
											<FormErrorMessage>
												{errors.name.message}
											</FormErrorMessage>
										)}
									</FormControl>
								</Grid>
								<Grid
									templateColumns={{
										sm: '1fr',
										md: 'repeat(1, 1fr)'
									}}
									gap="15px"
									mb="24px"
								>
									{loading ?
										<Flex w="full" justifyContent="center">
											<Spinner size="md" />
										</Flex>
										:
										columns.map((column, x) => (
											<>
												<HStack
													key={`${column.id}`}
													alignItems="flex-start"
												>
													<FormControl
														isInvalid={errors.columns && errors.columns[x]?.label}
													>
														<Input type="hidden" {...register(`columns.${column.id}.key`)} value={x} />
														{loading ? (
															<Skeleton height="40px" />
														) : (
															<Input
																placeholder="Nome"
																defaultValue={
																	report.columns?.find(
																		(col) =>
																			col.id ==
																	column?.id
																	)?.label
																}
																{...register(
															`columns.${column.id}.label`
																)}
																focusBorderColor={
																	inputHover
																}
																borderRadius="8px"
																fontSize="md"
															/>
														)}
														{errors.columns &&
													errors.columns[x]?.label && (
															<FormErrorMessage>
																{
																	errors
																		.columns[x]?.label
																		.message
																}
															</FormErrorMessage>
														)}
													</FormControl>
													<FormControl
														isInvalid={
															errors.columns &&
													errors.columns[x]?.type
														}
													>
														{loading ? (
															<Skeleton height="40px" />
														) : (
															<Select
																placeholder="Selecione um campo"
																defaultValue={
																	report.columns?.find(
																		(col) =>
																			col.id ==
																	column?.id
																	)?.type
																}
																{...register(
															`columns.${column.id}.type`
																)}
																borderRadius="8px"
																fontSize="md"
															>
																<option value="form">
																	Formulário
																</option>
																<option value="metric">
																	Métrica
																</option>
																<option value="beneficiary">
																	Vida
																</option>
																<option value="campaign">
																	Campanha
																</option>
															</Select>
														)}
														{errors.columns &&
													errors.columns[x]?.type && (
															<FormErrorMessage>
																{
																	errors
																		.columns[x]?.type
																		.message
																}
															</FormErrorMessage>
														)}
													</FormControl>
													{(renderValueField(column.id) ==
												'beneficiary' || renderValueField(column.id) ==
												'campaign') && (
														<FormControl
															isInvalid={
																errors.columns &&
															errors.columns[x]?.value
															}
														>
															{loading ? (
																<Skeleton height="40px" />
															) : (
																<Select
																	placeholder={
																		isDisabled(
																			column.id
																		)
																			? 'Selecione um campo'
																			: 'Seleciona um valor'
																	}
																	defaultValue={
																		report.columns?.find(
																			(col) =>
																				col.id ==
																			column?.id
																		)?.value
																	}
																	disabled={isDisabled(
																		column.id
																	)}
																	{...register(
																	`columns.${column.id}.value`
																	)}
																	borderRadius="8px"
																	fontSize="md"
																>
																	{renderOptions(
																		column.id
																	).map(
																		(
																			opt,
																			key
																		) => (
																			<option
																				key={
																					key
																				}
																				value={
																					opt.value
																				}
																			>
																				{
																					opt.label
																				}
																			</option>
																		)
																	)}
																</Select>
															)}
															{errors.columns &&
															errors.columns[x]?.value && (
																<FormErrorMessage>
																	{
																		errors
																			.columns[x]?.value
																			.message
																	}
																</FormErrorMessage>
															)}
														</FormControl>
													)}
													{(renderValueField(column.id) ==
												'form' ||
												renderValueField(
													column.id
												) == 'metric') && (
														<FormControl
															isInvalid={
																errors.columns &&
															errors.columns[x]?.value
															}
														>
															{loading ? (
																<Skeleton height="40px" />
															) : (
																<Input
																	placeholder="Tag do Campo"
																	defaultValue={
																		report.columns?.find(
																			(col) =>
																				col.id ==
																			column?.id
																		)?.value
																	}
																	{...register(
																	`columns.${column.id}.value`
																	)}
																	focusBorderColor={
																		inputHover
																	}
																	borderRadius="8px"
																	fontSize="md"
																/>
															)}
															{errors.columns &&
															errors.columns[x]?.value && (
																<FormErrorMessage>
																	{
																		errors
																			.columns[x]?.value
																			.message
																	}
																</FormErrorMessage>
															)}
														</FormControl>
													)}
													<FormControl
														isInvalid={
															errors.columns &&
															errors.columns[x]?.order
														}
													>
														<Input
															placeholder="Ordem (Posição)"
															{...register(
																`columns.${column.id}.order`
															)}
															focusBorderColor={
																inputHover
															}
															borderRadius="8px"
															fontSize="md"
														/>
														{errors.columns &&
															errors.columns[x]
																?.order && (
															<FormErrorMessage>
																{
																	errors
																		.columns[
																			x
																		]?.order
																		.message
																}
															</FormErrorMessage>
														)}
													</FormControl>
													<Button
														colorScheme="orange"
														onClick={() =>
															handleRemoveColumn(
																column.id
															)
														}
														marginTop="auto"
													>
														<Text fontSize="md">
															<Trash size="22" />
														</Text>
													</Button>
												</HStack>
												{x + 1 !== columns.length && (
													<Divider />
												)}
											</>
										))}
									{errors.columns_req && (
										<Flex mb="24px">
											<Text fontSize="sm" color="red.500">
												{errors.columns_req.message}
											</Text>
										</Flex>
									)}
									<Flex mb="24px">
										<Button
											colorScheme="green"
											leftIcon={<Plus size="22" />}
											onClick={() => handleAddColumn()}
										>
											Adicionar coluna
										</Button>
									</Flex>
								</Grid>
								<Flex
									w="full"
									justifyContent="flex-end"
									mt="24px"
								>
									<HStack spacing={4}>
										<Button
											disabled={loadingUpdate}
											variant="ghost"
											colorScheme="gray"
											alignSelf="flex-end"
											size="md"
											onClick={() => history.goBack()}
										>
											Cancelar
										</Button>
										<Button
											isLoading={loadingUpdate}
											disabled={loadingUpdate}
											colorScheme="green"
											type="submit"
											alignSelf="flex-end"
											size="md"
										>
											Atualizar
										</Button>
									</HStack>
								</Flex>
							</Flex>
						</form>
					</FormProvider>
				</CardBody>
			</Card>
		</Flex>
	);
};

export default EditReport;
