import { Grid, GridItem } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import { useDashboard } from 'hooks/customDashboards';

import ChartContainer from 'components/Charts/ChartContainer';

import { widths } from 'variables/customDashboards';

import Counter from './charts/Counter';
import Bars from './charts/Bars';
import Columns from './charts/Columns';
import Donut from './charts/Donut';
import Velocimeter from './charts/Velocimeter';

const renderChart = (chart) => {
	let component = null;
	switch (chart.chart_type) {
		case 'counter':
			component = <Counter label={chart.title} value={10} />;
			break;
		case 'bars':
			component = (
				<Bars label={chart.title} color={chart.color} value={10} />
			);
			break;
		case 'columns':
			component = (
				<Columns label={chart.title} color={chart.color} value={10} />
			);
			break;
		case 'donut':
			component = (
				<Donut label={chart.title} color={chart.color} value={10} />
			);
			break;
		case 'velocimeter':
			component = (
				<Velocimeter
					label={chart.title}
					color={chart.color}
					value={10}
				/>
			);
			break;
		default:
			component = <Counter label={chart.title} value={10} />;
			break;
	}
	return component;
};

const CustomDashboard = () => {
	const { id } = useParams();
	const { data: dataDash } = useDashboard(id);
	const dashboard = dataDash?.getDashboard || [];
	return (
		<Grid
			templateColumns={{ sm: '1fr', md: 'repeat(4, 1fr)' }}
			templateRows={{ sm: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)' }}
			gap="12px"
			pt={{ sm: '125px', lg: '160px' }}
		>
			{dashboard?.charts
				?.sort((a, b) => (a.order > b.order ? 1 : -1))
				.map((chart) => (
					<GridItem
						colSpan={
							widths.find((wd) => wd.value == chart.width).colSpan
						}
					>
						<ChartContainer
							title={chart.title}
							subtitle={chart.subtitle}
							tooltip={chart.helper}
						>
							{renderChart(chart)}
						</ChartContainer>
					</GridItem>
				))}
		</Grid>
	);
};

export default CustomDashboard;
