import { useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useCreateReport } from 'hooks/customReports';

import {
	Button,
	Divider,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Grid,
	HStack,
	Input,
	Select,
	Text,
	useColorModeValue,
	useToast
} from '@chakra-ui/react';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import { useState } from 'react';
import { Plus, Trash } from '@phosphor-icons/react';
import { beneficiaryValues , campaignValues } from 'variables/customReports';


const schema = yup.object().shape({
	name: yup.string().required('O campo Nome é obrigatório'),
	columns: yup.array().of(
		yup.object().shape({
			label: yup.string().required('O campo Nome é obrigatório'),
			type: yup.string().required('Escolha o Campo da coluna'),
			value: yup.string().required('O campo Valor é obrigatório'),
			order: yup.string().required('O campo Ordem é obrigatório'),
		})
	)
});

const CreateReport = () => {
	const methods = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		resolver: yupResolver(schema)
	});

	const toast = useToast();
	const history = useHistory();

	const [counter, setCounter] = useState(0);
	const [columns, setColumns] = useState([]);

	const {
		register,
		unregister,
		handleSubmit,
		formState: { errors },
		setError,
		clearErrors,
		reset,
		watch
	} = methods;

	const wColumns = watch('columns');

	const isDisabled = (key) => {
		if (!wColumns) return true;
		if (wColumns[key]?.type) return false;
		return true;
	};

	const renderValueField = (key) => {
		if (!wColumns || !wColumns[key].type) return null;
		const { type } = wColumns[key];

		return type;
	};

	const renderOptions = (key) => {
		if (!wColumns || !wColumns[key].type) return [];
		const { type } = wColumns[key];
		switch (type) {
			case 'form':
				return [];
			case 'metric':
				return [];
			case 'beneficiary':
				return beneficiaryValues;
			case 'campaign':
				return campaignValues;
			default:
				break;
		}
		return [];
	};

	const handleAddColumn = () => {
		clearErrors(`columns_req`);
		const id = counter;
		setColumns([...columns, { id }]);
		setCounter(counter + 1);
	};

	const handleRemoveColumn = (id) => {
		clearErrors();
		setColumns(columns.filter((chart) => chart.id !== id));
		unregister(`columns.${id}`);
		
	};

	const [createReport, { loading }] = useCreateReport({
		onSuccess: (data) => {
			reset();
			toast({
				title: 'Relatório cadastrado com sucesso.',
				status: 'success',
				duration: 5000,
				isClosable: true
			});
			history.push(
				`/sistema/administracao/relatorios-custom/${data.createReport.id}`
			);
		},
		onError: () => {
			toast({
				title: 'Ocorreu um erro',
				status: 'error',
				duration: 5000,
				isClosable: true
			});
		}
	});

	const onSubmit = (data) => {
		if (!data.columns || data.columns.length == 0) {
			setError('columns_req', {
				type: 'custom',
				message: 'Insira pelo menos uma coluna para o relatório'
			});
			return;
		}

		const columns = data.columns.filter(n => n).map((column) => ({
			...column,
			order: Number(column.order),
		}));

		delete data.columns;

		const variables = {
			input: {
				...data,
				client_id: 'de0d6575-9790-4ae2-a800-7973ac31c5b6',
				columns: {
					create: columns
				}
			}
		};

		createReport({ variables });
	};

	const inputHover = useColorModeValue('health.800', 'health.800');
	const textColor = useColorModeValue('gray.700', 'white');

	return (
		<Flex
			direction="column"
			minH="100vh"
			align="center"
			pt={{ sm: '120px', lg: '160px' }}
		>
			<Card w={{ md: '100%', lg: '50%' }}>
				<CardHeader mb="40px">
					<Flex direction="column">
						<Text
							color={textColor}
							fontSize="lg"
							fontWeight="bold"
							mb="3px"
						>
							Cadastrar Relatório Customizado
						</Text>
						<Text
							color="gray.400"
							fontWeight="normal"
							fontSize="sm"
						>
							Preencha os campos para realizar o cadastro
						</Text>
					</Flex>
				</CardHeader>
				<CardBody>
					<FormProvider {...methods}>
						<form
							style={{ width: '100%' }}
							onSubmit={handleSubmit(onSubmit)}
						>
							<Flex direction="column" w="100%">
								<Grid
									templateColumns={{
										sm: '1fr',
										md: 'repeat(2, 1fr)'
									}}
									gap="24px"
									mb="24px"
								>
									<FormControl isInvalid={errors.name}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Nome do relatório
										</FormLabel>
										<Input
											{...register('name')}
											focusBorderColor={inputHover}
											borderRadius="8px"
											fontSize="md"
										/>
										{errors.name && (
											<FormErrorMessage>
												{errors.name.message}
											</FormErrorMessage>
										)}
									</FormControl>
								</Grid>
								<Grid
									templateColumns={{
										sm: '1fr',
										md: 'repeat(1, 1fr)'
									}}
									gap="15px"
									mb="24px"
								>
									{columns.map((column, x) => (
										<>
											<HStack
												key={`${column.id}`}
												alignItems="flex-start"
											>
												<FormControl
													isInvalid={
														errors.columns &&
														errors.columns[x]?.label
													}
												>
													<Input
														placeholder="Nome"
														{...register(
															`columns.${column.id}.label`
														)}
														focusBorderColor={
															inputHover
														}
														borderRadius="8px"
														fontSize="md"
													/>
													{errors.columns &&
														errors.columns[x]
															?.label && (
														<FormErrorMessage>
															{
																errors
																	.columns[
																		x
																	]?.label
																	.message
															}
														</FormErrorMessage>
													)}
												</FormControl>
												<FormControl
													isInvalid={
														errors.columns &&
														errors.columns[x]?.type
													}
												>
													<Select
														placeholder="Selecione um campo"
														{...register(
															`columns.${column.id}.type`
														)}
														borderRadius="8px"
														fontSize="md"
													>
														<option value="form">
															Formulário
														</option>
														<option value="metric">
															Métrica
														</option>
														<option value="beneficiary">
															Vida
														</option>
														<option value="campaign">
															Campanha
														</option>
													</Select>
													{errors.columns &&
														errors.columns[x]
															?.type && (
														<FormErrorMessage>
															{
																errors
																	.columns[
																		x
																	]?.type
																	.message
															}
														</FormErrorMessage>
													)}
												</FormControl>
												{renderValueField(column.id) ==
													'beneficiary' && (
													<FormControl
														isInvalid={
															errors.columns &&
															errors.columns[x]
																?.value
														}
													>
														<Select
															placeholder={
																isDisabled(
																	column.id
																)
																	? 'Selecione um campo'
																	: 'Seleciona um valor'
															}
															disabled={isDisabled(
																column.id
															)}
															{...register(
																`columns.${column.id}.value`
															)}
															borderRadius="8px"
															fontSize="md"
														>
															{renderOptions(
																column.id
															).map(
																(opt, key) => (
																	<option
																		key={
																			key
																		}
																		value={
																			opt.value
																		}
																	>
																		{
																			opt.label
																		}
																	</option>
																)
															)}
														</Select>
														{errors.columns &&
															errors.columns[x]
																?.value && (
															<FormErrorMessage>
																{
																	errors
																		.columns[
																			x
																		]?.value
																		.message
																}
															</FormErrorMessage>
														)}
													</FormControl>
												)}
												{(renderValueField(column.id) ==
													'form' ||
													renderValueField(
														column.id
													) == 'metric') && (
													<FormControl
														isInvalid={
															errors.columns &&
															errors.columns[x]
																?.value
														}
													>
														<Input
															placeholder="Tag do Campo"
															{...register(
																`columns.${column.id}.value`
															)}
															focusBorderColor={
																inputHover
															}
															borderRadius="8px"
															fontSize="md"
														/>
														{errors.columns &&
															errors.columns[x]
																?.value && (
															<FormErrorMessage>
																{
																	errors
																		.columns[
																			x
																		]?.value
																		.message
																}
															</FormErrorMessage>
														)}
													</FormControl>
												)}
												<FormControl
													isInvalid={
														errors.columns &&
														errors.columns[x]?.order
													}
												>
													<Input
														placeholder="Ordem (Posição)"
														{...register(
															`columns.${column.id}.order`
														)}
														focusBorderColor={
															inputHover
														}
														borderRadius="8px"
														fontSize="md"
													/>
													{errors.columns &&
														errors.columns[x]
															?.order && (
														<FormErrorMessage>
															{
																errors
																	.columns[
																		x
																	]?.order
																	.message
															}
														</FormErrorMessage>
													)}
												</FormControl>
												<Button
													colorScheme="orange"
													onClick={() =>
														handleRemoveColumn(
															column.id
														)
													}
													marginTop="auto"
												>
													<Text fontSize="md">
														<Trash size="22" />
													</Text>
												</Button>
											</HStack>
											{x + 1 !== columns.length && (
												<Divider />
											)}
										</>
									))}
									{errors.columns_req && (
										<Flex mb="24px">
											<Text fontSize="sm" color="red.500">
												{errors.columns_req.message}
											</Text>
										</Flex>
									)}
									<Flex mb="24px">
										<Button
											colorScheme="green"
											leftIcon={<Plus size="22" />}
											onClick={() => handleAddColumn()}
										>
											Adicionar coluna
										</Button>
									</Flex>
								</Grid>
								<Flex
									w="full"
									justifyContent="flex-end"
									mt="24px"
								>
									<HStack spacing={4}>
										<Button
											disabled={loading}
											variant="ghost"
											colorScheme="gray"
											alignSelf="flex-end"
											size="md"
											onClick={() => history.goBack()}
										>
											Cancelar
										</Button>
										<Button
											isLoading={loading}
											disabled={loading}
											colorScheme="green"
											type="submit"
											alignSelf="flex-end"
											size="md"
										>
											Cadastrar
										</Button>
									</HStack>
								</Flex>
							</Flex>
						</form>
					</FormProvider>
				</CardBody>
			</Card>
		</Flex>
	);
};

export default CreateReport;
