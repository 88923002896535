import { AuthProvider as Provider } from 'hooks/useAuth';
import NotificationProvider from './notificationProvider';

const AuthProvider = ({ children, user }) => (
	<Provider user={user}>
		<NotificationProvider>{children}</NotificationProvider>
	</Provider>
);

export default AuthProvider;
