import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import InputMask from 'react-input-mask';

import { useBeneficiary, useUpdateBeneficiary } from 'hooks/beneficiaries';
import { useGetRegionals } from 'hooks/regionals';
import { useGetUnits } from 'hooks/units';

import {
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Grid,
	HStack,
	Input,
	Select,
	Skeleton,
	Text,
	useColorModeValue,
	useToast
} from '@chakra-ui/react';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';

import { cnpjRegex } from 'variables/regex';

const schema = yup.object().shape({
	name: yup.string().required('O campo Nome é obrigatório'),
	cnpj: yup.string().nullable().matches(cnpjRegex, 'Insira um CNPJ válido')
});

const EditBeneficiary = () => {
	const { id } = useParams();
	const { data, loading } = useBeneficiary(id);
	const { data: dataR, loading: loadingRegionals } = useGetRegionals();
	const { data: dataU, loading: loadingUnits } = useGetUnits();

	const beneficiary = data?.getBeneficiary || {};
	const regionals = dataR?.listRegionals || [];
	const units = dataU?.listUnits || [];

	const history = useHistory();

	const methods = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		resolver: yupResolver(schema)
	});

	const toast = useToast();

	const {
		register,
		reset,
		handleSubmit,
		formState: { errors },
		setValue,
		watch
	} = methods;

	const wRegional = watch(
		'regional_id',
		beneficiary?.unit?.regional.id || null
	);

	useEffect(() => {
		if (beneficiary && beneficiary.unit)
			setValue('regional_id', beneficiary.unit.regional.id);
	}, [beneficiary]);

	const [updateBeneficiary, { loading: loadingUpdate }] =
		useUpdateBeneficiary({
			onSuccess: (data) => {
				reset();
				toast({
					title: 'Vida atualizada com sucesso.',
					status: 'success',
					duration: 5000,
					isClosable: true
				});
				history.push(
					`/sistema/administracao/gestao-vidas/${data.updateBeneficiary.id}`
				);
			},
			onError: () => {
				toast({
					title: 'Ocorreu um erro',
					status: 'error',
					duration: 5000,
					isClosable: true
				});
			}
		});

	const onSubmit = (data) => {
		data.cpf = data.cpf.replaceAll('-', '').replaceAll('.', '');
		data.phone = data.phone
			.replaceAll('(', '')
			.replaceAll(')', '')
			.replaceAll('-', '')
			.replaceAll(' ', '');

		const variables = {
			input: {
				id,
				...data
			}
		};
		updateBeneficiary({ variables });
	};

	const inputHover = useColorModeValue('health.800', 'health.800');
	const textColor = useColorModeValue('gray.700', 'white');

	return (
		<Flex
			direction="column"
			minH="100vh"
			align="center"
			pt={{ sm: '120px', lg: '160px' }}
		>
			<Card w={{ md: '100%', lg: '50%' }}>
				<CardHeader mb="40px">
					<Flex direction="column">
						<Text
							color={textColor}
							fontSize="lg"
							fontWeight="bold"
							mb="3px"
						>
							Editar Vida
						</Text>
					</Flex>
				</CardHeader>
				<CardBody>
					<FormProvider {...methods}>
						<form
							style={{ width: '100%' }}
							onSubmit={handleSubmit(onSubmit)}
						>
							<Flex direction="column" w="100%">
								<Grid
									templateColumns={{
										sm: '1fr',
										md: 'repeat(2, 1fr)'
									}}
									gap="24px"
								>
									<FormControl isInvalid={errors.name}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Nome
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Input
												{...register('name')}
												defaultValue={beneficiary.name}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
											/>
										)}
										{errors.name && (
											<FormErrorMessage>
												{errors.name.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.cpf}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											CPF
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Input
												as={InputMask}
												mask="999.999.999-99"
												defaultValue={beneficiary.cpf}
												{...register('cpf')}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
											/>
										)}
										{errors.cpf && (
											<FormErrorMessage>
												{errors.cpf.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.phone}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Telefone
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Input
												as={InputMask}
												mask="(99) 99999-9999"
												defaultValue={beneficiary.phone}
												{...register('phone')}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
											/>
										)}
										{errors.phone && (
											<FormErrorMessage>
												{errors.phone.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.email}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											E-mail
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Input
												{...register('email')}
												defaultValue={beneficiary.email}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
											/>
										)}
										{errors.email && (
											<FormErrorMessage>
												{errors.email.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.regional_id}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Regional
										</FormLabel>
										{loading || loadingRegionals ? (
											<Skeleton height="40px" />
										) : (
											<Select
												{...register('regional_id')}
												placeholder="Selecione uma opção..."
												defaultValue={
													beneficiary.unit.regional.id
												}
											>
												{regionals.map((reg) => (
													<option value={reg.id}>
														{reg.name}
													</option>
												))}
											</Select>
										)}
										{errors.regional_id && (
											<FormErrorMessage>
												{errors.regional_id.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.unit_id}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Unidade
										</FormLabel>
										{loading || loadingUnits ? (
											<Skeleton height="40px" />
										) : (
											<Select
												placeholder={
													!wRegional
														? 'Seleione uma regional'
														: 'Selecione uma opção...'
												}
												defaultValue={
													beneficiary.unit.id
												}
												disabled={!wRegional}
												{...register('unit_id')}
											>
												{units
													?.filter(
														(unit) =>
															unit.regional.id ==
															wRegional
													)
													.map((unit) => (
														<option value={unit.id}>
															{unit.name}
														</option>
													))}
											</Select>
										)}
										{errors.unit_id && (
											<FormErrorMessage>
												{errors.unit_id.message}
											</FormErrorMessage>
										)}
									</FormControl>
								</Grid>
								<Flex
									w="full"
									justifyContent="flex-end"
									mt="24px"
								>
									<HStack spacing={4}>
										<Button
											disabled={loadingUpdate}
											variant="ghost"
											colorScheme="gray"
											alignSelf="flex-end"
											size="md"
											onClick={() => history.goBack()}
										>
											Cancelar
										</Button>
										<Button
											isLoading={loadingUpdate}
											disabled={loadingUpdate}
											colorScheme="green"
											type="submit"
											alignSelf="flex-end"
											size="md"
										>
											Atualizar
										</Button>
									</HStack>
								</Flex>
							</Flex>
						</form>
					</FormProvider>
				</CardBody>
			</Card>
		</Flex>
	);
};

export default EditBeneficiary;
