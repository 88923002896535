import { gql } from '@apollo/client';

export const GET_TICKETS = gql`
	query GetTickets {
		listTickets {
			id
			client_id
			user_id
			status
			priority
			error
			description
			created_at
			updated_at
			deleted_at
		}
	}
`;

export const GET_TICKETS_PAGINATE = gql`
	query GET_TICKETS($first: Int! = 30, $page: Int! = 1, $search: String) {
		paginateTickets(first: $first, page: $page, search: $search) {
			paginatorInfo {
				count
				currentPage
				firstItem
				perPage
				lastItem
				total
			}
			data {
				id
				client_id
				user_id
				status
				priority
				error
				description
				created_at
				updated_at
				deleted_at
			}
		}
	}
`;

export const GET_TICKET = gql`
	query GetTicket($id: ID!) {
		getTicket(id: $id) {
			id
			client_id
			user_id
			status
			priority
			error
			description
			created_at
			updated_at
			deleted_at
		}
	}
`;

export const CREATE_TICKET = gql`
	mutation CreateTicket($input: CreateTicket) {
		createTicket(input: $input) {
			id
			client_id
			user_id
			status
			priority
			error
			description
			created_at
			updated_at
			deleted_at
		}
	}
`;

export const UPDATE_TICKET = gql`
	mutation UpdateTicket($input: UpdateTicket) {
		updateTicket(input: $input) {
			id
			client_id
			user_id
			status
			priority
			error
			description
			created_at
			updated_at
			deleted_at
		}
	}
`;
