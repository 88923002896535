/* eslint-disable react/require-default-props */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/named */
/* eslint-disable no-unused-vars */

import { useAuth, useLogout, useLogout_ } from 'hooks/useAuth';

// Chakra Icons
// Chakra Imports
import {
	Button,
	Flex,
	IconButton,
	Input,
	InputGroup,
	InputLeftElement,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Spinner,
	Text,
	useColorModeValue,
	useToast
} from '@chakra-ui/react';
// Custom Icons
// Custom Components
import { SidebarResponsive } from 'components/Sidebar/Sidebar';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import menuItems from 'menu';

import Icon from 'components/FontAwesomeIcons/Icon';
import TimeAgo from 'javascript-time-ago';
import br from 'javascript-time-ago/locale/br.json';
import { Auth } from 'aws-amplify';
import { IoDocumentsSharp } from 'react-icons/io5';
import ReactTimeAgo from 'react-time-ago';
import { JWT_TOKEN_COGNITO, JWT_TOKEN_COGNITO_ID } from 'variables/enumLabels';
import { useNotifications } from 'hooks/notifications';
import { ItemContent } from '../Menu/ItemContent';
import RegionalSelect from './RegionalSelect';

TimeAgo.addDefaultLocale(br);
TimeAgo.addLocale(br);

export default function HeaderLinks(props) {
	const { user, logout } = useAuth();
	const { forms, newForm, reports, newReport, isLoadingReports } =
		useNotifications();
	const { variant, children, fixed, secondary, onOpen, ...rest } = props;

	const toast = useToast();

	const [hasNewNot, setHasNewNot] = React.useState(false);

	useEffect(() => {
		if (newForm.status && newForm.status == 'form')
			toast({
				title: `Formulário salvo com sucesso!`,
				status: 'success',
				duration: 5000,
				isClosable: true
			});
	}, [newForm]);

	useEffect(() => {
		if (newReport.status && newReport.status == 'generated')
			toast({
				title: `Relatório: ${newReport.label} finalizado.`,
				status: 'info',
				duration: 5000,
				isClosable: true
			});
	}, [newReport]);

	// Chakra Color Mode
	const mainhealthlab = useColorModeValue('health.900', 'health.900');
	const inputBg = useColorModeValue('white', 'gray.800');
	let mainText = useColorModeValue('gray.700', 'gray.200');
	let navbarIcon = useColorModeValue('white', 'white');
	const searchIcon = useColorModeValue('gray.700', 'gray.200');

	const handleSignout = () => {
		window.localStorage.removeItem(JWT_TOKEN_COGNITO);
		window.localStorage.removeItem(JWT_TOKEN_COGNITO_ID);
		Auth.signOut({ global: true });
	};

	if (secondary) {
		navbarIcon = 'white';
		mainText = 'white';
	}
	const settingsRef = React.useRef();

	return (
		<Flex
			pe={{ sm: '0px', md: '16px' }}
			w={{ sm: '100%', md: 'auto' }}
			alignItems="center"
		>
			<SidebarResponsive
				logoText={props.logoText}
				secondary={props.secondary}
				routes={menuItems}
				{...rest}
			/>
			{/* <RegionalSelect /> */}
			<Icon
				icon="gear"
				me="16px"
				ref={settingsRef}
				onClick={props.onOpen}
				color={navbarIcon}
				w="20px"
				h="20px"
			/>
			{isLoadingReports ? (
				<Menu>
					<MenuButton me="16px">
						<Spinner
							color={hasNewNot ? 'red.400' : navbarIcon}
							w="18px"
							h="18px"
						/>
					</MenuButton>
				</Menu>
			) : (
				<Menu>
					<MenuButton
						onClick={() => !isLoadingReports && setHasNewNot(false)}
						me="16px"
					>
						<Icon
							icon="task"
							color={hasNewNot ? 'red.400' : navbarIcon}
							w="20px"
							h="20px"
						/>
					</MenuButton>
					<MenuList p="16px 8px">
						<Flex
							flexDirection="column"
							maxH="300px"
							overflowY="auto"
						>
							{/* {reports && reports
									.map((i, index) => (
										i.status == 'generated' && i.url ?
											<MenuItem borderRadius="8px" mb="10px" key={index} onClick={() => window.open(i.url)}>
												<ItemContent
													bg='healthlab.900'
													icon={<IoDocumentsSharp fontSize='1.5rem' />}
													time={<ReactTimeAgo date={i.created_at} locale="br" timeStyle="twitter" />}
													boldInfo={i.label}
												/>
											</MenuItem>
											:
											<MenuItem borderRadius="8px" mb="10px" key={index} >
												<ItemContent
													bg='healthlab.900'
													icon={<Spinner />}
													time={<ReactTimeAgo date={i.created_at} locale="br" timeStyle="twitter" />}
													boldInfo={i.label}
												/>
											</MenuItem>
									))
								} */}
						</Flex>
					</MenuList>
				</Menu>
			)}
			<Menu>
				<MenuButton onClick={() => setHasNewNot(false)} me="16px">
					<Icon
						icon=""
						color={hasNewNot ? 'red.400' : navbarIcon}
						w="20px"
						h="20px"
					/>
				</MenuButton>
			</Menu>
			<NavLink to="/sistema/perfil">
				<Button
					ms="0px"
					px="0px"
					color={navbarIcon}
					variant="transparent-with-icon"
					leftIcon={
						<Icon
							icon="user"
							color={navbarIcon}
							w="24px"
							h="24px"
							me="0px"
						/>
					}
				>
					<Text
						fontWeight="bold"
						color={navbarIcon}
						cursor="pointer"
						mr="16px"
						display={{ sm: 'none', md: 'flex' }}
					>
						{user?.attributes?.name}
					</Text>
				</Button>
			</NavLink>
			<Text
				fontWeight="bold"
				color={navbarIcon}
				cursor="pointer"
				mr="16px"
				display={{ sm: 'none', md: 'flex' }}
				onClick={handleSignout}
			>
				<Icon icon="logout" margin="8" w="24px" h="24px" />
				Sair
			</Text>
		</Flex>
	);
}

HeaderLinks.propTypes = {
	variant: PropTypes.string,
	fixed: PropTypes.bool,
	secondary: PropTypes.bool,
	onOpen: PropTypes.func
};
