import { useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import InputMask from 'react-input-mask';

import { useCreateRegional } from 'hooks/regionals';

import {
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Grid,
	HStack,
	Input,
	Select,
	Skeleton,
	Text,
	useColorModeValue,
	useToast
} from '@chakra-ui/react';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';

import { cnpjRegex } from 'variables/regex';
import { useGetClients } from 'hooks/clients';

const schema = yup.object().shape({
	name: yup.string().required('O campo Nome é obrigatório'),
	cnpj: yup.string().nullable().matches(cnpjRegex, 'Insira um CNPJ válido'),
	client_id: yup.string().required('O campo Cliente é obrigatório')
});

const CreateRegional = () => {
	const {
		data: { listClients: clients = [] } = [],
		loading: loadingClients
	} = useGetClients();

	const methods = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		resolver: yupResolver(schema)
	});
	const toast = useToast();
	const history = useHistory();

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset
	} = methods;

	const [createRegional, { loading }] = useCreateRegional({
		onSuccess: (data) => {
			reset();
			toast({
				title: 'Regional cadastrada com sucesso.',
				status: 'success',
				duration: 5000,
				isClosable: true
			});
			history.push(
				`/sistema/administracao/regionais/${data.createRegional.id}`
			);
		},
		onError: () => {
			toast({
				title: 'Ocorreu um erro',
				status: 'error',
				duration: 5000,
				isClosable: true
			});
		}
	});

	const onSubmit = (data) => {
		const variables = {
			input: { ...data }
		};
		createRegional({ variables });
	};

	const inputHover = useColorModeValue('health.800', 'health.800');
	const textColor = useColorModeValue('gray.700', 'white');

	return (
		<Flex
			direction="column"
			minH="100vh"
			align="center"
			pt={{ sm: '120px', lg: '160px' }}
		>
			<Card w={{ md: '100%', lg: '50%' }}>
				<CardHeader mb="40px">
					<Flex direction="column">
						<Text
							color={textColor}
							fontSize="lg"
							fontWeight="bold"
							mb="3px"
						>
							Cadastrar Regional
						</Text>
						<Text
							color="gray.400"
							fontWeight="normal"
							fontSize="sm"
						>
							Preencha os campos para realizar o cadastro
						</Text>
					</Flex>
				</CardHeader>
				<CardBody>
					<FormProvider {...methods}>
						<form
							style={{ width: '100%' }}
							onSubmit={handleSubmit(onSubmit)}
						>
							<Flex direction="column" w="100%">
								<Grid
									templateColumns={{
										sm: '1fr',
										md: 'repeat(2, 1fr)'
									}}
									gap="24px"
								>
									<FormControl isInvalid={errors.name}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Nome
										</FormLabel>
										<Input
											{...register('name')}
											focusBorderColor={inputHover}
											borderRadius="8px"
											fontSize="md"
										/>
										{errors.name && (
											<FormErrorMessage>
												{errors.name.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.phone}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Telefone
										</FormLabel>
										<Input
											as={InputMask}
											mask="(99) 99999-9999"
											{...register('phone')}
											focusBorderColor={inputHover}
											borderRadius="8px"
											fontSize="md"
										/>
										{errors.phone && (
											<FormErrorMessage>
												{errors.phone.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.cnpj}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											CNPJ
										</FormLabel>
										<Input
											{...register('cnpj')}
											as={InputMask}
											mask="99.999.999/9999-99"
											focusBorderColor={inputHover}
											borderRadius="8px"
											fontSize="md"
										/>
										{errors.cnpj && (
											<FormErrorMessage>
												{errors.cnpj.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.client_id}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Cliente
										</FormLabel>
										<Skeleton
											height="40px"
											isLoaded={!loadingClients}
										>
											<Select
												placeholder="Selecione uma opção..."
												{...register('client_id')}
											>
												{clients &&
													clients.map((client) => (
														<option
															value={client.id}
														>
															{client.name}
														</option>
													))}
											</Select>
										</Skeleton>
										{errors.client_id && (
											<FormErrorMessage>
												{errors.client_id.message}
											</FormErrorMessage>
										)}
									</FormControl>
								</Grid>
								<Flex
									w="full"
									justifyContent="flex-end"
									mt="24px"
								>
									<HStack spacing={4}>
										<Button
											disabled={loading}
											variant="ghost"
											colorScheme="gray"
											alignSelf="flex-end"
											size="md"
											onClick={() => history.goBack()}
										>
											Cancelar
										</Button>
										<Button
											isLoading={loading}
											disabled={loading}
											colorScheme="green"
											type="submit"
											alignSelf="flex-end"
											size="md"
										>
											Cadastrar
										</Button>
									</HStack>
								</Flex>
							</Flex>
						</form>
					</FormProvider>
				</CardBody>
			</Card>
		</Flex>
	);
};

export default CreateRegional;
