import { createContext, useContext, useReducer } from 'react';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import {
	CREATE_TICKET,
	GET_TICKET,
	GET_TICKETS_PAGINATE,
	UPDATE_TICKET
} from 'graphql/tickets';
import { ACTION_TYPES, reducers } from './reducers';

const TicketsStateContext = createContext();
const TicketsDispatchContext = createContext();

const mockTicket = {
	id: 115,
	name: 'Perfil Saúde',
	builder_tag: 'perfil_saude',
	risks: [
		{
			id: 111,
			name: 'Risco Cardiovascular',
			tag: 'risco_cardiovascular',
			value: 'Risco Baixo',
			color: '#68bc00'
		},
		{
			id: 222,
			name: 'Risco Cardiovascular',
			tag: 'risco_cardiovascular',
			value: 'Risco Médio',
			color: '#fcc400'
		},
		{
			id: 333,
			name: 'Risco Cardiovascular',
			tag: 'risco_cardiovascular',
			value: 'Risco Alto',
			color: '#d33115'
		}
	],
	devolutive_tag: 'Devolutiva perfil',
	devolutive_type: 'devolutiva_perfil',
	created_at: new Date()
};

const initialState = {
	rows: [mockTicket],
	page: 1,
	perPage: 15
};

const TicketsProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducers, initialState);

	return (
		<TicketsStateContext.Provider value={{ ...state }}>
			<TicketsDispatchContext.Provider value={dispatch}>
				{children}
			</TicketsDispatchContext.Provider>
		</TicketsStateContext.Provider>
	);
};

const useTickets = () => {
	const context = useContext(TicketsStateContext);

	if (!context)
		throw new Error('useTickets must be used within an TicketsProvider');

	return context;
};

const useDispatch = () => {
	const dispatch = useContext(TicketsDispatchContext);

	if (dispatch === undefined)
		throw new Error('useDispatch must be used within a TicketsProvider');

	return dispatch;
};

const usePaginateTickets = () => {
	const dispatch = useDispatch();

	return useLazyQuery(GET_TICKETS_PAGINATE, {
		onCompleted: (data) => {
			window.history.replaceState(
				null,
				'',
				`./gestao-vidas?page=${data.paginateTickets.paginatorInfo.currentPage}&size=${data.paginateTickets.paginatorInfo.perPage}`
			);
			dispatch({ type: ACTION_TYPES.LIST, data: data.paginateTickets });
		}
	});
};

const useCreateTicket = (options = {}) => {
	const dispatch = useDispatch();
	const { onSuccess, onError } = options;

	return useMutation(CREATE_TICKET, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data);
			dispatch({ type: ACTION_TYPES.ADD, data: data.createTicket });
		},
		onError: (error) => {
			if (onError) onError(error);
		}
	});
};

const useTicket = (id) => {
	const dispatch = useContext(TicketsDispatchContext);

	return useQuery(
		GET_TICKET,
		{
			fetchPolicy: 'no-cache',
			variables: {
				id
			}
		},
		{
			onCompleted: (data) => {
				dispatch({ type: ACTION_TYPES.VIEW, data: data.ticket });
			}
		}
	);
};

const useUpdateTicket = (options = {}) => {
	const dispatch = useContext(TicketsDispatchContext);
	const { onSuccess, onError } = options;

	return useMutation(UPDATE_TICKET, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data);
			dispatch({ type: ACTION_TYPES.UPDATE, data: data.updateTicket });
		},
		onError: (error) => {
			if (onError) onError(error);
		},
		refetchQueries: [
			{
				query: GET_TICKETS_PAGINATE
			}
		]
	});
};

// const useDeleteTicket = (id, options = {}) => {
// 	const dispatch = useDispatch()
// 	const { onSuccess } = options

// 	return useMutation(() => DELETE_TICKET(id), {
// 			...options,
// 			onSuccess: () => {
// 				onSuccess()
// 				dispatch({ type: ACTION_TYPES.REMOVE, id })
// 			},
// 	})
// }

export {
	TicketsProvider,
	useTickets,
	useCreateTicket,
	usePaginateTickets,
	useTicket,
	useUpdateTicket
	// useDeleteTicket
};
