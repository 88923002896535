import { Link, useHistory, useParams } from 'react-router-dom';

import { useTicket } from 'hooks/tickets';

import dateFormat from 'functions/dateFormat';

import {
	Button,
	Flex,
	FormControl,
	FormLabel,
	Grid,
	HStack,
	Skeleton,
	Text,
	useColorModeValue
} from '@chakra-ui/react';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';

const ViewTicket = () => {
	const { id } = useParams();
	const { data, loading } = useTicket(id);

	const history = useHistory();

	const textColor = useColorModeValue('gray.700', 'white');

	return (
		<Flex
			direction="column"
			minH="100vh"
			align="center"
			pt={{ sm: '120px', lg: '160px' }}
		>
			<Card w={{ md: '100%', lg: '50%' }}>
				<CardHeader mb="40px">
					<Flex direction="row" w="100%" justify="space-between">
						<Text
							color={textColor}
							fontSize="lg"
							fontWeight="bold"
							mb="3px"
						>
							Suporte
						</Text>
						<Flex>
							<HStack spacing={4} alignItems="flex-start">
								<Link to={`${id}/editar`}>
									<Button colorScheme="green" size="md">
										Editar
									</Button>
								</Link>
								<Button
									variant="ghost"
									colorScheme="gray"
									size="md"
									onClick={() => history.goBack()}
								>
									Voltar
								</Button>
							</HStack>
						</Flex>
					</Flex>
				</CardHeader>
				<CardBody>
					<Flex direction="column" w="100%">
						<Grid
							templateColumns={{
								sm: '1fr',
								md: 'repeat(2, 1fr)'
							}}
							gap="24px"
							mb="24px"
						>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Status
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text fontSize="lg" fontWeight="bold">
										{data?.getTicket?.status === 'open' &&
											'Aberto'}
										{data?.getTicket?.status ===
											'working' && 'Em andamento'}
										{data?.getTicket?.status === 'closed' &&
											'Finalizado'}
									</Text>
								</Skeleton>
							</FormControl>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Prioridade
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text fontSize="lg" fontWeight="bold">
										{data?.getTicket?.priority === 'low' &&
											'Baixa'}
										{data?.getTicket?.priority ===
											'normal' && 'Normal'}
										{data?.getTicket?.priority === 'high' &&
											'Alta'}
										{data?.getTicket?.priority ===
											'urgent' && 'Urgente'}
									</Text>
								</Skeleton>
							</FormControl>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Onde encontrou o erro?
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text fontSize="lg" fontWeight="bold">
										{data?.getTicket?.error}
									</Text>
								</Skeleton>
							</FormControl>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									O que o erro causa?
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text fontSize="lg" fontWeight="bold">
										{data?.getTicket?.description}
									</Text>
								</Skeleton>
							</FormControl>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Aberto Em
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text fontSize="lg" fontWeight="bold">
										{dateFormat(
											data?.getTicket?.created_at
										)}
									</Text>
								</Skeleton>
							</FormControl>
						</Grid>
					</Flex>
				</CardBody>
			</Card>
		</Flex>
	);
};

export default ViewTicket;
