import { useHistory, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import InputMask from 'react-input-mask';

import { useUpdateUser, useUser, useUsers } from 'hooks/users';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Grid,
	HStack,
	Input,
	Select,
	Skeleton,
	Switch,
	Text,
	useColorModeValue,
	useToast
} from '@chakra-ui/react';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';

import { cpfRegex, phoneRegex } from 'variables/regex';
import { useState } from 'react';
import { useGetClients } from 'hooks/clients';

import { GET_ROLES } from '../../graphql/extras';

import {
	ROLE_ADMIN_REGIONAL,
	ROLE_SUPER_ADMIN
} from '../../variables/constants';

import { GET_REGIONALS_ALL } from '../../graphql/regionals';

const schema = yup.object().shape({
	name: yup.string().required('O campo Nome é obrigatório'),
	cpf: yup
		.string()
		.required('O campo CPF é obrigatório')
		.matches(cpfRegex, 'Insira um CPF válido'),
	email: yup
		.string()
		.email('Insira um e-mail válido')
		.required('O campo E-mail é obrigatório'),
	phone: yup
		.string()
		.required('O campo Telefone Principal é obrigatório')
		.matches(phoneRegex, 'Insira um telefone válido'),
	roles: yup.string(),
	client_id: yup.string().when('roles', {
		is: (role) => role !== ROLE_SUPER_ADMIN,
		then: yup.string().required('O campo Cliente é obrigatório')
	}),
	regional_id: yup.string()
	// 	.when('roles.connect', {
	// 	is: (connect) => connect !== ROLE_OPERADOR_REGIONAL,
	// 	then: yup.string().required('O campo Regional é obrigatório')
	// })
});

const EditUser = () => {
	const { id } = useParams();
	const { user } = useUsers();
	const { loading } = useUser(id);
	const { data, loading: loadingRoles } = useQuery(GET_ROLES);
	const { data: { listClients: clients = [] } = [] } = useGetClients();

	const [loadRegionals, { data: dataRegionals }] =
		useLazyQuery(GET_REGIONALS_ALL);
	const history = useHistory();

	const methods = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		resolver: yupResolver(schema),
		defaultValues: user
	});
	const toast = useToast();

	const {
		register,
		reset,
		handleSubmit,
		formState: { errors }
	} = methods;

	const [roleType, setRoleType] = useState(null);

	const [updateUser, { loading: loadingUpdate }] = useUpdateUser({
		onSuccess: (data) => {
			reset();
			toast({
				title: 'Usuário atualizado com sucesso.',
				status: 'success',
				duration: 5000,
				isClosable: true
			});
			history.push(
				`/sistema/administracao/usuarios/${data.updateUser.id}`
			);
		},
		onError: () => {
			toast({
				title: 'Ocorreu um erro',
				status: 'error',
				duration: 5000,
				isClosable: true
			});
		}
	});

	const onSubmit = (data) => {
		updateUser({
			variables: {
				input: {
					id,
					...data
				}
			}
		});
	};
	const getRegionals = (id) => {
		loadRegionals({
			variables: {
				id
			}
		});
	};
	const inputHover = useColorModeValue('health.800', 'health.800');
	const textColor = useColorModeValue('gray.700', 'white');

	return loading && !user?.id && loadingRoles && dataRegionals ? (
		'Carregando'
	) : (
		<Flex
			direction="column"
			minH="100vh"
			align="center"
			pt={{ sm: '120px', lg: '160px' }}
		>
			<Card w={{ md: '100%', lg: '50%' }}>
				<CardHeader mb="40px">
					<Flex direction="column">
						<Text
							color={textColor}
							fontSize="lg"
							fontWeight="bold"
							mb="3px"
						>
							Editar Usuário
						</Text>
					</Flex>
				</CardHeader>
				<CardBody>
					<FormProvider {...methods}>
						<form
							style={{ width: '100%' }}
							onSubmit={handleSubmit(onSubmit)}
						>
							<Flex direction="column" w="100%">
								<Grid
									templateColumns={{
										sm: '1fr',
										md: 'repeat(1, 1fr)'
									}}
									gap="24px"
								>
									<FormControl>
										<HStack alignItems="center" mb="24px">
											<FormLabel
												color={textColor}
												fontWeight="bold"
												fontSize="xs"
												my="auto"
											>
												Ativo
											</FormLabel>
											<Switch colorScheme="green" />
										</HStack>
									</FormControl>
								</Grid>
								<Grid
									templateColumns={{
										sm: '1fr',
										md: 'repeat(2, 1fr)'
									}}
									gap="24px"
								>
									<FormControl isInvalid={errors.name}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Nome
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Input
												{...register('name')}
												defaultValue={user.name || null}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
											/>
										)}
										{errors?.name && (
											<FormErrorMessage>
												{errors?.name?.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.email}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											E-mail
										</FormLabel>

										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Input
												{...register('email')}
												defaultValue={
													user.email || null
												}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
											/>
										)}
										{errors.email && (
											<FormErrorMessage>
												{errors.email.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl
										isInvalid={errors.phone || null}
									>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Celular
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Input
												as={InputMask}
												mask="(99) 99999-9999"
												{...register('phone')}
												defaultValue={
													user.phone || null
												}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
											/>
										)}
										{errors.phone && (
											<FormErrorMessage>
												{errors.phone.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.type}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Tipo de Perfil
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Select
												{...register('roles')}
												defaultValue={user?.roles
													?.map((r) => r.name)
													.join()}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
												placeholder="Selecione uma opção..."
												onChange={({ target }) =>
													setRoleType(target.value)
												}
											>
												{data?.roles?.map((r) => (
													<option
														value={r.name}
														selected={user?.roles
															?.map((r) => r.name)
															.includes(r.name)}
													>
														{r.name}
													</option>
												))}
											</Select>
										)}
										{errors.type && (
											<FormErrorMessage>
												{errors.type.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.cpf}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											CPF
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Input
												{...register('cpf')}
												as={InputMask}
												mask="999.999.999-99"
												defaultValue={user?.cpf}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
											/>
										)}
										{errors.cpf && (
											<FormErrorMessage>
												{errors.cpf.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.client_id}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Cliente
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Select
												{...register('client_id')}
												placeholder="Selecione uma opção..."
												onChange={({ target }) =>
													getRegionals(target.value)
												}
											>
												{clients?.map((r) => (
													<option
														value={r.id}
														selected={
															user?.client_id ===
															r.id
														}
													>
														{r.name}
													</option>
												))}
											</Select>
										)}
										{errors.client_id && (
											<FormErrorMessage>
												{errors.client_id.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl
										isInvalid={errors.regional_id}
										hidden={
											roleType !== ROLE_ADMIN_REGIONAL
										}
									>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Regional
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Select
												{...register('regional_id')}
												placeholder="Selecione uma opção..."
											>
												{dataRegionals?.regionals?.map(
													(r) => (
														<option value={r.id}>
															{r.name}
														</option>
													)
												)}
											</Select>
										)}
										{errors.regional_id && (
											<FormErrorMessage>
												{errors.regional_id.message}
											</FormErrorMessage>
										)}
									</FormControl>
								</Grid>
								<Flex
									w="full"
									justifyContent="flex-end"
									mt="24px"
								>
									<HStack spacing={4}>
										<Button
											disabled={loadingUpdate}
											variant="ghost"
											colorScheme="gray"
											alignSelf="flex-end"
											size="md"
											onClick={() => history.goBack()}
										>
											Cancelar
										</Button>
										<Button
											isLoading={loadingUpdate}
											disabled={loadingUpdate}
											colorScheme="green"
											type="submit"
											alignSelf="flex-end"
											size="md"
										>
											Atualizar
										</Button>
									</HStack>
								</Flex>
							</Flex>
						</form>
					</FormProvider>
				</CardBody>
			</Card>
		</Flex>
	);
};

export default EditUser;
