import { createContext, useContext, useReducer } from 'react';

import Pusher from 'pusher-js';

import { GET_REPORTS } from 'services/reports';
import { useQuery } from 'react-query';

import { JWT_TOKEN_COGNITO_ID } from 'variables/enumLabels';
import { useAuth } from 'hooks/useAuth';
import { ACTION_TYPES, reducers } from './reducers';

const NotificationsStateContext = createContext();
const NotificationsDispatchContext = createContext();

const openPusherSocket = (dispatch, user, reportEvent, formEvent) => {
	if (window.pusher) return false;

	window.pusher = {};
	const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
		cluster: process.env.REACT_APP_PUSHER_CLUSTER,
		authEndpoint: process.env.REACT_APP_SOCKET_URL,
		encrypted: true,
		auth: {
			headers: {
				Authorization:
					`Bearer ${window.localStorage.getItem(
						JWT_TOKEN_COGNITO_ID
					)}` || ''
			}
		}
	});

	pusher
		.subscribe(`private-receiver.${user.id}`)
		.bind(
			'Notrelife\\CommunicationChannel\\Triggers\\BroadcastDispatcher',
			(data) => {
				if (data.status == 'generated') {
					dispatch({ type: ACTION_TYPES.UPDATE_REPORT, data });
					document.dispatchEvent(reportEvent);
				}
				if (data.status == 'form') {
					dispatch({ type: ACTION_TYPES.UPDATE_FORM, data });
					document.dispatchEvent(formEvent);
				}
			}
		);

	window.pusher = pusher;
	window.listenNotifications = true;

	return null;
};

const NotificationsProvider = ({ children }) => {
	const { user } = useAuth();
	const [state, dispatch] = useReducer(reducers, {
		notifications: [],
		newForm: {},
		forms: [],
		reports: [],
		newReport: {}
	});

	const { isLoading: isLoadingReports } = useQuery(
		['reports'],
		() => GET_REPORTS(),
		{
			onSuccess: (data) => {
				dispatch({ type: ACTION_TYPES.LIST_REPORTS, data });
			}
		}
	);

	const newReportNotification = new CustomEvent('report-notification', {
		status: null
	});
	const formFinishedNotification = new CustomEvent('form-finished', {
		status: null
	});

	if (process.env.REACT_APP_PUSHER_KEY && user.id)
		openPusherSocket(
			dispatch,
			user,
			newReportNotification,
			formFinishedNotification
		);

	return (
		<NotificationsStateContext.Provider
			value={{ isLoadingReports, ...state }}
		>
			<NotificationsDispatchContext.Provider value={dispatch}>
				{children}
			</NotificationsDispatchContext.Provider>
		</NotificationsStateContext.Provider>
	);
};

const useNotifications = () => {
	const context = useContext(NotificationsStateContext);

	if (!context)
		throw new Error(
			'useNotifications must be used within an NotificationsProvider'
		);

	return context;
};

const useDispatch = () => {
	const dispatch = useContext(NotificationsDispatchContext);

	if (dispatch === undefined)
		throw new Error(
			'useDispatch must be used within a NotificationsProvider'
		);

	return dispatch;
};

const useNewReport = (data) => {
	const dispatch = useContext(NotificationsDispatchContext);

	dispatch({ type: ACTION_TYPES.ADD_REPORT, data });
};

const useReadNotification = () => {};

export {
	NotificationsProvider,
	useNotifications,
	useNewReport,
	useReadNotification,
	useDispatch
};
