const status = [
	{
		label: 'Cadastro Incompleto',
		value: 'pending_register'
	},
	{
		label: 'Pendente Questionário de Saúde',
		value: 'pending_assessment'
	},
	{
		label: 'Perfil Saúde Realizado',
		value: 'assessment_done'
	}
];

export { status };
