import { NotificationsProvider as Provider } from 'hooks/notifications';
import { QueryProvider } from './queryClient';

const NotificationProvider = ({ children }) => (
	<QueryProvider>
		<Provider>{children}</Provider>
	</QueryProvider>
);

export default NotificationProvider;
