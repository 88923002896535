import ReactDOM from 'react-dom';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';

import AdminLayout from 'layouts/Admin';

import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import AppAuthProvider from './providers/appAuthProvider';

ReactDOM.render(
	<BrowserRouter>
		<Switch>
			<AppAuthProvider>
				<PrivateRoute path="/sistema" component={AdminLayout} />
			</AppAuthProvider>
			<Redirect from="/" to="/sistema/dashboard" />
		</Switch>
	</BrowserRouter>,
	document.getElementById('root')
);
