import { TicketsProvider as Provider } from 'hooks/tickets/index';
import { ManagerQueryProvider } from 'services/manager';

const TicketsProvider = ({ children }) => (
	<ManagerQueryProvider>
		<Provider>{children}</Provider>
	</ManagerQueryProvider>
);

export default TicketsProvider;
