import { gql } from '@apollo/client';

export const GET_REPORTS = gql`
	query GetReports {
		listReports {
			id
			name
			created_at
		}
	}
`;

export const GET_REPORTS_PAGINATE = gql`
	query PaginateReports(
		$first: Int! = 30
		$page: Int! = 1
		$where: QueryPaginateReportsWhereWhereConditions
	) {
		paginateReports(first: $first, page: $page, where: $where) {
			paginatorInfo {
				count
				currentPage
				firstItem
				perPage
				lastItem
				total
			}
			data {
				id
				name
				columns {
					id
				}
				created_at
			}
		}
	}
`;

export const GET_REPORT = gql`
	query GetReport($id: ID!) {
		getReport(id: $id) {
			id
			name
			columns {
				id
				label
				type
				value
				order
			}
			created_at
		}
	}
`;
export const GET_REPORTS_ALL = gql`
	query GetReports($id: ID!) {
		reports(client_id: $id) {
			id
			name
			columns {
				id
			}
			created_at
		}
	}
`;
export const CREATE_REPORT = gql`
	mutation CreateReport($input: CreateReport) {
		createReport(input: $input) {
			id
			name
		}
	}
`;

export const UPDATE_REPORT = gql`
	mutation UpdateReport($input: UpdateReport) {
		updateReport(input: $input) {
			id
			name
		}
	}
`;

export const DELETE_REPORT = gql`
	mutation DeleteReport($where: MutationDeleteReportWhereWhereConditions) {
		deleteReport(where: $where) {
			id
		}
	}
`;
