import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { CompactPicker } from 'react-color';

import { useCreateForm } from 'hooks/forms';

import {
	Box,
	Button,
	Divider,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Grid,
	HStack,
	Input,
	InputGroup,
	InputLeftElement,
	Select,
	Text,
	useColorModeValue,
	useToast
} from '@chakra-ui/react';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import Icon from 'components/FontAwesomeIcons/Icon';

const schema = yup.object().shape({
	name: yup.string().required('O campo Nome é obrigatório'),
	tag_builder: yup.string().required('O campo Tag do Builder é obrigatório'),
	devolutive_type: yup.string().when('devolutive_tag', {
		is: (devolutiveTag) => devolutiveTag != null && devolutiveTag !== '',
		then: yup
			.string()
			.required('O campo é obrigatório quando é informado uma devolutiva')
	})
});

const CreateForm = () => {
	const methods = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		resolver: yupResolver(schema)
	});
	const toast = useToast();
	const history = useHistory();
	let counter = 0;

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		getValues,
		setValue,
		unregister
	} = methods;

	const [risks, setRisks] = useState([]);
	const [colorPicker, setColorPicker] = useState(null);
	const [currentColor, setCurrentColor] = useState(null);

	const handleAddRisk = () => {
		const id = counter++;
		setRisks([...risks, { id }]);
	};

	const handleRiskRemove = (id) => {
		setRisks(risks.filter((risk) => risk.id !== id));
		unregister(`risks.${id}`);
	};

	const handleColorChange = (id, value) => {
		if (value) {
			const { hex } = value;
			setValue(`risks.${id}.color`, hex);
			setColorPicker(null);
		}
	};

	const [createForm, { loading }] = useCreateForm({
		onSuccess: (data) => {
			reset();
			toast({
				title: 'Formulário do Cliente cadastrado com sucesso.',
				status: 'success',
				duration: 5000,
				isClosable: true
			});
			history.push(
				`/sistema/administracao/formularios/${data.createFormConfig.id}`
			);
		},
		onError: () => {
			toast({
				title: 'Ocorreu um erro',
				status: 'error',
				duration: 5000,
				isClosable: true
			});
		}
	});

	const onSubmit = (data) => {
		const variables = {
			input: {
				name: data.name,
				tag_builder: data.tag_builder,
				data_track: data.risks,
				devolutive: {
					tag_builder: data.devolutive_tag,
					type: data.devolutive_type
				}
			}
		};

		createForm({ variables });
	};

	const inputHover = useColorModeValue('health.800', 'health.800');
	const textColor = useColorModeValue('gray.700', 'white');

	return (
		<Flex
			direction="column"
			minH="100vh"
			align="center"
			pt={{ sm: '120px', lg: '160px' }}
		>
			<Card w={{ md: '100%', lg: '50%' }}>
				<CardHeader mb="40px">
					<Flex direction="column">
						<Text
							color={textColor}
							fontSize="lg"
							fontWeight="bold"
							mb="3px"
						>
							Cadastrar Formulário
						</Text>
						<Text
							color="gray.400"
							fontWeight="normal"
							fontSize="sm"
						>
							Preencha os campos para realizar o cadastro
						</Text>
					</Flex>
				</CardHeader>
				<CardBody>
					<FormProvider {...methods}>
						<form
							style={{ width: '100%' }}
							onSubmit={handleSubmit(onSubmit)}
						>
							<Flex direction="column" w="100%">
								<Grid
									templateColumns={{
										sm: '1fr',
										md: 'repeat(2, 1fr)'
									}}
									gap="24px"
									mb="24px"
								>
									<FormControl isInvalid={errors.name}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Nome do Formulário
										</FormLabel>
										<Input
											{...register('name')}
											focusBorderColor={inputHover}
											borderRadius="8px"
											fontSize="md"
										/>
										{errors.name && (
											<FormErrorMessage>
												{errors.name.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.tag_builder}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Tag do Builder
										</FormLabel>
										<Input
											{...register('tag_builder')}
											focusBorderColor={inputHover}
											borderRadius="8px"
											fontSize="md"
										/>
										{errors.tag_builder && (
											<FormErrorMessage>
												{errors.tag_builder.message}
											</FormErrorMessage>
										)}
									</FormControl>
								</Grid>
								<Grid
									templateColumns={{
										sm: '1fr',
										md: 'repeat(1, 1fr)'
									}}
									gap="15px"
									mb="24px"
								>
									{risks.map((risk, x) => (
										<>
											<HStack key={risk.id}>
												<FormControl>
													<Input
														placeholder="Nome"
														{...register(
															`risks.${risk.id}.label`
														)}
														focusBorderColor={
															inputHover
														}
														borderRadius="8px"
														fontSize="md"
													/>
												</FormControl>
												<FormControl>
													<Input
														placeholder="Tag do Builder"
														{...register(
															`risks.${risk.id}.tag_builder`
														)}
														focusBorderColor={
															inputHover
														}
														borderRadius="8px"
														fontSize="md"
													/>
												</FormControl>
												<FormControl>
													<Input
														placeholder="Filtro"
														{...register(
															`risks.${risk.id}.type`
														)}
														focusBorderColor={
															inputHover
														}
														borderRadius="8px"
														fontSize="md"
													/>
												</FormControl>
												<FormControl>
													<Box position="relative">
														<InputGroup>
															<InputLeftElement pointerEvents="none">
																<Box
																	w="20px"
																	h="20px"
																	backgroundColor={
																		getValues(
																			`risks.${risk.id}.color`
																		) ||
																		'#ffffff'
																	}
																/>
															</InputLeftElement>
															<Input
																placeholder="Cor"
																{...register(
																	`risks.${risk.id}.color`
																)}
																defaultValue="#ffffff"
																focusBorderColor={
																	inputHover
																}
																borderRadius="8px"
																fontSize="md"
																onFocus={() => {
																	setCurrentColor(
																		getValues(
																			`risks.${risk.id}.color`
																		) ||
																			'#ffffff'
																	);
																	setColorPicker(
																		risk.id
																	);
																}}
															/>
															<Box
																position="absolute"
																right="0"
																top="40px"
																zIndex="99"
																hidden={
																	colorPicker !==
																	risk.id
																}
															>
																<CompactPicker
																	color={
																		currentColor ||
																		'#ffffff'
																	}
																	onChange={(
																		value
																	) =>
																		handleColorChange(
																			risk.id,
																			value
																		)
																	}
																/>
															</Box>
														</InputGroup>
													</Box>
												</FormControl>
												<Button
													colorScheme="orange"
													onClick={() =>
														handleRiskRemove(
															risk.id
														)
													}
													marginTop="auto"
												>
													<Text fontSize="md">
														<Icon icon="delete" />
													</Text>
												</Button>
											</HStack>
											{x + 1 !== risks.length && (
												<Divider />
											)}
										</>
									))}
								</Grid>
								<Flex mb="24px">
									<Button
										colorScheme="green"
										leftIcon={<Icon icon="plus" />}
										onClick={() => handleAddRisk()}
									>
										Adicionar métrica
									</Button>
								</Flex>
								<Grid
									templateColumns={{
										sm: '1fr',
										md: 'repeat(2, 1fr)'
									}}
									gap="24px"
									mb="24px"
								>
									<FormControl
										isInvalid={errors.devolutive_tag}
									>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Tag da Devolutiva
										</FormLabel>
										<Input
											{...register('devolutive_tag')}
											focusBorderColor={inputHover}
											borderRadius="8px"
											fontSize="md"
										/>
										{errors.devolutive_tag && (
											<FormErrorMessage>
												{errors.devolutive_tag.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl
										isInvalid={errors.devolutive_type}
									>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Opções de retorno
										</FormLabel>
										<Select
											placeholder="Selecione uma opção..."
											{...register('devolutive_type')}
										>
											<option value={1}>
												Whatsapp com link da tela
											</option>
											<option value={2}>
												E-mail com PDF
											</option>
										</Select>
										{errors.devolutive_type && (
											<FormErrorMessage>
												{errors.devolutive_type.message}
											</FormErrorMessage>
										)}
									</FormControl>
								</Grid>
								<Flex
									w="full"
									justifyContent="flex-end"
									mt="24px"
								>
									<HStack spacing={4}>
										<Button
											disabled={loading}
											variant="ghost"
											colorScheme="gray"
											alignSelf="flex-end"
											size="md"
											onClick={() => history.goBack()}
										>
											Cancelar
										</Button>
										<Button
											isLoading={loading}
											disabled={loading}
											colorScheme="green"
											type="submit"
											alignSelf="flex-end"
											size="md"
										>
											Cadastrar
										</Button>
									</HStack>
								</Flex>
							</Flex>
						</form>
					</FormProvider>
				</CardBody>
			</Card>
		</Flex>
	);
};

export default CreateForm;
