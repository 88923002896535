import { Text, VStack } from '@chakra-ui/react';

const Notes = () => (
	<VStack
		w="full"
		spacing={4}
		justifyContent="flex-start"
		alignItems="flex-start"
	>
		<Text>Notas</Text>
	</VStack>
);

export default Notes;
