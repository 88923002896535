const formatValues = (field) => {
	let oprt = 'EQ';
	let finalValue = field.value;
	let { label } = field;

	if (field.label == 'start_date' || field.label == 'end_date')
		label = 'created_at';

	if (field.label == 'created_at') {
		oprt = 'GT';
	}
	if (field.label == 'start_date') oprt = 'GT';
	if (field.label == 'end_date') oprt = 'LT';

	if (field.label == 'is_active') finalValue = field.value == '1';

	if (field.label.startsWith('has')) {
		const models = field.label.split('_').slice(1);

		return {
			HAS: {
				relation: models.join('.'),
				condition: {
					column: 'ID',
					operator: oprt,
					value: finalValue
				}
			}
		};
	}

	return {
		column: label.toUpperCase(),
		operator: oprt,
		value: finalValue
	};
};

const buildGraphqlFilter = (values) => {
	const filter = {
		where: {
			column: 'ID',
			operator: 'NEQ',
			value: null,
			AND: values.map((val) => formatValues(val))
		}
	};

	return filter;
};

export { buildGraphqlFilter };
