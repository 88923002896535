import { gql } from '@apollo/client';

export const GET_BENEFICIARIES = gql`
	query GetBeneficiaries {
		listBeneficiaries {
			id
			unit_id
			name
			cpf
			phone
			email
			status
			created_at
		}
	}
`;

export const GET_BENEFICIARIES_PAGINATE = gql`
	query PaginateBeneficiaries(
		$first: Int! = 30
		$page: Int! = 1
		$search: String
		$where: QueryPaginateBeneficiariesWhereWhereConditions
	) {
		paginateBeneficiaries(
			first: $first
			page: $page
			search: $search
			where: $where
		) {
			paginatorInfo {
				count
				currentPage
				firstItem
				perPage
				lastItem
				total
			}
			data {
				id
				name
				cpf
				phone
				email
				status
				created_at
				unit {
					id
					name
					regional {
						id
						name
					}
				}
			}
		}
	}
`;

export const GET_BENEFICIARY = gql`
	query GetBeneficiary($id: ID!) {
		getBeneficiary(id: $id) {
			id
			name
			cpf
			phone
			email
			status
			created_at
			unit {
				id
				name
				regional {
					id
					name
				}
			}
		}
	}
`;

export const CREATE_BENEFICIARY = gql`
	mutation CreateBeneficiary($input: CreateBeneficiary) {
		createBeneficiary(input: $input) {
			id
		}
	}
`;

export const IMPORT_BENEFICIARIES = gql`
	mutation ImportBeneficiaries($unit_id: String, $file: Upload!) {
		importBeneficiaries(unit_id: $unit_id, file: $file)
	}
`;

export const UPDATE_BENEFICIARY = gql`
	mutation UpdateBeneficiary($input: UpdateBeneficiary) {
		updateBeneficiary(input: $input) {
			id
			name
			cpf
			phone
			email
			status
			unit {
				id
				name
				regional {
					id
					name
				}
			}
		}
	}
`;

export const DELETE_BENEFICIARY = gql`
	mutation DeleteBeneficiary(
		$where: MutationDeleteBeneficiaryWhereWhereConditions
	) {
		deleteBeneficiary(where: $where) {
			id
		}
	}
`;
