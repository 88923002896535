import { gql } from '@apollo/client';

export const GET_UNITS = gql`
	query GetUnits {
		listUnits {
			id
			name
			is_active
			created_at
			regional {
				id
				name
			}
		}
	}
`;

export const GET_UNITS_PAGINATE = gql`
	query PaginateUnits(
		$first: Int! = 30
		$page: Int! = 1
		$where: QueryPaginateUnitsWhereWhereConditions
	) {
		paginateUnits(first: $first, page: $page, where: $where) {
			paginatorInfo {
				count
				currentPage
				firstItem
				perPage
				lastItem
				total
			}
			data {
				id
				name
				is_active
				created_at
				regional {
					id
					name
				}
			}
		}
	}
`;

export const GET_UNIT = gql`
	query GetUnit($id: ID!) {
		getUnit(id: $id) {
			id
			name
			is_active
			created_at
			regional {
				id
				name
			}
		}
	}
`;

export const CREATE_UNIT = gql`
	mutation CreateUnit($input: CreateUnit) {
		createUnit(input: $input) {
			id
			name
			is_active
			regional {
				id
				name
			}
		}
	}
`;

export const UPDATE_UNIT = gql`
	mutation UpdateUnit($input: UpdateUnit) {
		updateUnit(input: $input) {
			id
			name
			is_active
			regional {
				id
				name
			}
		}
	}
`;

export const DELETE_UNIT = gql`
	mutation DeleteUnit($where: MutationDeleteUnitWhereWhereConditions) {
		deleteUnit(where: $where) {
			id
		}
	}
`;
