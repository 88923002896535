import { createContext, useContext, useReducer, useState } from 'react';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import {
	GET_BENEFICIARIES_PAGINATE,
	GET_BENEFICIARIES,
	CREATE_BENEFICIARY,
	GET_BENEFICIARY,
	UPDATE_BENEFICIARY,
	DELETE_BENEFICIARY,
	IMPORT_BENEFICIARIES
} from 'graphql/beneficiaries';

import { ACTION_TYPES, reducers } from './reducers';

const BeneficiariesStateContext = createContext();
const BeneficiariesDispatchContext = createContext();

const initialState = {
	rows: [],
	beneficiary: {},
	page: 1,
	perPage: 15
};

const BeneficiariesProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducers, initialState);
	const [toDelete, setToDelete] = useState(null);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);

	return (
		<BeneficiariesStateContext.Provider
			value={{
				...state,
				toDelete,
				setToDelete,
				deleteModalOpen,
				setDeleteModalOpen
			}}
		>
			<BeneficiariesDispatchContext.Provider value={dispatch}>
				{children}
			</BeneficiariesDispatchContext.Provider>
		</BeneficiariesStateContext.Provider>
	);
};

const useBeneficiaries = () => {
	const context = useContext(BeneficiariesStateContext);

	if (!context)
		throw new Error(
			'useBeneficiaries must be used within an BeneficiariesProvider'
		);

	return context;
};

const useDispatch = () => {
	const dispatch = useContext(BeneficiariesDispatchContext);

	if (dispatch === undefined)
		throw new Error(
			'useDispatch must be used within a BeneficiariesProvider'
		);

	return dispatch;
};

const usePaginateBeneficiaries = () => {
	const dispatch = useDispatch();

	return useLazyQuery(GET_BENEFICIARIES_PAGINATE, {
		onCompleted: (data) => {
			window.history.replaceState(
				null,
				'',
				`./gestao-vidas?page=${data.paginateBeneficiaries.paginatorInfo.currentPage}&size=${data.paginateBeneficiaries.paginatorInfo.perPage}`
			);
			dispatch({
				type: ACTION_TYPES.LIST,
				data: data.paginateBeneficiaries
			});
		}
	});
};

const useGetBeneficiaries = () => useQuery(GET_BENEFICIARIES);

const useCreateBeneficiary = (options = {}) => {
	const dispatch = useDispatch();
	const { onSuccess, onError } = options;

	return useMutation(CREATE_BENEFICIARY, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data);
			dispatch({ type: ACTION_TYPES.ADD, data: data.createBeneficiary });
		},
		onError: (error) => {
			if (onError) onError(error);
		}
	});
};

const useImportBeneficiaries = (options = {}) => {
	const { onSuccess, onError } = options;

	return useMutation(IMPORT_BENEFICIARIES, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data);
		},
		onError: (error) => {
			if (onError) onError(error);
		}
	});
};

const useBeneficiary = (id) => {
	const dispatch = useContext(BeneficiariesDispatchContext);

	return useQuery(
		GET_BENEFICIARY,
		{
			fetchPolicy: 'no-cache',
			variables: {
				id
			}
		},
		{
			onCompleted: (data) => {
				dispatch({
					type: ACTION_TYPES.VIEW,
					data: data.getBeneficiary
				});
			}
		}
	);
};

const useUpdateBeneficiary = (options = {}) => {
	const dispatch = useContext(BeneficiariesDispatchContext);
	const { onSuccess, onError } = options;

	return useMutation(UPDATE_BENEFICIARY, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data);
			dispatch({
				type: ACTION_TYPES.UPDATE,
				data: data.updateBeneficiary
			});
		},
		onError: (error) => {
			if (onError) onError(error);
		}
	});
};

const useDeleteBeneficiary = (options = {}) => {
	const dispatch = useDispatch();
	const { onSuccess } = options;

	return useMutation(DELETE_BENEFICIARY, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess();
			dispatch({
				type: ACTION_TYPES.REMOVE,
				data: data.deleteBeneficiary
			});
		}
	});
};

export {
	BeneficiariesProvider,
	useBeneficiaries,
	useCreateBeneficiary,
	useImportBeneficiaries,
	usePaginateBeneficiaries,
	useGetBeneficiaries,
	useBeneficiary,
	useUpdateBeneficiary,
	useDeleteBeneficiary
};
