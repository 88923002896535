import { gql } from '@apollo/client';

export const GET_REGIONALS = gql`
	query GetRegionals {
		listRegionals {
			id
			client_id
			name
			admin_id
			cnpj
			phone
			units {
				id
				name
			}
			is_active
			created_at
		}
	}
`;

export const GET_REGIONALS_PAGINATE = gql`
	query PaginateRegionals(
		$first: Int! = 30
		$page: Int! = 1
		$where: QueryPaginateRegionalsWhereWhereConditions
	) {
		paginateRegionals(first: $first, page: $page, where: $where) {
			paginatorInfo {
				count
				currentPage
				firstItem
				perPage
				lastItem
				total
			}
			data {
				id
				client_id
				name
				admin_id
				cnpj
				phone
				is_active
				created_at
			}
		}
	}
`;

export const GET_REGIONAL = gql`
	query GetRegional($id: ID!) {
		getRegional(id: $id) {
			id
			client_id
			name
			admin_id
			cnpj
			phone
			is_active
			created_at
		}
	}
`;
export const GET_REGIONALS_ALL = gql`
	query GetRegionals($id: ID!) {
		regionals(client_id: $id) {
			id
			client_id
			name
			admin_id
			cnpj
			phone
			is_active
			created_at
		}
	}
`;
export const CREATE_REGIONAL = gql`
	mutation CreateRegional($input: CreateRegional) {
		createRegional(input: $input) {
			id
			name
			cnpj
			phone
		}
	}
`;

export const UPDATE_REGIONAL = gql`
	mutation UpdateRegional($input: UpdateRegional) {
		updateRegional(input: $input) {
			id
			name
			cnpj
			phone
		}
	}
`;

export const DELETE_REGIONAL = gql`
	mutation DeleteRegional(
		$where: MutationDeleteRegionalWhereWhereConditions
	) {
		deleteRegional(where: $where) {
			id
		}
	}
`;
