import { FiltersProvider } from 'hooks/filters';
import { RegionalsProvider } from 'hooks/regionals';
import { UnitsProvider } from 'hooks/units';
import { BeneficiariesProvider as Provider } from 'hooks/beneficiaries';
import { ManagerUploadFile } from 'services/clients/managerUploadFile';

const BeneficiariesProvider = ({ children }) => (
	<ManagerUploadFile>
		<RegionalsProvider>
			<UnitsProvider>
				<FiltersProvider>
					<Provider>{children}</Provider>
				</FiltersProvider>
			</UnitsProvider>
		</RegionalsProvider>
	</ManagerUploadFile>
);

export default BeneficiariesProvider;
