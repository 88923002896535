import { FiltersProvider } from 'hooks/filters';
import { RegionalsProvider as Provider } from 'hooks/regionals';
import { ManagerQueryProvider } from 'services/manager';

const RegionalsProvider = ({ children }) => (
	<ManagerQueryProvider>
		<FiltersProvider>
			<Provider>{children}</Provider>
		</FiltersProvider>
	</ManagerQueryProvider>
);

export default RegionalsProvider;
