import { ChartBar, Notebook } from '@phosphor-icons/react';

const menuItems = [
	{
		name: 'Dashboards',
		path: '/dashboards',
		icon: (color) => (
			<ChartBar size={18} weight="bold" color={color || '#72b6ae'} />
		),
		collapse: true,
		roles: [],
		items: (dashboards = []) => [
			{
				name: 'Exemplo',
				path: '/dashboards/exemplo',
				roles: []
			},
			...dashboards.map((dash) => ({
				name: dash.name,
				path: `/dashboards/${dash.id}`,
				roles: []
			}))
		]
	},
	{
		name: 'Administração',
		path: '/administracao',
		icon: (color) => (
			<Notebook size={18} weight="bold" color={color || '#72b6ae'} />
		),
		collapse: true,
		roles: [],
		items: () => [
			{
				name: 'Gestão de Vidas',
				path: '/administracao/gestao-vidas',
				roles: []
			},
			{
				name: 'Clientes',
				path: '/administracao/clientes',
				roles: []
			},
			{
				name: 'Unidades',
				path: '/administracao/unidades',
				roles: []
			},
			{
				name: 'Regionais',
				path: '/administracao/regionais',
				roles: []
			},
			{
				name: 'Formulários',
				path: '/administracao/formularios',
				roles: []
			},
			{
				name: 'Campanhas',
				path: '/administracao/campanhas',
				roles: []
			},
			{
				name: 'Relatórios',
				path: '/administracao/relatorios-custom',
				roles: []
			},
			{
				name: 'Dashboards',
				path: '/administracao/custom-dashboards',
				roles: []
			},
			{
				name: 'Usuários',
				path: '/administracao/usuarios',
				roles: []
			},
			{
				name: 'Suporte',
				path: '/administracao/suporte',
				roles: []
			}
		]
	}
];

export default menuItems;
