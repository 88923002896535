import {
	Button,
	Flex,
	Grid,
	HStack,
	Text,
	useColorModeValue
} from '@chakra-ui/react';
import { Funnel } from '@phosphor-icons/react';

import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';

const FiltersBox = ({ children, handleClick }) => {
	const iconColor = useColorModeValue('#FFF', '#000');
	return (
		<Card mb="24px">
			<CardHeader>
				<Text fontWeight="bold" size="md">
					Filtros
				</Text>
			</CardHeader>
			<CardBody>
				<Grid
					templateColumns={{
						sm: '1fr',
						md: 'repeat(6, 1fr)'
					}}
					mt="24px"
					gap="24px"
					w="full"
				>
					{children}
				</Grid>
			</CardBody>
			<CardFooter>
				<Flex w="full" justifyContent="end">
					<HStack spacing={4}>
						{/* <Button
							colorScheme="orange"
							onClick={() => handleClear()}
							leftIcon={
								<Eraser
									size={20}
									color={iconColor}
								/>
							}
						>
							Limpar Filtros
						</Button> */}
						<Button
							colorScheme="blue"
							onClick={() => handleClick()}
							leftIcon={
								<Funnel
									size={20}
									color={iconColor}
									weight="duotone"
								/>
							}
						>
							Filtrar
						</Button>
					</HStack>
				</Flex>
			</CardFooter>
		</Card>
	);
};

export default FiltersBox;
