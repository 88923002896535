import { useBeneficiaries, useDeleteBeneficiary } from 'hooks/beneficiaries';

import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useToast
} from '@chakra-ui/react';

const DeleteBeneficiary = () => {
	const { toDelete, deleteModalOpen, setDeleteModalOpen } =
		useBeneficiaries();
	const toast = useToast();

	const [deleteBeneficiary, { loading }] = useDeleteBeneficiary({
		onSuccess: () => {
			toast({
				title: 'Vida removida com sucesso.',
				status: 'success',
				duration: 5000,
				isClosable: true
			});
			setDeleteModalOpen(false);
		},
		onError: () => {
			toast({
				title: 'Ocorreu um erro.',
				status: 'error',
				duration: 5000,
				isClosable: true
			});
		}
	});

	const onDelete = () => {
		deleteBeneficiary({
			variables: {
				where: {
					column: 'ID',
					operator: 'EQ',
					value: toDelete
				}
			}
		});
	};

	return (
		<Modal
			isCentered
			onClose={() => setDeleteModalOpen(false)}
			isOpen={deleteModalOpen}
			motionPreset="slideInBottom"
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Remover Vida</ModalHeader>
				<ModalCloseButton />
				<ModalBody>Por favor, confirme a ação de remoção.</ModalBody>
				<ModalFooter>
					<Button
						mr={3}
						variant="ghost"
						size="md"
						onClick={() => setDeleteModalOpen(false)}
					>
						Cancelar
					</Button>
					<Button
						isLoading={loading}
						colorScheme="red"
						size="md"
						onClick={() => onDelete()}
					>
						Remover
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default DeleteBeneficiary;
