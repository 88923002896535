import { Flex, Grid, VStack } from '@chakra-ui/react';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import { useBeneficiary } from 'hooks/beneficiaries';
import { useParams } from 'react-router-dom';

import BeneficiaryDetails from './components/BeneficiaryDetails';
import Folders from './components/folders';
import ActionsMenu from './components/Menu';

const ViewBeneficiary = () => {
	const { id } = useParams();
	const { data, loading } = useBeneficiary(id);
	const beneficiary = data?.getBeneficiary || {};

	return (
		<Grid
			templateColumns={{ sm: '1fr', md: 'repeat(1, 1fr 2fr)' }}
			templateRows={{ sm: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)' }}
			gap="12px"
			w="100%"
			pt={{ sm: '120px', lg: '160px' }}
		>
			<Card
				px="0px"
				pb="22px"
				minHeight="100px"
				overflowX={{ sm: 'auto', lg: 'unset' }}
			>
				<CardBody h="100%" px="22px">
					<VStack spacing={8} w="full">
						<Flex
							w="full"
							justifyContent="center"
							alignItems="center"
							position="relative"
						>
							<ActionsMenu id={id} />
						</Flex>
						<BeneficiaryDetails
							beneficiary={beneficiary}
							isLoading={loading}
						/>
					</VStack>
				</CardBody>
			</Card>
			<Folders isLoading={loading} />
		</Grid>
	);
};

export default ViewBeneficiary;
