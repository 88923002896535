import { useCampaigns, useDeleteCampaign } from 'hooks/campaigns';

import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useToast
} from '@chakra-ui/react';

const DeleteCampaign = () => {
	const { toDelete, deleteModalOpen, setDeleteModalOpen } = useCampaigns();
	const toast = useToast();

	const [deleteCampaign, { loading }] = useDeleteCampaign({
		onSuccess: () => {
			toast({
				title: 'Campanha removida com sucesso.',
				status: 'success',
				duration: 5000,
				isClosable: true
			});
			setDeleteModalOpen(false);
		},
		onError: () => {
			toast({
				title: 'Ocorreu um erro.',
				status: 'error',
				duration: 5000,
				isClosable: true
			});
		}
	});

	const onDelete = () => {
		deleteCampaign({
			variables: {
				where: {
					column: 'ID',
					operator: 'EQ',
					value: toDelete
				}
			}
		});
	};

	return (
		<Modal
			isCentered
			onClose={() => setDeleteModalOpen(false)}
			isOpen={deleteModalOpen}
			motionPreset="slideInBottom"
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Remover Campanha</ModalHeader>
				<ModalCloseButton />
				<ModalBody>Por favor, confirme a ação de remoção.</ModalBody>
				<ModalFooter>
					<Button
						mr={3}
						variant="ghost"
						size="md"
						onClick={() => setDeleteModalOpen(false)}
					>
						Cancelar
					</Button>
					<Button
						isLoading={loading}
						colorScheme="red"
						size="md"
						onClick={() => onDelete()}
					>
						Remover
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default DeleteCampaign;
