import { useEffect } from 'react';
import { Text } from '@chakra-ui/react';

import { useReports, usePaginateReports } from 'hooks/customReports';
import { useFilters } from 'hooks/filters';

import AsyncTable from 'components/Tables/AsyncTable';
import dateFormat from 'functions/dateFormat';

const columns = [
	{
		Header: 'Nome',
		accessor: 'name'
	},
	{
		Header: 'Total de Colunas',
		accessor: 'columns',
		Cell: ({ value }) => <Text>{value?.length}</Text>
	},
	{
		Header: 'Data de criação',
		accessor: 'created_at',
		Cell: ({ value }) => <Text>{dateFormat(value)}</Text>
	}
];

const ReportsTable = () => {
	const { filters } = useFilters();
	const { rows, page, perPage, total, setToDelete, setDeleteModalOpen } =
		useReports();
	const [paginateReports, { loading }] = usePaginateReports();

	const deleteReport = (id) => {
		setToDelete(id);
		setDeleteModalOpen(true);
	};

	const fetchData = ({ pageSize, pageIndex }) => {
		if (pageSize != perPage) pageIndex = 1;
		paginateReports({
			variables: {
				first: pageSize || perPage,
				page: pageIndex || page,
				...filters
			}
		});
	};

	useEffect(() => {
		if (filters && Object.keys(filters).length > 0) {
			paginateReports({
				variables: {
					first: perPage,
					page,
					...filters
				}
			});
		}
	}, [filters]);

	return (
		<AsyncTable
			fetchData={fetchData}
			isLoaded={!loading}
			data={!loading ? rows : [{}, {}, {}, {}]}
			columns={columns}
			total={total}
			size={perPage}
			asyncPage={page}
			searchByName
			baseRoute="relatorios-custom"
			removeAction={deleteReport}
			linkRow
		/>
	);
};

export default ReportsTable;
