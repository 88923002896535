import { Flex, Text } from '@chakra-ui/react';

const Counter = ({ value }) => (
	<Flex
		justifyContent="center"
		alignItems="center"
		width="100%"
		height="100%"
	>
		<Text fontSize="30px" fontWeight="bold">
			{value}
		</Text>
	</Flex>
);

export default Counter;
