import { Text, Select, VStack, Skeleton } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { useGetRegionals } from 'hooks/regionals';
import { useGetUnits } from 'hooks/units';
import { useGetForms } from 'hooks/forms';

const Fields = () => {
	const {
		data: { listRegionals: regionals = [] } = [],
		loading: loadingRegionals
	} = useGetRegionals();
	const { data: { listUnits: units = [] } = [], loading: loadingUnits } =
		useGetUnits();
	const {
		data: { listFormConfigs: forms = [] } = [],
		loading: loadingForms
	} = useGetForms();

	const methods = useFormContext();

	const { register } = methods;

	return (
		<>
			<VStack spacing={2} alignItems="flex-start">
				<Text size="sm">Status</Text>
				<Select
					placeholder="Selecione uma opção..."
					{...register('is_active')}
				>
					<option value={1}>Ativo</option>
					<option value={0}>Inativo</option>
				</Select>
			</VStack>
			<VStack spacing={2} alignItems="flex-start">
				<Text size="sm">Regional</Text>
				<Skeleton height="40px" isLoaded={!loadingRegionals}>
					<Select
						placeholder="Selecione uma opção..."
						{...register('has_unit_regional')}
					>
						{regionals.map((reg) => (
							<option value={reg.id}>{reg.name}</option>
						))}
					</Select>
				</Skeleton>
			</VStack>
			<VStack spacing={2} alignItems="flex-start">
				<Text size="sm">Unidade</Text>
				<Skeleton height="40px" isLoaded={!loadingUnits}>
					<Select
						placeholder="Selecione uma opção..."
						{...register('unit_id')}
					>
						{units.map((unit) => (
							<option value={unit.id}>{unit.name}</option>
						))}
					</Select>
				</Skeleton>
			</VStack>
			<VStack spacing={2} alignItems="flex-start">
				<Text size="sm">Formulário</Text>
				<Skeleton height="40px" isLoaded={!loadingForms}>
					<Select
						placeholder="Selecione uma opção..."
						{...register('form_config_id')}
					>
						{forms.map((form) => (
							<option value={form.id}>{form.name}</option>
						))}
					</Select>
				</Skeleton>
			</VStack>
		</>
	);
};

export default Fields;
