import Amplify from 'services/clients/cognitoAmplify'; // eslint-disable-line
import '@aws-amplify/ui-react/dist/styles.css';
import { useEffect, useState } from 'react';

import { Auth } from 'aws-amplify';
import AuthProvider from './authProvider';
import { QueryProvider } from './queryClient';
import {
	JWT_TOKEN_COGNITO,
	JWT_TOKEN_COGNITO_ID
} from '../variables/enumLabels';
import CustomDashboardsProvider from './customDashboardProvider';

const AppProvider = ({ user, children }) => {
	const [provider, setProvider] = useState(<b>Carregando....</b>);
	useEffect(() => {
		Auth.currentSession().then((session) => {
			window.localStorage.setItem(
				JWT_TOKEN_COGNITO,
				session.accessToken.jwtToken
			);
			window.localStorage.setItem(
				JWT_TOKEN_COGNITO_ID,
				session.accessToken.jwtToken
			);
			setProvider(
				<QueryProvider>
					<AuthProvider user={user}>
						<CustomDashboardsProvider>
							{children}
						</CustomDashboardsProvider>
					</AuthProvider>
				</QueryProvider>
			);
		});
	}, []);

	return provider;
};

export default AppProvider;
