import { useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import InputMask from 'react-input-mask';

import { useCreateBeneficiary } from 'hooks/beneficiaries';

import {
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Grid,
	HStack,
	Input,
	Select,
	Skeleton,
	Text,
	useColorModeValue,
	useToast
} from '@chakra-ui/react';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import { useGetRegionals } from 'hooks/regionals';
import { useGetUnits } from 'hooks/units';

const schema = yup.object().shape({
	name: yup.string().required('O campo Nome é obrigatório'),
	cpf: yup.string().required('O campo CPF é obrigatório'),
	phone: yup.string().required('O campo Telefone é obrigatório'),
	email: yup
		.string()
		.email('Insira um E-mail válido')
		.required('O campo E-mail é obrigatório'),
	unit_id: yup.string().required('O campo Unidade é obrigatório')
});

const CreateBeneficiary = () => {
	const { data: dataR, loading: loadingRegionals } = useGetRegionals();
	const { data: dataU, loading: loadingUnits } = useGetUnits();

	const regionals = dataR?.listRegionals || [];
	const units = dataU?.listUnits || [];

	const methods = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		resolver: yupResolver(schema)
	});
	const toast = useToast();
	const history = useHistory();

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		watch
	} = methods;

	const wRegional = watch('regional_id', null);

	const [createBeneficiary, { loading }] = useCreateBeneficiary({
		onSuccess: (data) => {
			reset();
			toast({
				title: 'Vida cadastrada com sucesso.',
				status: 'success',
				duration: 5000,
				isClosable: true
			});
			history.push(
				`/sistema/administracao/gestao-vidas/${data.createBeneficiary.id}`
			);
		},
		onError: () => {
			toast({
				title: 'Ocorreu um erro',
				status: 'error',
				duration: 5000,
				isClosable: true
			});
		}
	});

	const onSubmit = (data) => {
		const unitId = data.unit_id;
		delete data.regional_id;
		delete data.unit_id;

		data.cpf = data.cpf.replaceAll('-', '').replaceAll('.', '');
		data.phone = data.phone
			.replaceAll('(', '')
			.replaceAll(')', '')
			.replaceAll('-', '')
			.replaceAll(' ', '');

		const variables = {
			input: {
				...data,
				unit: {
					connect: unitId
				}
			}
		};
		createBeneficiary({ variables });
	};

	const inputHover = useColorModeValue('health.800', 'health.800');
	const textColor = useColorModeValue('gray.700', 'white');

	return (
		<Flex
			direction="column"
			minH="100vh"
			align="center"
			pt={{ sm: '120px', lg: '160px' }}
		>
			<Card w={{ md: '100%', lg: '50%' }}>
				<CardHeader mb="40px">
					<Flex direction="column">
						<Text
							color={textColor}
							fontSize="lg"
							fontWeight="bold"
							mb="3px"
						>
							Cadastrar Vida
						</Text>
						<Text
							color="gray.400"
							fontWeight="normal"
							fontSize="sm"
						>
							Preencha os campos para realizar o cadastro
						</Text>
					</Flex>
				</CardHeader>
				<CardBody>
					<FormProvider {...methods}>
						<form
							style={{ width: '100%' }}
							onSubmit={handleSubmit(onSubmit)}
						>
							<Flex direction="column" w="100%">
								<Grid
									templateColumns={{
										sm: '1fr',
										md: 'repeat(2, 1fr)'
									}}
									gap="24px"
								>
									<FormControl isInvalid={errors.name}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Nome
										</FormLabel>
										<Input
											{...register('name')}
											focusBorderColor={inputHover}
											borderRadius="8px"
											fontSize="md"
										/>
										{errors.name && (
											<FormErrorMessage>
												{errors.name.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.name}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											CPF
										</FormLabel>
										<Input
											{...register('cpf')}
											as={InputMask}
											mask="999.999.999-99"
											focusBorderColor={inputHover}
											borderRadius="8px"
											fontSize="md"
										/>
										{errors.cpf && (
											<FormErrorMessage>
												{errors.cpf.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.phone}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Telefone
										</FormLabel>
										<Input
											as={InputMask}
											mask="(99) 99999-9999"
											{...register('phone')}
											focusBorderColor={inputHover}
											borderRadius="8px"
											fontSize="md"
										/>
										{errors.phone && (
											<FormErrorMessage>
												{errors.phone.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.email}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											E-mail
										</FormLabel>
										<Input
											{...register('email')}
											focusBorderColor={inputHover}
											borderRadius="8px"
											fontSize="md"
										/>
										{errors.email && (
											<FormErrorMessage>
												{errors.email.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.regional_id}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Regional
										</FormLabel>
										<Skeleton
											height="40px"
											isLoaded={!loadingRegionals}
										>
											<Select
												placeholder="Selecione uma opção..."
												{...register('regional_id')}
											>
												{regionals.map((reg) => (
													<option value={reg.id}>
														{reg.name}
													</option>
												))}
											</Select>
										</Skeleton>
										{errors.regional_id && (
											<FormErrorMessage>
												{errors.regional_id.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.unit_id}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Unidade
										</FormLabel>
										<Skeleton
											height="40px"
											isLoaded={!loadingUnits}
										>
											<Select
												placeholder={
													!wRegional
														? 'Seleione uma regional'
														: 'Selecione uma opção...'
												}
												disabled={!wRegional}
												{...register('unit_id')}
											>
												{units
													?.filter(
														(unit) =>
															unit.regional.id ==
															wRegional
													)
													.map((unit) => (
														<option value={unit.id}>
															{unit.name}
														</option>
													))}
											</Select>
										</Skeleton>
										{errors.unit_id && (
											<FormErrorMessage>
												{errors.unit_id.message}
											</FormErrorMessage>
										)}
									</FormControl>
								</Grid>
								<Flex
									w="full"
									justifyContent="flex-end"
									mt="24px"
								>
									<HStack spacing={4}>
										<Button
											disabled={loading}
											variant="ghost"
											colorScheme="gray"
											alignSelf="flex-end"
											size="md"
											onClick={() => history.goBack()}
										>
											Cancelar
										</Button>
										<Button
											isLoading={loading}
											disabled={loading}
											colorScheme="green"
											type="submit"
											alignSelf="flex-end"
											size="md"
										>
											Cadastrar
										</Button>
									</HStack>
								</Flex>
							</Flex>
						</form>
					</FormProvider>
				</CardBody>
			</Card>
		</Flex>
	);
};

export default CreateBeneficiary;
