import {
	Box,
	Flex,
	HStack,
	Text,
	Tooltip,
	useColorModeValue,
	VStack
} from '@chakra-ui/react';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import { Info } from '@phosphor-icons/react';

const ChartContainer = ({
	children,
	title = null,
	subtitle = null,
	minHeight = '300px',
	maxHeight = '450px',
	position = 'static',
	overflowY = 'unset',
	tooltip = null
}) => {
	const textColor = useColorModeValue('gray.700', 'white');

	return (
		<Card
			minHeight={minHeight}
			maxHeight={maxHeight}
			width="100%"
			height="100%"
			position={position}
			overflowY={overflowY}
			overflowX={{ sm: 'auto', lg: 'unset' }}
		>
			<CardHeader>
				<VStack spacing={2} alignItems="left" w="full">
					<HStack spacing={2} alignItems="center" w="full">
						<Text
							color={textColor}
							fontSize="lg"
							fontWeight="bold"
							marginBottom={0}
						>
							{title}
						</Text>
						{tooltip && (
							<Tooltip
								label={tooltip}
								placement="top"
								fontSize="md"
							>
								<Flex w="5" h="full" alignItems="center">
									<Info size={22} weight="fill" />
								</Flex>
							</Tooltip>
						)}
					</HStack>
					{subtitle && (
						<Text color={textColor} fontSize="md" marginBottom={0}>
							{title}
						</Text>
					)}
				</VStack>
			</CardHeader>
			<CardBody h="100%" py=".75em">
				<Box w="100%" h="100%" minW={{ lg: 'auto', sm: 700 }}>
					{children}
				</Box>
			</CardBody>
		</Card>
	);
};

export default ChartContainer;
