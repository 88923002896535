import {
	Text,
	useColorModeValue,
	Select,
	VStack,
	Input
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import InputMask from 'react-input-mask';

const Fields = () => {
	const methods = useFormContext();

	const { register } = methods;

	const inputHover = useColorModeValue('notrelife.800', 'notrelife.800');

	return (
		<>
			<VStack spacing={2} alignItems="flex-start">
				<Text size="sm">Status</Text>
				<Select
					placeholder="Selecione uma opção..."
					{...register('is_active')}
				>
					<option value={1}>Ativo</option>
					<option value={0}>Inativo</option>
				</Select>
			</VStack>
			<VStack spacing={2} alignItems="flex-start">
				<Text size="sm">Data Inicial</Text>
				<Input
					as={InputMask}
					mask="99/99/9999"
					focusBorderColor={inputHover}
					borderRadius="8px"
					fontSize="md"
					{...register('start_date')}
				/>
			</VStack>
			<VStack spacing={2} alignItems="flex-start">
				<Text size="sm">Data Final</Text>
				<Input
					as={InputMask}
					mask="99/99/9999"
					focusBorderColor={inputHover}
					borderRadius="8px"
					fontSize="md"
					{...register('end_date')}
				/>
			</VStack>
		</>
	);
};

export default Fields;
