import { useClients, usePaginateClients } from 'hooks/clients';

import AsyncTable from 'components/Tables/AsyncTable';
import StatusIndicator from 'components/StatusIndicator';

const columns = [
	{
		Header: 'Nome',
		accessor: 'name',
		isLink: true
	},
	{
		Header: 'Ativo',
		accessor: 'enabled',
		Cell: ({ value }) => <StatusIndicator isActive={value} size="sm" />
	},
	{
		Header: 'Contato',
		accessor: 'contact'
	},
	{
		Header: 'E-mail',
		accessor: 'email'
	}
];

const ClientsTable = () => {
	const { page, perPage, total, rows } = useClients();
	const [paginateClients, { loading }] = usePaginateClients();

	const fetchData = ({ pageSize, pageIndex, search }) => {
		paginateClients({
			variables: {
				first: pageSize || perPage,
				page: pageIndex || page,
				search: search || ''
			}
		});
	};

	return (
		<AsyncTable
			fetchData={fetchData}
			isLoaded={!loading}
			data={!loading ? rows : [{}, {}, {}, {}]}
			columns={columns}
			total={total}
			size={perPage}
			asyncPage={page}
			searchByName
			baseRoute="clientes"
			noDelete
			linkRow
		/>
	);
};

export default ClientsTable;
