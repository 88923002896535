import { createContext, useContext, useReducer, useState } from 'react';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import {
	GET_REGIONALS_PAGINATE,
	GET_REGIONALS,
	CREATE_REGIONAL,
	GET_REGIONAL,
	UPDATE_REGIONAL,
	DELETE_REGIONAL
} from 'graphql/regionals';

import { ACTION_TYPES, reducers } from './reducers';

const RegionalsStateContext = createContext();
const RegionalsDispatchContext = createContext();

const initialState = {
	rows: [],
	regional: {},
	page: 1,
	perPage: 15
};

const RegionalsProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducers, initialState);
	const [toDelete, setToDelete] = useState(null);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);

	return (
		<RegionalsStateContext.Provider
			value={{
				...state,
				toDelete,
				setToDelete,
				deleteModalOpen,
				setDeleteModalOpen
			}}
		>
			<RegionalsDispatchContext.Provider value={dispatch}>
				{children}
			</RegionalsDispatchContext.Provider>
		</RegionalsStateContext.Provider>
	);
};

const useRegionals = () => {
	const context = useContext(RegionalsStateContext);

	if (!context)
		throw new Error(
			'useRegionals must be used within an RegionalsProvider'
		);

	return context;
};

const useDispatch = () => {
	const dispatch = useContext(RegionalsDispatchContext);

	if (dispatch === undefined)
		throw new Error('useDispatch must be used within a RegionalsProvider');

	return dispatch;
};

const usePaginateRegionals = () => {
	const dispatch = useDispatch();

	return useLazyQuery(GET_REGIONALS_PAGINATE, {
		onCompleted: (data) => {
			window.history.replaceState(
				null,
				'',
				`./regionais?page=${data.paginateRegionals.paginatorInfo.currentPage}&size=${data.paginateRegionals.paginatorInfo.perPage}`
			);
			dispatch({ type: ACTION_TYPES.LIST, data: data.paginateRegionals });
		}
	});
};

const useGetRegionals = () => useQuery(GET_REGIONALS);

const useCreateRegional = (options = {}) => {
	const dispatch = useDispatch();
	const { onSuccess, onError } = options;

	return useMutation(CREATE_REGIONAL, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data);
			dispatch({ type: ACTION_TYPES.ADD, data: data.createRegional });
		},
		onError: (error) => {
			if (onError) onError(error);
		}
	});
};

const useRegional = (id) => {
	const dispatch = useContext(RegionalsDispatchContext);

	return useQuery(
		GET_REGIONAL,
		{
			fetchPolicy: 'no-cache',
			variables: {
				id
			}
		},
		{
			onCompleted: (data) => {
				dispatch({ type: ACTION_TYPES.VIEW, data: data.getRegional });
			}
		}
	);
};

const useUpdateRegional = (options = {}) => {
	const dispatch = useContext(RegionalsDispatchContext);
	const { onSuccess, onError } = options;

	return useMutation(UPDATE_REGIONAL, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data);
			dispatch({ type: ACTION_TYPES.UPDATE, data: data.updateRegional });
		},
		onError: (error) => {
			if (onError) onError(error);
		}
	});
};

const useDeleteRegional = (options = {}) => {
	const dispatch = useDispatch();
	const { onSuccess } = options;

	return useMutation(DELETE_REGIONAL, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess();
			dispatch({ type: ACTION_TYPES.REMOVE, data: data.deleteRegional });
		}
	});
};

export {
	RegionalsProvider,
	useRegionals,
	useCreateRegional,
	usePaginateRegionals,
	useGetRegionals,
	useRegional,
	useUpdateRegional,
	useDeleteRegional
};
