import { ResponsivePie } from '@nivo/pie';
import { useColorModeValue } from '@chakra-ui/react';
import { border, tooltip } from 'variables/nivo/default';
import {
	arc,
	arcLabelsTextColor,
	arcLinkLabelsColor,
	legendsDark,
	legendsLight,
	margin,
	pieProps
} from 'variables/nivo/halfPie';

import { colors } from 'variables/customDashboards';

const total = () => 20;

const Velocimeter = ({ color }) => {
	const mockData = [
		{
			id: 'sent',
			label: 'Enviadas',
			value: 20
		},
		{
			id: 'signed',
			label: 'Assinadas',
			value: 12
		}
	];

	return (
		<ResponsivePie
			data={mockData}
			margin={margin}
			colors={colors.find((cl) => cl.value == color)?.hex}
			{...border}
			{...arc}
			arcLinkLabelsColor={arcLinkLabelsColor}
			arcLabelsTextColor={arcLabelsTextColor}
			legends={useColorModeValue(legendsLight, legendsDark)}
			tooltip={({ datum }) =>
				tooltip(datum.label, datum.value, total(), datum.color)
			}
			{...pieProps}
			enableArcLabels={false}
			innerRadius={0.7}
		/>
	);
};

export default Velocimeter;
