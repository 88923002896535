const ACTION_TYPES = {
	LIST_REPORTS: 'LIST_REPORTS',
	ADD_REPORT: 'ADD_REPORT',
	UPDATE_REPORT: 'UPDATE_REPORT',
	READ: 'READ'
};

const reducers = (state, action) => {
	switch (action.type) {
		case ACTION_TYPES.LIST_REPORTS: {
			return {
				...state,
				reports: action.data
			};
		}
		case ACTION_TYPES.ADD_REPORT: {
			return {
				...state,
				reports: [...state.reports, action.data].sort((a, b) =>
					a.created_at > b.created_at ? -1 : 1
				)
			};
		}
		case ACTION_TYPES.UPDATE_REPORT: {
			const reports = state.reports.filter(
				(rp) => rp.id !== action.data.id
			);
			const report = state.reports.find((rp) => rp.id == action.data.id);
			if (report) {
				report.status = action?.data?.status;
				report.url = action?.data?.url;

				return {
					...state,
					reports: [...reports, report].sort((a, b) =>
						a.created_at > b.created_at ? -1 : 1
					),
					newReport: report
				};
			}
			return {
				...state,
				reports: reports.sort((a, b) =>
					a.created_at > b.created_at ? -1 : 1
				)
			};
		}
		case ACTION_TYPES.UPDATE_FORM: {
			return {
				...state,
				forms: [...state.forms, action.data],
				newForm: action.data
			};
		}
		default: {
			return state;
		}
	}
};

export { reducers, ACTION_TYPES };
