import { useHistory, useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useDashboard, useUpdateDashboard } from 'hooks/customDashboards';

import {
	Button,
	Divider,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Grid,
	HStack,
	Input,
	Skeleton,
	Spinner,
	Text,
	useColorModeValue,
	useToast
} from '@chakra-ui/react';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';

import { useGetForms } from 'hooks/forms';
import { useState, useEffect } from 'react';
import { Pencil, Plus, Trash } from '@phosphor-icons/react';

import ChartModal from './components/ChartModal';

const schema = yup.object().shape({
	name: yup.string().required('O campo Nome é obrigatório'),
	charts: yup.array().of(
		yup.object().shape({
			title: yup.string().required('O campo Título é obrigatório'),
			chart_type: yup.string().required('Escolha o Tipo de Gráfico'),
			value_type: yup
				.string()
				.nullable()
				.when('chart_type', {
					is: (type) => type != 'counter',
					then: yup.string().required('Selecione um tipo de seleção')
				}),
			form_id: yup
				.string()
				.nullable()
				.when('chart_type', {
					is: (type) => type == 'counter',
					then: yup.string().required('Selecione um formulário')
				})
				.when('value_type', {
					is: (type) => type == 'common',
					then: yup.string().required('Selecione um formulário')
				}),
			form_label: yup
				.string()
				.nullable()
				.when('chart_type', {
					is: (type) => type == 'counter',
					then: yup.string().required('Insira um dado')
				})
				.when('value_type', {
					is: (type) => type == 'common',
					then: yup.string().required('Insira um dado')
				}),
			value: yup
				.string()
				.nullable()
				.when('chart_type', {
					is: (type) => type == 'counter',
					then: yup.string().required('Insira um valor')
				}),
			color: yup
				.string()
				.nullable()
				.when('value_type', {
					is: (type) => type == 'common',
					then: yup.string().required('Selecione uma cor')
				}),
			max_lines: yup
				.string()
				.nullable()
				.when('value_type', {
					is: (type) => type == 'common',
					then: yup
						.string()
						.required('Insira a quantidade máxima de linhas')
				}),
			values: yup
				.array()
				.nullable()
				.of(
					yup.object().shape({
						form_id: yup
							.string()
							.nullable()
							.required('Selecione um formulário'),
						form_label: yup
							.string()
							.nullable()
							.required('Insira um dado'),
						value: yup
							.string()
							.nullable()
							.required('Insira um valor'),
						color: yup
							.string()
							.nullable()
							.required('Selecione uma cor')
					})
				),
			width: yup.string().required('O campo Largura é obrigatório'),
			order: yup.string().required('O campo Ordem é obrigatório')
		})
	)
});

const EditDashboard = () => {
	const { id } = useParams();
	const { data, loading } = useDashboard(id);
	const { data: dataForms, loading: loadingForms } = useGetForms();
	const dashboard = data?.getDashboard || {};
	const forms = dataForms?.listFormConfigs || [];

	const [currentOpen, setCurrentOpen] = useState(null);
	const [counter, setCounter] = useState(0);
	const [charts, setCharts] = useState([]);

	const history = useHistory();

	const methods = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		resolver: (data, context, options) => {
			const thisCharts = [];
			const keys = Object.keys(data?.charts) || []
			keys.map(chart => thisCharts[data.charts[chart].key] = data.charts[chart])
			const newData = {
				...data,
				charts: thisCharts
			}
			return yupResolver(schema)(newData, context, options)
		}
	});
	const toast = useToast();

	const {
		register,
		unregister,
		reset,
		handleSubmit,
		formState: { errors },
		setError,
		clearErrors,
		getValues,
		setValue
	} = methods;

	const hasName = (id) =>
		getValues(`charts.${id}.title`) != null &&
		getValues(`charts.${id}.title`) != '';

	const handleOpenModal = (id = null) => {
		setCurrentOpen(id);
	};

	const handleAddChart = () => {
		clearErrors(`charts_req`);
		const id = counter;
		setCharts([...charts, { id }]);
		setCounter(counter + 1);
	};

	const handleChartRemove = (id) => {
		clearErrors();
		setCharts(charts.filter((chart) => chart.id !== id));
		unregister(`charts.${id}`);
	};

	useEffect(() => {
		if (dashboard && dashboard.charts) {
			dashboard.charts.forEach((chart) => {
				setValue(`charts.${chart.id}`, { ...chart });
				if (chart.values.length > 0) {
					setValue(`charts.${chart.id}.values`, []);
					chart.values?.forEach((value) =>
						setValue(`charts.${chart.id}.values.${value.id}`, value)
					);
				}
			});
			setCharts(dashboard.charts);
		}
	}, [dashboard]);

	const [updateDashboard, { loading: loadingUpdate }] = useUpdateDashboard({
		onSuccess: (data) => {
			reset();
			toast({
				title: 'Dashboard atualizado com sucesso.',
				status: 'success',
				duration: 5000,
				isClosable: true
			});
			history.push(
				`/sistema/administracao/custom-dashboards/${data.updateDashboard.id}`
			);
		},
		onError: () => {
			toast({
				title: 'Ocorreu um erro',
				status: 'error',
				duration: 5000,
				isClosable: true
			});
		}
	});

	const onSubmit = (data) => {
		clearErrors()
		if (!data.charts || data.charts.length == 0) {
			setError('charts_req', {
				type: 'custom',
				message: 'Insira pelo menos um painel para o dashboard'
			});
			return;
		}

		const chartKeys = Object.keys(data.charts);
		const charts = chartKeys.map((key) => data.charts[key]);
		const createChart = charts
			.filter((chart) => !chart.id)
			.map((chart) => {
				delete chart.key
				return {
					...chart,
					order: Number(chart.order),
					max_lines: chart.max_lines ? Number(chart.max_lines) : null,
					values:
					chart.value_type == 'common'
						? {}
						: {
							create: chart?.values?.map((value) => ({
								...value
							}))
						  }
				}});
		const updatecharts = charts
			.filter((chart) => chart.id)
			.map((chart) => {
				const valueKeys = Object.keys(chart?.values);
				const values = valueKeys?.map((key) => chart.values[key]) || [];
				const currentValues = dashboard.charts
					.find((ch) => ch.id == chart.id)
					.values?.map((vl) => vl.id);
				const deleteValues = currentValues
					.filter((vl) => !values.find((v) => v.id == vl))
					.map((vl) => ({
						id: vl
					}));
				const update =
					chart.value_type == 'common' || values.length == 0
						? {}
						: {
							create: values
								?.filter((value) => !value.id)
								.map((value) => ({
									...value
								})),
							update: values
								?.filter((value) => value.id)
								.map((value) => ({
									...value
								})),
							delete: deleteValues
						  };

				delete chart.key

				return {
					...chart,
					order: Number(chart.order),
					max_lines: chart.max_lines ? Number(chart.max_lines) : null,
					values: update
				};
			});

		const currentCharts = dashboard.charts.map((chart) => chart.id);
		const deleteCharts = currentCharts
			.filter((chart) => !charts.find((c) => c.id == chart))
			.map((chart) => ({
				id: chart
			}));

		delete data.charts;

		const variables = {
			input: {
				...data,
				id,
				charts: {
					create: createChart,
					update: updatecharts,
					delete: deleteCharts
				}
			}
		};
		updateDashboard({ variables });
	};

	const inputHover = useColorModeValue('health.800', 'health.800');
	const textColor = useColorModeValue('gray.700', 'white');

	return (
		<Flex
			direction="column"
			minH="100vh"
			align="center"
			pt={{ sm: '120px', lg: '160px' }}
		>
			<Card w={{ md: '100%', lg: '50%' }}>
				<CardHeader mb="40px">
					<Flex direction="column">
						<Text
							color={textColor}
							fontSize="lg"
							fontWeight="bold"
							mb="3px"
						>
							Editar Dashboard Customizado
						</Text>
					</Flex>
				</CardHeader>
				<CardBody>
					<FormProvider {...methods}>
						<form
							style={{ width: '100%' }}
							onSubmit={handleSubmit(onSubmit)}
						>
							<Flex direction="column" w="100%">
								<Grid
									templateColumns={{
										sm: '1fr',
										md: 'repeat(2, 1fr)'
									}}
									gap="24px"
									mb="24px"
								>
									<FormControl isInvalid={errors.name}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Nome do menu
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Input
												{...register('name')}
												defaultValue={dashboard.name}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
											/>
										)}
										{errors.name && (
											<FormErrorMessage>
												{errors.name.message}
											</FormErrorMessage>
										)}
									</FormControl>
								</Grid>
								<Grid
									templateColumns={{
										sm: '1fr',
										md: 'repeat(1, 1fr)'
									}}
									gap="15px"
									mb="24px"
								>
									{loading ? (
										<Flex w="full" justifyContent="center">
											<Spinner size="md" />
										</Flex>
									) : (
										charts.map((chart, x) => (
											<>
												<HStack key={chart.id}>
													<Input type="hidden" {...register(`charts.${chart.id}.key`)} value={x} />
													<ChartModal
														id={chart.id}
														currentOpen={
															currentOpen
														}
														setOpen={
															handleOpenModal
														}
														defaultValues={
															chart.values
														}
														forms={forms}
														loadingForms={
															loadingForms
														}
														errors={
															errors?.charts
																? errors.charts[
																	x
																  ]
																: null
														}
													/>
													<FormControl>
														<Text>
															#{' '}
															{hasName(chart.id)
																? getValues(
																		`charts.${chart.id}.title`
																  )
																: x + 1}
														</Text>
													</FormControl>
													<Button
														colorScheme="green"
														onClick={() =>
															setCurrentOpen(
																chart.id
															)
														}
														marginTop="auto"
													>
														<Text fontSize="md">
															<Pencil size="22" />
														</Text>
													</Button>
													<Button
														colorScheme="orange"
														onClick={() =>
															handleChartRemove(
																chart.id
															)
														}
														marginTop="auto"
													>
														<Text fontSize="md">
															<Trash size="22" />
														</Text>
													</Button>
												</HStack>
												{ errors && errors.charts && errors.charts[x] &&
													<Flex w="full">
														<Text fontSize="sm" color="red.500">
															O Painel possui erros de validação
														</Text>
													</Flex>
												}
												{x + 1 !== charts.length && (
													<Divider />
												)}
											</>
										))
									)}
									{errors.charts_req && (
										<Flex mb="24px">
											<Text fontSize="sm" color="red.500">
												{errors.charts_req.message}
											</Text>
										</Flex>
									)}
									<Flex mb="24px">
										<Button
											colorScheme="green"
											leftIcon={<Plus size="22" />}
											onClick={() => handleAddChart()}
										>
											Adicionar painel
										</Button>
									</Flex>
								</Grid>
								<Flex
									w="full"
									justifyContent="flex-end"
									mt="24px"
								>
									<HStack spacing={4}>
										<Button
											disabled={loadingUpdate}
											variant="ghost"
											colorScheme="gray"
											alignSelf="flex-end"
											size="md"
											onClick={() => history.goBack()}
										>
											Cancelar
										</Button>
										<Button
											isLoading={loadingUpdate}
											disabled={loadingUpdate}
											colorScheme="green"
											type="submit"
											alignSelf="flex-end"
											size="md"
										>
											Atualizar
										</Button>
									</HStack>
								</Flex>
							</Flex>
						</form>
					</FormProvider>
				</CardBody>
			</Card>
		</Flex>
	);
};

export default EditDashboard;
