import { createContext, useContext, useReducer, useState } from 'react';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import {
	GET_CAMPAIGN,
	CREATE_CAMPAIGN,
	UPDATE_CAMPAIGN,
	GET_CAMPAIGNS_PAGINATE,
	DELETE_CAMPAIGN,
	GET_CAMPAIGNS
} from 'graphql/campaigns';

import { ACTION_TYPES, reducers } from './reducers';

const CampaignsStateContext = createContext();
const CampaignsDispatchContext = createContext();

const initialState = {
	rows: [],
	page: 1,
	perPage: 15
};

const CampaignsProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducers, initialState);
	const [toDelete, setToDelete] = useState(null);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);

	return (
		<CampaignsStateContext.Provider
			value={{
				...state,
				toDelete,
				setToDelete,
				deleteModalOpen,
				setDeleteModalOpen
			}}
		>
			<CampaignsDispatchContext.Provider value={dispatch}>
				{children}
			</CampaignsDispatchContext.Provider>
		</CampaignsStateContext.Provider>
	);
};

const useCampaigns = () => {
	const context = useContext(CampaignsStateContext);

	if (!context)
		throw new Error(
			'useCampaigns must be used within an CampaignsProvider'
		);

	return context;
};

const useDispatch = () => {
	const dispatch = useContext(CampaignsDispatchContext);

	if (dispatch === undefined)
		throw new Error('useDispatch must be used within a CampaignsProvider');

	return dispatch;
};

const usePaginateCampaigns = () => {
	const dispatch = useDispatch();

	return useLazyQuery(GET_CAMPAIGNS_PAGINATE, {
		onCompleted: (data) => {
			window.history.replaceState(
				null,
				'',
				`./campanhas?page=${data.paginateCampaigns.paginatorInfo.currentPage}&size=${data.paginateCampaigns.paginatorInfo.perPage}`
			);
			dispatch({ type: ACTION_TYPES.LIST, data: data.paginateCampaigns });
		}
	});
};

const useGetCampaigns = () => useQuery(GET_CAMPAIGNS);

const useCreateCampaign = (options = {}) => {
	const dispatch = useDispatch();
	const { onSuccess, onError } = options;

	return useMutation(CREATE_CAMPAIGN, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data);
			dispatch({ type: ACTION_TYPES.ADD, data: data.createCampaign });
		},
		onError: (error) => {
			if (onError) onError(error);
		}
	});
};

const useCampaign = (id) => {
	const dispatch = useContext(CampaignsDispatchContext);

	return useQuery(
		GET_CAMPAIGN,
		{
			fetchPolicy: 'no-cache',
			variables: {
				id
			}
		},
		{
			onCompleted: (data) => {
				dispatch({ type: ACTION_TYPES.VIEW, data: data.campaign });
			}
		}
	);
};

const useUpdateCampaign = (options = {}) => {
	const dispatch = useContext(CampaignsDispatchContext);
	const { onSuccess, onError } = options;

	return useMutation(UPDATE_CAMPAIGN, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data);
			dispatch({ type: ACTION_TYPES.UPDATE, data: data.updateCampaign });
		},
		onError: (error) => {
			if (onError) onError(error);
		},
		refetchQueries: [
			{
				query: GET_CAMPAIGNS_PAGINATE
			}
		]
	});
};

const useDeleteCampaign = (options = {}) => {
	const dispatch = useDispatch();
	const { onSuccess } = options;
	return useMutation(DELETE_CAMPAIGN, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess();
			dispatch({ type: ACTION_TYPES.REMOVE, data: data.deleteCampaign });
		}
	});
};

export {
	CampaignsProvider,
	useCampaigns,
	useGetCampaigns,
	useCreateCampaign,
	usePaginateCampaigns,
	useCampaign,
	useUpdateCampaign,
	useDeleteCampaign
};
