import { createContext, useContext, useReducer } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import {
	CREATE_FORM,
	GET_FORM,
	GET_FORMS,
	GET_FORMS_PAGINATE,
	UPDATE_FORM
} from 'graphql/forms';

import { ACTION_TYPES, reducers } from './reducers';

const FormsStateContext = createContext();
const FormsDispatchContext = createContext();

const initialState = {
	rows: [],
	page: 1,
	perPage: 15
};

const FormsProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducers, initialState);

	return (
		<FormsStateContext.Provider value={{ ...state }}>
			<FormsDispatchContext.Provider value={dispatch}>
				{children}
			</FormsDispatchContext.Provider>
		</FormsStateContext.Provider>
	);
};

const useForms = () => {
	const context = useContext(FormsStateContext);

	if (!context)
		throw new Error('useForms must be used within an FormsProvider');

	return context;
};

const useDispatch = () => {
	const dispatch = useContext(FormsDispatchContext);

	if (dispatch === undefined)
		throw new Error('useDispatch must be used within a FormsProvider');

	return dispatch;
};

const usePaginateForms = () => {
	const dispatch = useDispatch();

	return useLazyQuery(GET_FORMS_PAGINATE, {
		onCompleted: (data) => {
			window.history.replaceState(
				null,
				'',
				`./gestao-vidas?page=${data.paginateFormConfigs.paginatorInfo.currentPage}&size=${data.paginateFormConfigs.paginatorInfo.perPage}`
			);
			dispatch({
				type: ACTION_TYPES.LIST,
				data: data.paginateFormConfigs
			});
		}
	});
};

const useGetForms = () => useQuery(GET_FORMS);

const useCreateForm = (options = {}) => {
	const dispatch = useDispatch();
	const { onSuccess, onError } = options;

	return useMutation(CREATE_FORM, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data);
			dispatch({ type: ACTION_TYPES.ADD, data: data.createForm });
		},
		onError: (error) => {
			if (onError) onError(error);
		}
	});
};

const useForm = (id) => {
	const dispatch = useContext(FormsDispatchContext);

	return useQuery(
		GET_FORM,
		{
			fetchPolicy: 'no-cache',
			variables: {
				id
			}
		},
		{
			onCompleted: (data) => {
				dispatch({ type: ACTION_TYPES.VIEW, data: data.form });
			}
		}
	);
};

const useUpdateForm = (options = {}) => {
	const dispatch = useContext(FormsDispatchContext);
	const { onSuccess, onError } = options;

	return useMutation(UPDATE_FORM, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data);
			dispatch({ type: ACTION_TYPES.UPDATE, data: data.updateForm });
		},
		onError: (error) => {
			if (onError) onError(error);
		},
		refetchQueries: [
			{
				query: GET_FORMS_PAGINATE
			}
		]
	});
};

// const useDeleteForm = (id, options = {}) => {
// 	const dispatch = useDispatch()
// 	const { onSuccess } = options

// 	return useMutation(() => DELETE_FORM(id), {
// 			...options,
// 			onSuccess: () => {
// 				onSuccess()
// 				dispatch({ type: ACTION_TYPES.REMOVE, id })
// 			},
// 	})
// }

export {
	FormsProvider,
	useForms,
	useGetForms,
	useCreateForm,
	usePaginateForms,
	useForm,
	useUpdateForm
	// useDeleteForm
};
