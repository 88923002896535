import { gql } from '@apollo/client';

export const GET_CAMPAIGNS = gql`
	query GetCampaigns {
		listCampaigns {
			id
			name
			is_active
		}
	}
`;

export const GET_CAMPAIGNS_PAGINATE = gql`
	query PaginateCampaigns(
		$first: Int! = 30
		$page: Int! = 1
		$where: QueryPaginateCampaignsWhereWhereConditions
	) {
		paginateCampaigns(first: $first, page: $page, where: $where) {
			paginatorInfo {
				count
				currentPage
				firstItem
				perPage
				lastItem
				total
			}
			data {
				id
				name
				is_active
				created_at
				regional {
					name
				}
				unit {
					name
				}
				form_config {
					name
				}
			}
		}
	}
`;

export const GET_CAMPAIGN = gql`
	query GetCampaign($id: ID!) {
		getCampaign(id: $id) {
			id
			name
			is_active
			created_at
			regional {
				id
				name
			}
			unit {
				id
				name
			}
			form_config {
				id
				name
			}
		}
	}
`;

export const CREATE_CAMPAIGN = gql`
	mutation CreateCampaign($input: CreateCampaign) {
		createCampaign(input: $input) {
			id
			name
			is_active
			created_at
			regional {
				name
			}
			unit {
				name
			}
			form_config {
				name
			}
		}
	}
`;

export const UPDATE_CAMPAIGN = gql`
	mutation UpdateCampaign($input: UpdateCampaign) {
		updateCampaign(input: $input) {
			id
			name
			is_active
			created_at
			regional {
				name
			}
			unit {
				name
			}
			form_config {
				name
			}
		}
	}
`;

export const DELETE_CAMPAIGN = gql`
	mutation DeleteCampaign(
		$where: MutationDeleteCampaignWhereWhereConditions
	) {
		deleteCampaign(where: $where) {
			id
		}
	}
`;
