import { gql } from '@apollo/client';

export const GET_DASHBOARDS = gql`
	query GetDashboards {
		listDashboards {
			id
			name
			created_at
		}
	}
`;

export const GET_DASHBOARDS_PAGINATE = gql`
	query PaginateDashboards(
		$first: Int! = 30
		$page: Int! = 1
		$where: QueryPaginateDashboardsWhereWhereConditions
	) {
		paginateDashboards(first: $first, page: $page, where: $where) {
			paginatorInfo {
				count
				currentPage
				firstItem
				perPage
				lastItem
				total
			}
			data {
				id
				name
				charts {
					id
				}
				created_at
			}
		}
	}
`;

export const GET_DASHBOARD = gql`
	query GetDashboard($id: ID!) {
		getDashboard(id: $id) {
			id
			name
			charts {
				id
				title
				subtitle
				helper
				chart_type
				value_type
				width
				form_id
				form_label
				value
				values {
					id
					form_id
					form_label
					value
					color
				}
				color
				max_lines
				order
			}
			created_at
		}
	}
`;
export const GET_DASHBOARDS_ALL = gql`
	query GetDashboards($id: ID!) {
		dashboards(client_id: $id) {
			id
			name
			charts {
				id
				title
				subtitle
				helper
				chart_type
				value_type
				width
				form {
					id
				}
				form_label
				value
				color
				max_lines
				order
			}
			created_at
		}
	}
`;
export const CREATE_DASHBOARD = gql`
	mutation CreateDashboard($input: CreateDashboard) {
		createDashboard(input: $input) {
			id
			name
		}
	}
`;

export const UPDATE_DASHBOARD = gql`
	mutation UpdateDashboard($input: UpdateDashboard) {
		updateDashboard(input: $input) {
			id
			name
		}
	}
`;

export const DELETE_DASHBOARD = gql`
	mutation DeleteDashboard(
		$where: MutationDeleteDashboardWhereWhereConditions
	) {
		deleteDashboard(where: $where) {
			id
		}
	}
`;
