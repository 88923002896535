import { ClientsProvider as Provider } from 'hooks/clients/index';
import { ConfigurationQueryProvider } from '../services/configurationQueryProvider';

const ClientsProvider = ({ children }) => (
	<ConfigurationQueryProvider>
		<Provider>{children}</Provider>
	</ConfigurationQueryProvider>
);

export default ClientsProvider;
