import { gql } from '@apollo/client';

export const GET_ROLES = gql`
	query GET_ROLES {
		roles {
			id
			name
			created_at
		}
	}
`;
